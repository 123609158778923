(function(){/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var A;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}var ba="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a};
function ca(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var E=ca(this);function J(a,b){if(b)a:{var c=E;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];if(!(e in c))break a;c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&ba(c,a,{configurable:!0,writable:!0,value:b})}}
J("Symbol",function(a){function b(g){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new c(d+(g||"")+"_"+e++,g)}function c(g,f){this.g=g;ba(this,"description",{configurable:!0,writable:!0,value:f})}if(a)return a;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",e=0;return b});
J("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<b.length;c++){var d=E[b[c]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&ba(d.prototype,a,{configurable:!0,writable:!0,value:function(){return da(aa(this))}})}return a});function da(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
function M(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:aa(a)}}function ea(a){if(!(a instanceof Array)){a=M(a);for(var b,c=[];!(b=a.next()).done;)c.push(b.value);a=c}return a}var fa="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},ha;
if("function"==typeof Object.setPrototypeOf)ha=Object.setPrototypeOf;else{var ia;a:{var ja={a:!0},ka={};try{ka.__proto__=ja;ia=ka.a;break a}catch(a){}ia=!1}ha=ia?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var la=ha;
function ma(a,b){a.prototype=fa(b.prototype);a.prototype.constructor=a;if(la)la(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.ca=b.prototype}function na(){this.l=!1;this.i=null;this.h=void 0;this.g=1;this.s=this.m=0;this.j=null}function oa(a){if(a.l)throw new TypeError("Generator is already running");a.l=!0}na.prototype.o=function(a){this.h=a};
function pa(a,b){a.j={S:b,T:!0};a.g=a.m||a.s}na.prototype.return=function(a){this.j={return:a};this.g=this.s};function N(a,b,c){a.g=c;return{value:b}}function qa(a){this.g=new na;this.h=a}function ra(a,b){oa(a.g);var c=a.g.i;if(c)return sa(a,"return"in c?c["return"]:function(d){return{value:d,done:!0}},b,a.g.return);a.g.return(b);return ta(a)}
function sa(a,b,c,d){try{var e=b.call(a.g.i,c);if(!(e instanceof Object))throw new TypeError("Iterator result "+e+" is not an object");if(!e.done)return a.g.l=!1,e;var g=e.value}catch(f){return a.g.i=null,pa(a.g,f),ta(a)}a.g.i=null;d.call(a.g,g);return ta(a)}function ta(a){for(;a.g.g;)try{var b=a.h(a.g);if(b)return a.g.l=!1,{value:b.value,done:!1}}catch(c){a.g.h=void 0,pa(a.g,c)}a.g.l=!1;if(a.g.j){b=a.g.j;a.g.j=null;if(b.T)throw b.S;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function ua(a){this.next=function(b){oa(a.g);a.g.i?b=sa(a,a.g.i.next,b,a.g.o):(a.g.o(b),b=ta(a));return b};this.throw=function(b){oa(a.g);a.g.i?b=sa(a,a.g.i["throw"],b,a.g.o):(pa(a.g,b),b=ta(a));return b};this.return=function(b){return ra(a,b)};this[Symbol.iterator]=function(){return this}}function O(a,b){b=new ua(new qa(b));la&&a.prototype&&la(b,a.prototype);return b}
function va(a,b){a instanceof String&&(a+="");var c=0,d=!1,e={next:function(){if(!d&&c<a.length){var g=c++;return{value:b(g,a[g]),done:!1}}d=!0;return{done:!0,value:void 0}}};e[Symbol.iterator]=function(){return e};return e}var wa="function"==typeof Object.assign?Object.assign:function(a,b){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(d)for(var e in d)Object.prototype.hasOwnProperty.call(d,e)&&(a[e]=d[e])}return a};J("Object.assign",function(a){return a||wa});
J("Promise",function(a){function b(f){this.h=0;this.i=void 0;this.g=[];this.o=!1;var h=this.j();try{f(h.resolve,h.reject)}catch(k){h.reject(k)}}function c(){this.g=null}function d(f){return f instanceof b?f:new b(function(h){h(f)})}if(a)return a;c.prototype.h=function(f){if(null==this.g){this.g=[];var h=this;this.i(function(){h.l()})}this.g.push(f)};var e=E.setTimeout;c.prototype.i=function(f){e(f,0)};c.prototype.l=function(){for(;this.g&&this.g.length;){var f=this.g;this.g=[];for(var h=0;h<f.length;++h){var k=
f[h];f[h]=null;try{k()}catch(l){this.j(l)}}}this.g=null};c.prototype.j=function(f){this.i(function(){throw f;})};b.prototype.j=function(){function f(l){return function(n){k||(k=!0,l.call(h,n))}}var h=this,k=!1;return{resolve:f(this.B),reject:f(this.l)}};b.prototype.B=function(f){if(f===this)this.l(new TypeError("A Promise cannot resolve to itself"));else if(f instanceof b)this.D(f);else{a:switch(typeof f){case "object":var h=null!=f;break a;case "function":h=!0;break a;default:h=!1}h?this.u(f):this.m(f)}};
b.prototype.u=function(f){var h=void 0;try{h=f.then}catch(k){this.l(k);return}"function"==typeof h?this.H(h,f):this.m(f)};b.prototype.l=function(f){this.s(2,f)};b.prototype.m=function(f){this.s(1,f)};b.prototype.s=function(f,h){if(0!=this.h)throw Error("Cannot settle("+f+", "+h+"): Promise already settled in state"+this.h);this.h=f;this.i=h;2===this.h&&this.C();this.v()};b.prototype.C=function(){var f=this;e(function(){if(f.A()){var h=E.console;"undefined"!==typeof h&&h.error(f.i)}},1)};b.prototype.A=
function(){if(this.o)return!1;var f=E.CustomEvent,h=E.Event,k=E.dispatchEvent;if("undefined"===typeof k)return!0;"function"===typeof f?f=new f("unhandledrejection",{cancelable:!0}):"function"===typeof h?f=new h("unhandledrejection",{cancelable:!0}):(f=E.document.createEvent("CustomEvent"),f.initCustomEvent("unhandledrejection",!1,!0,f));f.promise=this;f.reason=this.i;return k(f)};b.prototype.v=function(){if(null!=this.g){for(var f=0;f<this.g.length;++f)g.h(this.g[f]);this.g=null}};var g=new c;b.prototype.D=
function(f){var h=this.j();f.I(h.resolve,h.reject)};b.prototype.H=function(f,h){var k=this.j();try{f.call(h,k.resolve,k.reject)}catch(l){k.reject(l)}};b.prototype.then=function(f,h){function k(v,u){return"function"==typeof v?function(z){try{l(v(z))}catch(m){n(m)}}:u}var l,n,w=new b(function(v,u){l=v;n=u});this.I(k(f,l),k(h,n));return w};b.prototype.catch=function(f){return this.then(void 0,f)};b.prototype.I=function(f,h){function k(){switch(l.h){case 1:f(l.i);break;case 2:h(l.i);break;default:throw Error("Unexpected state: "+
l.h);}}var l=this;null==this.g?g.h(k):this.g.push(k);this.o=!0};b.resolve=d;b.reject=function(f){return new b(function(h,k){k(f)})};b.race=function(f){return new b(function(h,k){for(var l=M(f),n=l.next();!n.done;n=l.next())d(n.value).I(h,k)})};b.all=function(f){var h=M(f),k=h.next();return k.done?d([]):new b(function(l,n){function w(z){return function(m){v[z]=m;u--;0==u&&l(v)}}var v=[],u=0;do v.push(void 0),u++,d(k.value).I(w(v.length-1),n),k=h.next();while(!k.done)})};return b});
J("Object.is",function(a){return a?a:function(b,c){return b===c?0!==b||1/b===1/c:b!==b&&c!==c}});J("Array.prototype.includes",function(a){return a?a:function(b,c){var d=this;d instanceof String&&(d=String(d));var e=d.length;c=c||0;for(0>c&&(c=Math.max(c+e,0));c<e;c++){var g=d[c];if(g===b||Object.is(g,b))return!0}return!1}});
J("String.prototype.includes",function(a){return a?a:function(b,c){if(null==this)throw new TypeError("The 'this' value for String.prototype.includes must not be null or undefined");if(b instanceof RegExp)throw new TypeError("First argument to String.prototype.includes must not be a regular expression");return-1!==this.indexOf(b,c||0)}});J("Array.prototype.keys",function(a){return a?a:function(){return va(this,function(b){return b})}});var xa=this||self;
function ya(a,b){a=a.split(".");var c=xa;a[0]in c||"undefined"==typeof c.execScript||c.execScript("var "+a[0]);for(var d;a.length&&(d=a.shift());)a.length||void 0===b?c[d]&&c[d]!==Object.prototype[d]?c=c[d]:c=c[d]={}:c[d]=b};function za(a,b){b=String.fromCharCode.apply(null,b);return null==a?b:a+b}var Aa,Ba="undefined"!==typeof TextDecoder,Ca,Da="undefined"!==typeof TextEncoder;
function Ea(a){if(Da)a=(Ca||(Ca=new TextEncoder)).encode(a);else{var b=void 0;b=void 0===b?!1:b;for(var c=0,d=new Uint8Array(3*a.length),e=0;e<a.length;e++){var g=a.charCodeAt(e);if(128>g)d[c++]=g;else{if(2048>g)d[c++]=g>>6|192;else{if(55296<=g&&57343>=g){if(56319>=g&&e<a.length){var f=a.charCodeAt(++e);if(56320<=f&&57343>=f){g=1024*(g-55296)+f-56320+65536;d[c++]=g>>18|240;d[c++]=g>>12&63|128;d[c++]=g>>6&63|128;d[c++]=g&63|128;continue}else e--}if(b)throw Error("Found an unpaired surrogate");g=65533}d[c++]=
g>>12|224;d[c++]=g>>6&63|128}d[c++]=g&63|128}}a=d.subarray(0,c)}return a};var Fa="function"===typeof Uint8Array.prototype.slice,P=0,Q=0;function Ha(a){this.h=null;this.g=this.i=this.j=0;this.l=!1;a&&Ia(this,a)}function Ia(a,b){b=b.constructor===Uint8Array?b:b.constructor===ArrayBuffer?new Uint8Array(b):b.constructor===Array?new Uint8Array(b):b.constructor===String?Ja(b):b instanceof Uint8Array?new Uint8Array(b.buffer,b.byteOffset,b.byteLength):new Uint8Array(0);a.h=b;a.j=0;a.i=a.h.length;a.g=a.j}Ha.prototype.reset=function(){this.g=this.j};
function Ka(a){var b=a.h,c=b[a.g],d=c&127;if(128>c)return a.g+=1,d;c=b[a.g+1];d|=(c&127)<<7;if(128>c)return a.g+=2,d;c=b[a.g+2];d|=(c&127)<<14;if(128>c)return a.g+=3,d;c=b[a.g+3];d|=(c&127)<<21;if(128>c)return a.g+=4,d;c=b[a.g+4];d|=(c&15)<<28;if(128>c)return a.g+=5,d>>>0;a.g+=5;128<=b[a.g++]&&128<=b[a.g++]&&128<=b[a.g++]&&128<=b[a.g++]&&a.g++;return d}
function R(a){var b=a.h[a.g];var c=a.h[a.g+1];var d=a.h[a.g+2],e=a.h[a.g+3];a.g+=4;c=(b<<0|c<<8|d<<16|e<<24)>>>0;a=2*(c>>31)+1;b=c>>>23&255;c&=8388607;return 255==b?c?NaN:Infinity*a:0==b?a*Math.pow(2,-149)*c:a*Math.pow(2,b-150)*(c+Math.pow(2,23))}var La=[];function Ma(){this.g=new Uint8Array(64);this.h=0}Ma.prototype.push=function(a){if(!(this.h+1<this.g.length)){var b=this.g;this.g=new Uint8Array(Math.ceil(1+2*this.g.length));this.g.set(b)}this.g[this.h++]=a};Ma.prototype.length=function(){return this.h};Ma.prototype.end=function(){var a=this.g,b=this.h;this.h=0;return Fa?a.slice(0,b):new Uint8Array(a.subarray(0,b))};function S(a,b){for(;127<b;)a.push(b&127|128),b>>>=7;a.push(b)};function Na(a){if(La.length){var b=La.pop();a&&Ia(b,a);a=b}else a=new Ha(a);this.g=a;this.h=this.i=this.l=-1;this.j=!1}Na.prototype.reset=function(){this.g.reset();this.h=this.l=-1};function Oa(a){var b=a.g;(b=b.g==b.i)||(b=a.j)||(b=a.g,b=b.l||0>b.g||b.g>b.i);if(b)return!1;b=Ka(a.g);var c=b&7;if(0!=c&&5!=c&&1!=c&&2!=c&&3!=c&&4!=c)return a.j=!0,!1;a.i=b;a.l=b>>>3;a.h=c;return!0}
function Pa(a){switch(a.h){case 0:if(0!=a.h)Pa(a);else{for(a=a.g;a.h[a.g]&128;)a.g++;a.g++}break;case 1:1!=a.h?Pa(a):(a=a.g,a.g+=8);break;case 2:if(2!=a.h)Pa(a);else{var b=Ka(a.g);a=a.g;a.g+=b}break;case 5:5!=a.h?Pa(a):(a=a.g,a.g+=4);break;case 3:b=a.l;do{if(!Oa(a)){a.j=!0;break}if(4==a.h){a.l!=b&&(a.j=!0);break}Pa(a)}while(1);break;default:a.j=!0}}function Qa(a,b,c){var d=a.g.i,e=Ka(a.g);e=a.g.g+e;a.g.i=e;c(b,a);a.g.g=e;a.g.i=d;return b}
function Ra(a){var b=Ka(a.g);a=a.g;var c=a.g;a.g+=b;a=a.h;var d;if(Ba)(d=Aa)||(d=Aa=new TextDecoder("utf-8",{fatal:!1})),d=d.decode(a.subarray(c,c+b));else{b=c+b;for(var e=[],g=null,f,h,k;c<b;)f=a[c++],128>f?e.push(f):224>f?c>=b?e.push(65533):(h=a[c++],194>f||128!==(h&192)?(c--,e.push(65533)):e.push((f&31)<<6|h&63)):240>f?c>=b-1?e.push(65533):(h=a[c++],128!==(h&192)||224===f&&160>h||237===f&&160<=h||128!==((d=a[c++])&192)?(c--,e.push(65533)):e.push((f&15)<<12|(h&63)<<6|d&63)):244>=f?c>=b-2?e.push(65533):
(h=a[c++],128!==(h&192)||0!==(f<<28)+(h-144)>>30||128!==((d=a[c++])&192)||128!==((k=a[c++])&192)?(c--,e.push(65533)):(f=(f&7)<<18|(h&63)<<12|(d&63)<<6|k&63,f-=65536,e.push((f>>10&1023)+55296,(f&1023)+56320))):e.push(65533),8192<=e.length&&(g=za(g,e),e.length=0);d=za(g,e)}return d};var Sa={},Ta=null;function Ja(a){var b=a.length,c=3*b/4;c%3?c=Math.floor(c):-1!="=.".indexOf(a[b-1])&&(c=-1!="=.".indexOf(a[b-2])?c-2:c-1);var d=new Uint8Array(c),e=0;Ua(a,function(g){d[e++]=g});return d.subarray(0,e)}
function Ua(a,b){function c(k){for(;d<a.length;){var l=a.charAt(d++),n=Ta[l];if(null!=n)return n;if(!/^[\s\xa0]*$/.test(l))throw Error("Unknown base64 encoding at char: "+l);}return k}Va();for(var d=0;;){var e=c(-1),g=c(0),f=c(64),h=c(64);if(64===h&&-1===e)break;b(e<<2|g>>4);64!=f&&(b(g<<4&240|f>>2),64!=h&&b(f<<6&192|h))}}
function Va(){if(!Ta){Ta={};for(var a="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split(""),b=["+/=","+/","-_=","-_.","-_"],c=0;5>c;c++){var d=a.concat(b[c].split(""));Sa[c]=d;for(var e=0;e<d.length;e++){var g=d[e];void 0===Ta[g]&&(Ta[g]=e)}}}};function Wa(){this.h=[];this.i=0;this.g=new Ma}function Xa(a){var b=a.i+a.g.length();if(0===b)return new Uint8Array(0);b=new Uint8Array(b);for(var c=a.h,d=c.length,e=0,g=0;g<d;g++){var f=c[g];0!==f.length&&(b.set(f,e),e+=f.length)}c=a.g;d=c.h;0!==d&&(b.set(c.g.subarray(0,d),e),c.h=0);a.h=[b];return b}
function T(a,b,c){if(null!=c){S(a.g,8*b+5);a=a.g;var d=c;d=(c=0>d?1:0)?-d:d;0===d?0<1/d?P=Q=0:(Q=0,P=2147483648):isNaN(d)?(Q=0,P=2147483647):3.4028234663852886E38<d?(Q=0,P=(c<<31|2139095040)>>>0):1.1754943508222875E-38>d?(d=Math.round(d/Math.pow(2,-149)),Q=0,P=(c<<31|d)>>>0):(b=Math.floor(Math.log(d)/Math.LN2),d*=Math.pow(2,-b),d=Math.round(8388608*d)&8388607,Q=0,P=(c<<31|b+127<<23|d)>>>0);c=P;a.push(c>>>0&255);a.push(c>>>8&255);a.push(c>>>16&255);a.push(c>>>24&255)}}
function Ya(a,b,c){S(a.g,8*b+2);S(a.g,c.length);b=a.g.end();a.h.push(b);a.h.push(c);a.i+=b.length+c.length};var Za="function"===typeof Uint8Array;function $a(a,b,c){return"object"===typeof a?Za&&!Array.isArray(a)&&a instanceof Uint8Array?c(a):ab(a,b,c):b(a)}function ab(a,b,c){if(Array.isArray(a)){for(var d=Array(a.length),e=0;e<a.length;e++){var g=a[e];null!=g&&(d[e]=$a(g,b,c))}Array.isArray(a)&&a.U&&bb(d);return d}d={};for(e in a)g=a[e],null!=g&&(d[e]=$a(g,b,c));return d}
function cb(a){return ab(a,function(b){return"number"===typeof b?isFinite(b)?b:String(b):b},function(b){var c;void 0===c&&(c=0);Va();c=Sa[c];for(var d=Array(Math.floor(b.length/3)),e=c[64]||"",g=0,f=0;g<b.length-2;g+=3){var h=b[g],k=b[g+1],l=b[g+2],n=c[h>>2];h=c[(h&3)<<4|k>>4];k=c[(k&15)<<2|l>>6];l=c[l&63];d[f++]=n+h+k+l}n=0;l=e;switch(b.length-g){case 2:n=b[g+1],l=c[(n&15)<<2]||e;case 1:b=b[g],d[f]=c[b>>2]+c[(b&3)<<4|n>>4]+l+e}return d.join("")})}var db={U:{value:!0,configurable:!0}};
function bb(a){Array.isArray(a)&&!Object.isFrozen(a)&&Object.defineProperties(a,db);return a};var eb;function U(a,b,c,d){var e=eb;eb=null;a||(a=e);e=this.constructor.aa;a||(a=e?[e]:[]);this.j=e?0:-1;this.i=null;this.g=a;a:{e=this.g.length;a=e-1;if(e&&(e=this.g[a],!(null===e||"object"!=typeof e||Array.isArray(e)||Za&&e instanceof Uint8Array))){this.l=a-this.j;this.h=e;break a}void 0!==b&&-1<b?(this.l=Math.max(b,a+1-this.j),this.h=null):this.l=Number.MAX_VALUE}if(c)for(b=0;b<c.length;b++)a=c[b],a<this.l?(a+=this.j,(e=this.g[a])?bb(e):this.g[a]=fb):(gb(this),(e=this.h[a])?bb(e):this.h[a]=fb);if(d&&
d.length)for(c=0;c<d.length;c++){a=b=void 0;e=d[c];for(var g=0;g<e.length;g++){var f=e[g],h=V(this,f);null!=h&&(a=f,b=h,W(this,f,void 0))}a&&W(this,a,b)}}var fb=Object.freeze(bb([]));function gb(a){var b=a.l+a.j;a.g[b]||(a.h=a.g[b]={})}function V(a,b){if(b<a.l){b+=a.j;var c=a.g[b];return c!==fb?c:a.g[b]=bb([])}if(a.h)return c=a.h[b],c!==fb?c:a.h[b]=bb([])}function X(a,b,c){c=void 0===c?0:c;a=V(a,b);a=null==a?a:+a;return null==a?c:a}function W(a,b,c){b<a.l?a.g[b+a.j]=c:(gb(a),a.h[b]=c)}
function hb(a,b){a.i||(a.i={});if(!a.i[1]){var c=V(a,1);for(var d=[],e=0;e<c.length;e++)d[e]=new b(c[e]);a.i[1]=d}return a.i[1]}function ib(a,b,c,d){var e=hb(a,c);b=b?b:new c;a=V(a,1);void 0!=d?(e.splice(d,0,b),a.splice(d,0,jb(b,!1))):(e.push(b),a.push(jb(b,!1)));return b}U.prototype.toJSON=function(){var a=jb(this,!1);return cb(a)};function jb(a,b){if(a.i)for(var c in a.i){var d=a.i[c];if(Array.isArray(d))for(var e=0;e<d.length;e++)d[e]&&jb(d[e],b);else d&&jb(d,b)}return a.g}
U.prototype.toString=function(){return jb(this,!1).toString()};function kb(a,b){a=V(a,b);return null==a?0:a}function lb(a,b){a=V(a,b);return null==a?"":a};function mb(a){if(4==a.h)return!1;Pa(a);return!0};function Z(a,b){var c=void 0;return new (c||(c=Promise))(function(d,e){function g(k){try{h(b.next(k))}catch(l){e(l)}}function f(k){try{h(b["throw"](k))}catch(l){e(l)}}function h(k){k.done?d(k.value):(new c(function(l){l(k.value)})).then(g,f)}h((b=b.apply(a,void 0)).next())})};function nb(a){U.call(this,a)}ma(nb,U);function ob(a,b){for(;Oa(b);)switch(b.i){case 8:var c=Ka(b.g);W(a,1,c);break;case 21:c=R(b.g);W(a,2,c);break;case 26:c=Ra(b);W(a,3,c);break;case 34:c=Ra(b);W(a,4,c);break;default:if(!mb(b))return a}return a};function pb(a){U.call(this,a,-1,qb)}ma(pb,U);pb.prototype.addClassification=function(a,b){return ib(this,a,nb,b)};var qb=[1];function rb(a){U.call(this,a)}ma(rb,U);function sb(a,b){for(;Oa(b);)switch(b.i){case 13:var c=R(b.g);W(a,1,c);break;case 21:c=R(b.g);W(a,2,c);break;case 29:c=R(b.g);W(a,3,c);break;case 37:c=R(b.g);W(a,4,c);break;case 45:c=R(b.g);W(a,5,c);break;default:if(!mb(b))return a}return a};function tb(a){U.call(this,a,-1,ub)}ma(tb,U);var ub=[1];function vb(a){U.call(this,a)}ma(vb,U);function wb(a,b,c){c=a.createShader(0===c?a.VERTEX_SHADER:a.FRAGMENT_SHADER);a.shaderSource(c,b);a.compileShader(c);if(!a.getShaderParameter(c,a.COMPILE_STATUS))throw Error("Could not compile WebGL shader.\n\n"+a.getShaderInfoLog(c));return c};function xb(a){return hb(a,nb).map(function(b){return{index:kb(b,1),W:X(b,2),label:null!=V(b,3)?lb(b,3):void 0,displayName:null!=V(b,4)?lb(b,4):void 0}})};function yb(a){return{x:X(a,1),y:X(a,2),z:X(a,3),visibility:null!=V(a,4)?X(a,4):void 0}};function zb(a,b){this.h=a;this.g=b;this.l=0}
function Ab(a,b,c){Bb(a,b);if("function"===typeof a.g.canvas.transferToImageBitmap)return Promise.resolve(a.g.canvas.transferToImageBitmap());if(c)return Promise.resolve(a.g.canvas);if("function"===typeof createImageBitmap)return createImageBitmap(a.g.canvas);void 0===a.i&&(a.i=document.createElement("canvas"));return new Promise(function(d){a.i.height=a.g.canvas.height;a.i.width=a.g.canvas.width;a.i.getContext("2d",{}).drawImage(a.g.canvas,0,0,a.g.canvas.width,a.g.canvas.height);d(a.i)})}
function Bb(a,b){var c=a.g;if(void 0===a.m){var d=wb(c,"\n  attribute vec2 aVertex;\n  attribute vec2 aTex;\n  varying vec2 vTex;\n  void main(void) {\n    gl_Position = vec4(aVertex, 0.0, 1.0);\n    vTex = aTex;\n  }",0),e=wb(c,"\n  precision mediump float;\n  varying vec2 vTex;\n  uniform sampler2D sampler0;\n  void main(){\n    gl_FragColor = texture2D(sampler0, vTex);\n  }",1),g=c.createProgram();c.attachShader(g,d);c.attachShader(g,e);c.linkProgram(g);if(!c.getProgramParameter(g,c.LINK_STATUS))throw Error("Could not compile WebGL program.\n\n"+
c.getProgramInfoLog(g));d=a.m=g;c.useProgram(d);e=c.getUniformLocation(d,"sampler0");a.j={G:c.getAttribLocation(d,"aVertex"),F:c.getAttribLocation(d,"aTex"),ba:e};a.s=c.createBuffer();c.bindBuffer(c.ARRAY_BUFFER,a.s);c.enableVertexAttribArray(a.j.G);c.vertexAttribPointer(a.j.G,2,c.FLOAT,!1,0,0);c.bufferData(c.ARRAY_BUFFER,new Float32Array([-1,-1,-1,1,1,1,1,-1]),c.STATIC_DRAW);c.bindBuffer(c.ARRAY_BUFFER,null);a.o=c.createBuffer();c.bindBuffer(c.ARRAY_BUFFER,a.o);c.enableVertexAttribArray(a.j.F);c.vertexAttribPointer(a.j.F,
2,c.FLOAT,!1,0,0);c.bufferData(c.ARRAY_BUFFER,new Float32Array([0,1,0,0,1,0,1,1]),c.STATIC_DRAW);c.bindBuffer(c.ARRAY_BUFFER,null);c.uniform1i(e,0)}d=a.j;c.useProgram(a.m);c.canvas.width=b.width;c.canvas.height=b.height;c.viewport(0,0,b.width,b.height);c.activeTexture(c.TEXTURE0);a.h.bindTexture2d(b.glName);c.enableVertexAttribArray(d.G);c.bindBuffer(c.ARRAY_BUFFER,a.s);c.vertexAttribPointer(d.G,2,c.FLOAT,!1,0,0);c.enableVertexAttribArray(d.F);c.bindBuffer(c.ARRAY_BUFFER,a.o);c.vertexAttribPointer(d.F,
2,c.FLOAT,!1,0,0);c.bindFramebuffer(c.DRAW_FRAMEBUFFER?c.DRAW_FRAMEBUFFER:c.FRAMEBUFFER,null);c.clearColor(0,0,0,0);c.clear(c.COLOR_BUFFER_BIT);c.colorMask(!0,!0,!0,!0);c.drawArrays(c.TRIANGLE_FAN,0,4);c.disableVertexAttribArray(d.G);c.disableVertexAttribArray(d.F);c.bindBuffer(c.ARRAY_BUFFER,null);a.h.bindTexture2d(0)}function Cb(a){this.g=a};var Db=new Uint8Array([0,97,115,109,1,0,0,0,1,4,1,96,0,0,3,2,1,0,10,9,1,7,0,65,0,253,15,26,11]);function Eb(a,b){return b+a}function Fb(a,b){window[a]=b}function Gb(a){var b=document.createElement("script");b.setAttribute("src",a);b.setAttribute("crossorigin","anonymous");return new Promise(function(c){b.addEventListener("load",function(){c()},!1);b.addEventListener("error",function(){c()},!1);document.body.appendChild(b)})}
function Hb(){return Z(this,function b(){return O(b,function(c){switch(c.g){case 1:return c.m=2,N(c,WebAssembly.instantiate(Db),4);case 4:c.g=3;c.m=0;break;case 2:return c.m=0,c.j=null,c.return(!1);case 3:return c.return(!0)}})})}
function Ib(a){this.g=a;this.listeners={};this.j={};this.D={};this.m={};this.s={};this.P=this.o=this.N=!0;this.B=Promise.resolve();this.H="";this.A={};this.locateFile=a&&a.locateFile||Eb;if("object"===typeof window)var b=window.location.pathname.toString().substring(0,window.location.pathname.toString().lastIndexOf("/"))+"/";else if("undefined"!==typeof location)b=location.pathname.toString().substring(0,location.pathname.toString().lastIndexOf("/"))+"/";else throw Error("solutions can only be loaded on a web page or in a web worker");
this.O=b;if(a.options){b=M(Object.keys(a.options));for(var c=b.next();!c.done;c=b.next()){c=c.value;var d=a.options[c].default;void 0!==d&&(this.j[c]="function"===typeof d?d():d)}}}A=Ib.prototype;A.close=function(){this.i&&this.i.delete();return Promise.resolve()};function Jb(a,b){return void 0===a.g.files?[]:"function"===typeof a.g.files?a.g.files(b):a.g.files}
function Kb(a){return Z(a,function c(){var d=this,e,g,f,h,k,l,n,w,v,u,z;return O(c,function(m){switch(m.g){case 1:e=d;if(!d.N)return m.return();g=Jb(d,d.j);return N(m,Hb(),2);case 2:f=m.h;if("object"===typeof window)return Fb("createMediapipeSolutionsWasm",{locateFile:d.locateFile}),Fb("createMediapipeSolutionsPackedAssets",{locateFile:d.locateFile}),l=g.filter(function(t){return void 0!==t.data}),n=g.filter(function(t){return void 0===t.data}),w=Promise.all(l.map(function(t){var x=Lb(e,t.url);if(void 0!==
t.path){var y=t.path;x=x.then(function(D){e.overrideFile(y,D);return Promise.resolve(D)})}return x})),v=Promise.all(n.map(function(t){return void 0===t.simd||t.simd&&f||!t.simd&&!f?Gb(e.locateFile(t.url,e.O)):Promise.resolve()})).then(function(){return Z(e,function x(){var y,D,F=this;return O(x,function(K){if(1==K.g)return y=window.createMediapipeSolutionsWasm,D=window.createMediapipeSolutionsPackedAssets,N(K,y(D),2);F.h=K.h;K.g=0})})}),u=function(){return Z(e,function x(){var y=this;return O(x,function(D){y.g.graph&&
y.g.graph.url?D=N(D,Lb(y,y.g.graph.url),0):(D.g=0,D=void 0);return D})})}(),N(m,Promise.all([v,w,u]),7);if("function"!==typeof importScripts)throw Error("solutions can only be loaded on a web page or in a web worker");h=g.filter(function(t){return void 0===t.simd||t.simd&&f||!t.simd&&!f}).map(function(t){return e.locateFile(t.url,e.O)});importScripts.apply(null,ea(h));return N(m,createMediapipeSolutionsWasm(Module),6);case 6:d.h=m.h;d.l=new OffscreenCanvas(1,1);d.h.canvas=d.l;k=d.h.GL.createContext(d.l,
{antialias:!1,alpha:!1,$:"undefined"!==typeof WebGL2RenderingContext?2:1});d.h.GL.makeContextCurrent(k);m.g=4;break;case 7:d.l=document.createElement("canvas");z=d.l.getContext("webgl2",{});if(!z&&(z=d.l.getContext("webgl",{}),!z))return alert("Failed to create WebGL canvas context when passing video frame."),m.return();d.C=z;d.h.canvas=d.l;d.h.createContext(d.l,!0,!0,{});case 4:d.i=new d.h.SolutionWasm,d.N=!1,m.g=0}})})}
function Mb(a){return Z(a,function c(){var d=this,e,g,f,h,k,l,n,w;return O(c,function(v){if(1==v.g){if(d.g.graph&&d.g.graph.url&&d.H===d.g.graph.url)return v.return();d.o=!0;if(!d.g.graph||!d.g.graph.url){v.g=2;return}d.H=d.g.graph.url;return N(v,Lb(d,d.g.graph.url),3)}2!=v.g&&(e=v.h,d.i.loadGraph(e));g=M(Object.keys(d.A));for(f=g.next();!f.done;f=g.next())h=f.value,d.i.overrideFile(h,d.A[h]);d.A={};if(d.g.listeners)for(k=M(d.g.listeners),l=k.next();!l.done;l=k.next())n=l.value,Nb(d,n);w=d.j;d.j=
{};d.setOptions(w);v.g=0})})}A.reset=function(){return Z(this,function b(){var c=this;return O(b,function(d){c.i&&(c.i.reset(),c.m={},c.s={});d.g=0})})};
A.setOptions=function(a,b){var c=this;if(b=b||this.g.options){for(var d=[],e=[],g={},f=M(Object.keys(a)),h=f.next();!h.done;g={J:g.J,K:g.K},h=f.next()){var k=h.value;k in this.j&&this.j[k]===a[k]||(this.j[k]=a[k],h=b[k],void 0!==h&&(h.onChange&&(g.J=h.onChange,g.K=a[k],d.push(function(l){return function(){return Z(c,function w(){var v,u=this;return O(w,function(z){if(1==z.g)return N(z,l.J(l.K),2);v=z.h;!0===v&&(u.o=!0);z.g=0})})}}(g))),h.graphOptionXref&&(k={valueNumber:1===h.type?a[k]:0,valueBoolean:0===
h.type?a[k]:!1,valueString:2===h.type?a[k]:""},h=Object.assign(Object.assign(Object.assign({},{calculatorName:"",calculatorIndex:0}),h.graphOptionXref),k),e.push(h))))}if(0!==d.length||0!==e.length)this.o=!0,this.v=(void 0===this.v?[]:this.v).concat(e),this.u=(void 0===this.u?[]:this.u).concat(d)}};
function Ob(a){return Z(a,function c(){var d=this,e,g,f,h,k,l,n;return O(c,function(w){switch(w.g){case 1:if(!d.o)return w.return();if(!d.u){w.g=2;break}e=M(d.u);g=e.next();case 3:if(g.done){w.g=5;break}f=g.value;return N(w,f(),4);case 4:g=e.next();w.g=3;break;case 5:d.u=void 0;case 2:if(d.v){h=new d.h.GraphOptionChangeRequestList;k=M(d.v);for(l=k.next();!l.done;l=k.next())n=l.value,h.push_back(n);d.i.changeOptions(h);h.delete();d.v=void 0}d.o=!1;w.g=0}})})}
A.initialize=function(){return Z(this,function b(){var c=this;return O(b,function(d){return 1==d.g?N(d,Kb(c),2):3!=d.g?N(d,Mb(c),3):N(d,Ob(c),0)})})};function Lb(a,b){return Z(a,function d(){var e=this,g,f;return O(d,function(h){if(b in e.D)return h.return(e.D[b]);g=e.locateFile(b,"");f=fetch(g).then(function(k){return k.arrayBuffer()});e.D[b]=f;return h.return(f)})})}A.overrideFile=function(a,b){this.i?this.i.overrideFile(a,b):this.A[a]=b};A.clearOverriddenFiles=function(){this.A={};this.i&&this.i.clearOverriddenFiles()};
A.send=function(a,b){return Z(this,function d(){var e=this,g,f,h,k,l,n,w,v,u;return O(d,function(z){switch(z.g){case 1:if(!e.g.inputs)return z.return();g=1E3*(void 0===b||null===b?performance.now():b);return N(z,e.B,2);case 2:return N(z,e.initialize(),3);case 3:f=new e.h.PacketDataList;h=M(Object.keys(a));for(k=h.next();!k.done;k=h.next())if(l=k.value,n=e.g.inputs[l]){a:{var m=e;var t=a[l];switch(n.type){case "video":var x=m.m[n.stream];x||(x=new zb(m.h,m.C),m.m[n.stream]=x);m=x;0===m.l&&(m.l=m.h.createTexture());
if("undefined"!==typeof HTMLVideoElement&&t instanceof HTMLVideoElement){var y=t.videoWidth;x=t.videoHeight}else"undefined"!==typeof HTMLImageElement&&t instanceof HTMLImageElement?(y=t.naturalWidth,x=t.naturalHeight):(y=t.width,x=t.height);x={glName:m.l,width:y,height:x};y=m.g;y.canvas.width=x.width;y.canvas.height=x.height;y.activeTexture(y.TEXTURE0);m.h.bindTexture2d(m.l);y.texImage2D(y.TEXTURE_2D,0,y.RGBA,y.RGBA,y.UNSIGNED_BYTE,t);m.h.bindTexture2d(0);m=x;break a;case "detections":x=m.m[n.stream];
x||(x=new Cb(m.h),m.m[n.stream]=x);m=x;m.data||(m.data=new m.g.DetectionListData);m.data.reset(t.length);for(x=0;x<t.length;++x){y=t[x];var D=m.data,F=D.setBoundingBox,K=x;var I=y.R;var p=new vb;W(p,1,I.X);W(p,2,I.Y);W(p,3,I.height);W(p,4,I.width);W(p,5,I.rotation);W(p,6,I.V);I=new Wa;var q=p;p=I;T(p,1,V(q,1));T(p,2,V(q,2));T(p,3,V(q,3));T(p,4,V(q,4));T(p,5,V(q,5));q=V(q,6);if(null!=q&&null!=q){S(p.g,48);p=p.g;var C=q;q=0>C;C=Math.abs(C);var r=C>>>0;C=Math.floor((C-r)/4294967296);C>>>=0;q&&(C=~C>>>
0,r=(~r>>>0)+1,4294967295<r&&(r=0,C++,4294967295<C&&(C=0)));P=r;Q=C;q=P;for(r=Q;0<r||127<q;)p.push(q&127|128),q=(q>>>7|r<<25)>>>0,r>>>=7;p.push(q)}I=Xa(I);F.call(D,K,I);if(y.M)for(D=0;D<y.M.length;++D)p=y.M[D],q=p.visibility?!0:!1,F=m.data,K=F.addNormalizedLandmark,I=x,p=Object.assign(Object.assign({},p),{visibility:q?p.visibility:0}),q=new rb,W(q,1,p.x),W(q,2,p.y),W(q,3,p.z),p.visibility&&W(q,4,p.visibility),r=p=new Wa,T(r,1,V(q,1)),T(r,2,V(q,2)),T(r,3,V(q,3)),T(r,4,V(q,4)),T(r,5,V(q,5)),p=Xa(p),
K.call(F,I,p);if(y.L)for(D=0;D<y.L.length;++D){F=m.data;K=F.addClassification;I=x;p=y.L[D];q=new nb;W(q,2,p.W);p.index&&W(q,1,p.index);p.label&&W(q,3,p.label);p.displayName&&W(q,4,p.displayName);p=new Wa;r=q;q=p;var H=V(r,1);if(null!=H&&null!=H)if(S(q.g,8),C=q.g,0<=H)S(C,H);else{for(var B=0;9>B;B++)C.push(H&127|128),H>>=7;C.push(1)}T(q,2,V(r,2));C=V(r,3);null!=C&&Ya(q,3,Ea(C));r=V(r,4);null!=r&&Ya(q,4,Ea(r));p=Xa(p);K.call(F,I,p)}}m=m.data;break a;default:m={}}}w=m;v=n.stream;switch(n.type){case "video":f.pushTexture2d(Object.assign(Object.assign({},
w),{stream:v,timestamp:g}));break;case "detections":u=w;u.stream=v;u.timestamp=g;f.pushDetectionList(u);break;default:throw Error("Unknown input config type: '"+n.type+"'");}}e.i.send(f);return N(z,e.B,4);case 4:f.delete(),z.g=0}})})};
function Pb(a,b,c){return Z(a,function e(){var g,f,h,k,l,n,w=this,v,u,z,m,t,x,y,D;return O(e,function(F){switch(F.g){case 1:if(!c)return F.return(b);g={};f=0;h=M(Object.keys(c));for(k=h.next();!k.done;k=h.next())l=k.value,n=c[l],"string"!==typeof n&&"texture"===n.type&&void 0!==b[n.stream]&&++f;1<f&&(w.P=!1);v=M(Object.keys(c));k=v.next();case 2:if(k.done){F.g=4;break}u=k.value;z=c[u];if("string"===typeof z)return y=g,D=u,N(F,Qb(w,u,b[z]),13);m=b[z.stream];if("detection_list"===z.type){if(m){var K=
m.getRectList();for(var I=m.getLandmarksList(),p=m.getClassificationsList(),q=[],C=0;C<K.size();++C){var r=K.get(C);a:{var H=new vb;for(r=new Na(r);Oa(r);)switch(r.i){case 13:var B=R(r.g);W(H,1,B);break;case 21:B=R(r.g);W(H,2,B);break;case 29:B=R(r.g);W(H,3,B);break;case 37:B=R(r.g);W(H,4,B);break;case 45:B=R(r.g);W(H,5,B);break;case 48:for(var G=r.g,L=128,Ga=0,Y=B=0;4>Y&&128<=L;Y++)L=G.h[G.g++],Ga|=(L&127)<<7*Y;128<=L&&(L=G.h[G.g++],Ga|=(L&127)<<28,B|=(L&127)>>4);if(128<=L)for(Y=0;5>Y&&128<=L;Y++)L=
G.h[G.g++],B|=(L&127)<<7*Y+3;if(128>L){G=Ga>>>0;L=B>>>0;if(B=L&2147483648)G=~G+1>>>0,L=~L>>>0,0==G&&(L=L+1>>>0);G=4294967296*L+(G>>>0);B=B?-G:G}else G.l=!0,B=void 0;W(H,6,B);break;default:if(!mb(r))break a}}H={X:X(H,1),Y:X(H,2),height:X(H,3),width:X(H,4),rotation:X(H,5,0),V:kb(H,6)};B=I.get(C);a:for(r=new tb,B=new Na(B);Oa(B);)switch(B.i){case 10:G=Qa(B,new rb,sb);ib(r,G,rb,void 0);break;default:if(!mb(B))break a}r=hb(r,rb).map(yb);G=p.get(C);a:for(B=new pb,G=new Na(G);Oa(G);)switch(G.i){case 10:B.addClassification(Qa(G,
new nb,ob));break;default:if(!mb(G))break a}H={R:H,M:r,L:xb(B)};q.push(H)}K=q}else K=[];g[u]=K;F.g=7;break}if("proto_list"===z.type){if(m){K=Array(m.size());for(I=0;I<m.size();I++)K[I]=m.get(I);m.delete()}else K=[];g[u]=K;F.g=7;break}if(void 0===m){F.g=3;break}if("proto"===z.type){g[u]=m;F.g=7;break}if("texture"!==z.type)throw Error("Unknown output config type: '"+z.type+"'");t=w.s[u];t||(t=new zb(w.h,w.C),w.s[u]=t);return N(F,Ab(t,m,1===f),12);case 12:x=F.h,g[u]=x;case 7:z.transform&&g[u]&&(g[u]=
z.transform(g[u]));F.g=3;break;case 13:y[D]=F.h;case 3:k=v.next();F.g=2;break;case 4:return F.return(g)}})})}function Qb(a,b,c){return Z(a,function e(){var g=this,f;return O(e,function(h){return"number"===typeof c||c instanceof Uint8Array||c instanceof g.h.Uint8BlobList?h.return(c):c instanceof g.h.Texture2dDataOut?(f=g.s[b],f||(f=new zb(g.h,g.C),g.s[b]=f),h.return(Ab(f,c,g.P))):h.return(void 0)})})}
function Nb(a,b){for(var c=b.name||"$",d=[].concat(ea(b.wants)),e=new a.h.StringList,g=M(b.wants),f=g.next();!f.done;f=g.next())e.push_back(f.value);g=a.h.PacketListener.implement({onResults:function(h){for(var k={},l=0;l<b.wants.length;++l)k[d[l]]=h.get(l);var n=a.listeners[c];n&&(a.B=Pb(a,k,b.outs).then(function(w){w=n(w);for(var v=0;v<b.wants.length;++v){var u=k[d[v]];"object"===typeof u&&u.hasOwnProperty&&u.hasOwnProperty("delete")&&u.delete()}w&&(a.B=w)}))}});a.i.attachMultiListener(e,g);e.delete()}
A.onResults=function(a,b){this.listeners[b||"$"]=a};ya("Solution",Ib);ya("OptionType",{BOOL:0,NUMBER:1,Z:2,0:"BOOL",1:"NUMBER",2:"STRING"});function Rb(a){void 0===a&&(a=0);switch(a){case 1:return"selfie_segmentation_landscape.tflite";default:return"selfie_segmentation.tflite"}}
function Sb(a){var b=this;a=a||{};this.g=new Ib({locateFile:a.locateFile,files:function(c){return[{simd:!0,url:"selfie_segmentation_solution_simd_wasm_bin.js"},{simd:!1,url:"selfie_segmentation_solution_wasm_bin.js"},{data:!0,url:Rb(c.modelSelection)}]},graph:{url:"selfie_segmentation.binarypb"},listeners:[{wants:["segmentation_mask","image_transformed"],outs:{image:{type:"texture",stream:"image_transformed"},segmentationMask:{type:"texture",stream:"segmentation_mask"}}}],inputs:{image:{type:"video",
stream:"input_frames_gpu"}},options:{useCpuInference:{type:0,graphOptionXref:{calculatorType:"InferenceCalculator",fieldName:"use_cpu_inference"},default:"iPad Simulator;iPhone Simulator;iPod Simulator;iPad;iPhone;iPod".split(";").includes(navigator.platform)||navigator.userAgent.includes("Mac")&&"ontouchend"in document},selfieMode:{type:0,graphOptionXref:{calculatorType:"GlScalerCalculator",calculatorIndex:1,fieldName:"flip_horizontal"}},modelSelection:{type:1,graphOptionXref:{calculatorType:"ConstantSidePacketCalculator",
calculatorName:"ConstantSidePacketCalculatorModelSelection",fieldName:"int_value"},onChange:function(c){return Z(b,function e(){var g,f,h=this,k;return O(e,function(l){if(1==l.g)return g=Rb(c),f="third_party/mediapipe/modules/selfie_segmentation/"+g,N(l,Lb(h.g,g),2);k=l.h;h.g.overrideFile(f,k);return l.return(!0)})})}}}})}A=Sb.prototype;A.close=function(){this.g.close();return Promise.resolve()};A.onResults=function(a){this.g.onResults(a)};
A.initialize=function(){return Z(this,function b(){var c=this;return O(b,function(d){return N(d,c.g.initialize(),0)})})};A.reset=function(){this.g.reset()};A.send=function(a){return Z(this,function c(){var d=this;return O(c,function(e){return N(e,d.g.send(a),0)})})};A.setOptions=function(a){this.g.setOptions(a)};ya("SelfieSegmentation",Sb);ya("VERSION","0.1.1629494320");}).call(this);
