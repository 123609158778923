//TODO:Stand-alone or configurable/integrated with chat widget
var useFAQChatInFAQ;
if(typeof FAQ_WITH_CHAT == "undefined" || FAQ_WITH_CHAT == false){
	useFAQChatInFAQ = false;
}else {
	useFAQChatInFAQ = true;
}

// TODO: create a proper shortcut function to hide/show element, also to manipulate DOM and style
// using native js element selector
// Will using these function below for further enhancement
function showingOrHideElementByID(id, show) {
	if(show) {
		document.getElementById(id).style.display = "block";
	}else {
		document.getElementById(id).style.display = "none";
	}
}
function changeCSSProperty(id, property, value) {
	document.getElementById(id).style[property] = value;
}

const FAQ_TOGGLE_DURATION = 100;
const FAQ_CONTENT_MAX_HEIGHT = "80vh";
const FAQ_CONTENT_MAX_WIDTH = "65vw";
let FAQ_CONTENT_DEFAULT_HEIGHT = "550px";
let FAQ_CONTENT_DEFAULT_WIDTH = "330px";
let FAQ_CONTENT_FULL_HEIGHT = "100%";
let FAQ_CONTENT_FULL_WIDTH = "100%";
var FAQ_SKIN = "";
var CentionChatStatus = {};
//style script
function loadScriptsFAQ(fileName) { //for loading FAQ related scripts only
	var faqWidgetCSS = CloudFrontURL+"/chat/css/"+fileName;
	var widgetStyle = document.createElement("link");
	widgetStyle.setAttribute("rel", "stylesheet");
	widgetStyle.setAttribute("type", "text/css");
	widgetStyle.setAttribute("href", faqWidgetCSS);
	if(typeof FAQ_WITH_CHAT !== "undefined" ) {
		document.head.appendChild(widgetStyle);
	}
}
// NOTES:
// CentionBaseURL & spacePrefix was defined in chat script
loadScriptsFAQ("cention-icon.css");
loadScriptsFAQ("faqWidget.css");

let FAQIsConnected = false;

var CentionFAQ = function(wrapper, params) {
	c3jQuery(function($) {
		const container = c3jQuery("#"+wrapper);
		var areaId = params.areaId;
		const ui = params.ui;
		var uiStyle = "";
		var uiPosition = "";
		var url = null; 
		var Id= params.widgetId;
		var libraryId = params.libraryId; //ids for knowledgebase
		var topList = params.topList; // enable topslist(boolean)
		var topListLimit = params.topListLimit; //limit number of list to be shown on toplist
		var topListMinVotes = params.topListMinVotes //min votes required to be on toplist
		var browseLibrary = params.browseLibrary; // enable libraryTree (boolean)
		var featuredFirstLibrary = params.featuredFirstLibrary;
		var hasChat = true;
		var hasChannel = false;
		var hasEnableCallback = false;
		var hasEULA = false;

		// dom UI var
		var rightChevron = `<i class="icon-chevron-right"></i>`;

		if (c3jQuery.isArray(params.areaId)) {
			if (params.areaId.length > 0) {
				areaId = params.areaId[0].id;
			}
		}

		if(!spacePrefix){
			spacePrefix = "";
		}

		params.faqWidgetCfg = "";
		if (areaId != null) {
			url = `${CentionBaseURL}${spacePrefix}/cention/chat/faqWidgetConfig`;
			fetchFaqConfig(areaId);
		} else {
			url = `${CentionBaseURL}${spacePrefix}/cention/chat/faqWidgetConfigWidgetId`;
			fetchFaqConfigWidgetId(Id);
		}

		function fetchFaqConfig(Id) {
			// Initially try to get the token from localStorage
			const token = localStorage.getItem('cenChatToken');

			// Include the token in the request if it exists
			const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
			fetch(`${url}?a=${Id}`, { headers })
				.then(response => response.json())
				.then(data => {
					if (data.token) {
						// If server sends a new token, replace the old one
						localStorage.setItem('cenChatToken', data.token);
					}

					if (data.error) {
						CentionFAQBase();
						return;
					}

					if (data.faqWidgetCfg) {
						params.faqWidgetCfg = data.faqWidgetCfg;
						CentionFAQBase();
					}
				})
				.catch(error => {
					console.error('Error fetching FAQ Widget Config:', error);
					CentionFAQBase();
				});
		}

		function fetchFaqConfigWidgetId(Id) {
			// Initially try to get the token from localStorage
			const token = localStorage.getItem('cenChatToken');

			// Include the token in the request if it exists
			const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
			fetch(`${url}?a=${Id}`, { headers })
				.then(response => response.json())
				.then(data => {
					if (data.token) {
						// If server sends a new token, replace the old one
						localStorage.setItem('cenChatToken', data.token);
					}

					areas = data.faqWidgetCfg.areaId;
					areaId = data.faqWidgetCfg.areaId[0].Id;

					if (data.faqWidgetCfg.areaId.length > 1) {
						CentionChatUseDynamicArea = true;
					}

					if (data.error) {
						CentionFAQBase();
						return;
					}

					if (data.faqWidgetCfg) {
						params.faqWidgetCfg = data.faqWidgetCfg;
						CentionFAQBase();
					}
				})
				.catch(error => {
					console.error('Error fetching FAQ Widget Config:', error);
					CentionFAQBase();
				});
		}
	
		
		function CentionFAQBase() {
			if(typeof params.ui != "undefined"){
				if(typeof params.ui.style != "undefined"){
					uiStyle = params.ui.style;
				}else {
					uiStyle = WIDGET;
				}
			}else {
				uiStyle = WIDGET;
			}

			if(typeof params.faqWidgetCfg.ui != "undefined") {
				if(params.faqWidgetCfg.ui.enableCallbackRequest) {
					loadScriptsFAQ("jquery.datetimepicker.css");
					loadScriptsFAQ("voiceWidget.css");
					hasEnableCallback = true;
				}
				hasChat = params.faqWidgetCfg.ui.hasChat;
				hasEULA = params.faqWidgetCfg.ui.askEULA;
			}

			if (typeof params.translation !== 'object') {
				if(isJsonStringObj(params.faqWidgetCfg.translation)) {
					translation = JSON.parse(params.faqWidgetCfg.translation);
				}else {
					translation = {};
				}
			}else{
				translation = params.translation;
			}

			//Provide default values of undefined parameters

			if (typeof params.libraryId == "undefined") {
				if (typeof params.faqWidgetCfg.libraryId != "undefined") {
					var libArr = params.faqWidgetCfg.libraryId.split(",")
					libraryId = libArr.map(x => parseInt(x));

				}else {
					libraryId = [1];
				}
			}

			if (typeof params.topList == "undefined") {
				if (typeof params.faqWidgetCfg.topList != "undefined") {
					topList = params.faqWidgetCfg.topList;
				}else {
					topList = true;
				}
			}

			if (typeof params.browseLibrary == "undefined") {
				if (typeof params.faqWidgetCfg.browseLibrary != "undefined") {
					browseLibrary = params.faqWidgetCfg.browseLibrary;
				}else {
					browseLibrary = true;
				}
			}

			if (typeof params.featuredFirstLibrary == "undefined") {
				if (typeof params.faqWidgetCfg.featuredFirstLibrary != "undefined") {
					featuredFirstLibrary = params.faqWidgetCfg.featuredFirstLibrary;
				}else {
					featuredFirstLibrary = false;
				}
			}

			if(typeof params.topListLimit == "undefined") {
				if (typeof params.faqWidgetCfg.topListLimit != "undefined") {
					topListLimit = params.faqWidgetCfg.topListLimit;
				}else {
					topListLimit = 10;
				}
			}

			if(typeof params.topListMinVotes == "undefined") {
				if (typeof params.faqWidgetCfg.topListMinVotes != "undefined") {
					topListMinVotes = params.faqWidgetCfg.topListMinVotes;
				}else {
					topListMinVotes = 0;
				}
			}

			if(typeof ui != "undefined") {
				if (ui["hasChat"] == false) {
					hasChat = ui["hasChat"];
				}
			}

			if (!window.console) window.console = {};
			if (!window.console.log) window.console.log = function () { };
			if (!String.prototype.startsWith) {
				String.prototype.startsWith = function(searchString, position){
					position = position || 0;
					return this.substring(position, searchString.length) === searchString;
				};
			}

			if(uiStyle == WIDGET) {
				const faqWidgetHTML = CentionBaseURL+spacePrefix+"/cention/chat/faqWidget?a="+areaId
					+ (params.languageCode?"&lang="+params.languageCode:"");
				c3jQuery.get(CloudFrontURL + "/chat/faqWidget.html")
				.done(function(html){
					container.html(html);
					loadWidget();
				}).fail(function(error){
					console.log("fail fetching widget due to error : ", error);
				});


				function loadWidget() {
					c3jQuery.ajax({
						url : faqWidgetHTML,
						dataType: 'json',
						xhrFields: {
							withCredentials: true
						}
					}).done(function(result) {
						console.log('data - ', result.data);
						CentionChatStatus = result.data.CentionChatStatus;
						container.append(CentionChatStatus);
						loadFaqWidget(CentionBaseURL,areaId);
						if(hasChat){
							CentionChat(null, params); //call chat functions from cention-chat.js
						}
						if(spacePrefix){
							const spaceNameFAQ = spacePrefix.substring(spacePrefix.lastIndexOf('/') + 1);
							c3jQuery("#CentionFAQ").addClass(spaceNameFAQ);
						}
					}).fail(function(error) {
						console.log("widget failed to load due to error:  ", error);
						var faqElem = document.getElementById("CentionFAQ");
						faqElem.style.display = "none";
						return;
					});
				}
			}
		}
		//init base UI
		function loadBasicUI() {
			c3jQuery("#CentionFAQContainer").hide();
			c3jQuery("#iconBackHeader").hide();
			c3jQuery("#FAQ-search-close").hide();
			c3jQuery('#CentionFAQSearched').hide();
			c3jQuery("#ContactChat").hide();
			c3jQuery(".FeedbackMessage").hide();
			c3jQuery("#FAQChatContainer").hide();
			c3jQuery("#ViewPage-BrowseLibrary").hide();
			c3jQuery("#ViewPage-Chat").hide()
			c3jQuery("#ViewPage-BrowseTree").hide()
			c3jQuery("#trigger-panel-close").hide();
			c3jQuery("#FAQBreadcrumbs").hide();
			c3jQuery("#BackHome").hide();

			if(CentionChatStatus.feature['sip.enabled'] && hasEnableCallback) {
				c3jQuery("#CallbackWrapper").show();
				c3jQuery("#CentionFAQContainer").addClass("withCallback");
				c3jQuery("#CentionFAQFeatured").addClass("withCallback");

				if(hasEULA){
					c3jQuery("#CentionFAQContainer").addClass("withEULA");
				}
			}

			//for skin frictionless

			if(FAQ_SKIN == SKIN_FRICTIONLESS){
				document.querySelector("#FAQ-HomeLogo > img").src = CloudFrontURL + "/chat/images/cention-mini-o-white.svg";
				document.querySelector(".CentionFAQFeedback >.FeedbackUpvote > img").src = CloudFrontURL + "/chat/images/c3-faq-upvote-emoji.png";
				document.querySelector(".CentionFAQFeedback >.FeedbackDownvote > img").src = CloudFrontURL + "/chat/images/c3-faq-downvote-emoji.png";
			}
		}

		function isJsonStringObj(text){
			try{
				if(typeof JSON.parse(text) === "object"){
					return true;
				}else{
					return false;
				}
			} catch(error){
				return false;
			}
		}

		function loadFaqWidget(baseURL, areaId) {

			//Initial configs as follows:
			//-BaseURL
			//-AreaId
			//-LibraryId and/or CategoryId
			//-ChatEnabled (optional)

			var CentionFAQ = _CentionFAQ();
			CentionFAQ.baseURL = baseURL;

			if(!spacePrefix){
				spacePrefix = "";
			}
			/******************/
			/*    Configs     */
			/******************/
			var defaultFAQSkin = SKIN_DEFAULT;

			var defaultFAQConfig = {
				//BEGIN_UI-------
				// skin: SKIN_DEFAULT,
				hasChat : true,
				showContactPage: true, //By default open the Contact Page before accessing the chat page
				position: "bottomRight", //Position of the widget (either "bottomLeft" or "bottomRight")
				zIndex: "",
				//END_UI

				//BEGIN_CSS
				mainBgColor: "#FFFFFF", //base background color for the whole FAQ widget by default
				mainTextColor: "#000000", //base text color for the whole FAQ widget by default
				headerBgColor: "#FFBF19", //background color of the header
				headerTextColor: "#3F3F3F", //text color of the header
				iconSearchColor: "#808080", //color of the Search icon
				iconContactColor: "#00000080",
				btnContactBgColor: "#424242", //background color of the contact button
				btnContactTextColor: "#FFFFFF", //text color of the contact button
				btnFeedbackBgColor: "#FFBF19",
				btnFeedbackTextColor: "#FFFFFF",
				btnLaunchChatBgColor: "#FFBF19",
				btnLaunchChatTextColor: "#FFFFFF",
				triggerPanelBgColor: "#FFBF19",
				triggerPanelTextColor: "#FFFFFF",
				footerTextColor: "#CCCCCC",
				footerLogoTextColor: "#000000",
				footerLink: "https://cention.com",
				footerText: "Powered by <img src='"+CloudFrontURL+"/img/cention-logo-black_smallest.png' style='width: 40%' />",
				searchBarTextColor: "#000000",
				searchBarBgColor: "#FFFFFF",
				questionListBgColor: "#F0F0F0", //for top questions
				questionListTextColor: "#000000", //for top questions
				libraryListBgColor: "#F0F0F0",
				libraryListTextColor: "#000000",
				tabIconTextColor: "#FFBF19", //icons color on main page
				tabIconBgColor: "#FFF7E5", //icons bg color on main page
				faqChatFooterBgColor: "#FFFFFF",
				//END_CSS
			}
			var defaultFAQText = {
				//BEGIN_TEXT
				textPanelTrigger: "Need help?", //text on the trigger panel
				textHeaderTitle: "Hello, how can I help you?", //text on the widget header
				textInputSearch : "Search for a question..", //placeholder label for the search input
				textFeatured : "Frequently asked questions in all categories", //text heading on the featured section
				textBrowseKnowledgebase: "Browse knowledgebase", //text heading on the browse section
				textSearchResult: "Showing all related results for", //heading text of the search
				textBtnContact: "Contact Us", //text label for the contact button on featured page
				textFeedbackQuestion : "Did you get the answer to your question?", //feedback label at bottom of answer
				textBtnUpvote: "Yes", //button label for upvote
				textBtnDownvote: "No - Contact Us", //button label for downvote
				textFeedbackMsg: "Thank you! Glad it helped", //text pop-out after upvote
				textCategoryHeading: "Categories",

				textContactHeading: "Contact Us", //heading text on contact section
				textEULA: 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.',
				textCookieWarning: "* Please allow cookie from {CENTION_BASE_URL} to make sure your chat continues if you browse to other pages during the chat.",

				//contact no details
				textContactNumTitle: "Contact Us", //text title for call
				textContactNumLine1: "tel-no +60 12345789", //text for contact no
				//operating hours details
				textContactHoursTitle: "Operating Hours",
				textContactHoursLine1: "Monday-Friday: 9-5pm", //time or any description 1
				textContactHoursLine2: "Saturday-Sunday: 9-5pm", //time or any description 2
				//chat details
				textContactChatTitle: "Chat with Us", //text title for chat
				textFAQChatLauncher: "Open Chat", // text for chat launcher
				textContactChatLine1: "Weekdays: 9 - 5pm", //time or any description 1
				textContactChatLine2: "Saturday-Sunday: 00:00-12:00", //time or any description 2
				//email details
				textContactEmailTitle: "Email Us", // text tittle for email
				textContactEmailLine1: "support@example.com", //text for email contact
				textContactEmailLine2: "We will get to you as soon as we can in 24 hours", //description 2
				textContactEmailLine3: "", //description 2
				//not found items
				textListNotFoundDesc1: "Oops! It looks like there is nothing available at the moment", // top-list not found text description 1
				textListNotFoundDesc2: "Not to worry, you can still contact us", // top-list not found text description 2
				textButtonStart: "",

				//top buttons/links
				textFAQButton1:"TOP FAQ", // text label for first link on the top of FAQ widget
				textFAQButton2:"Browse", // text label for second link on the top of FAQ widget
				textFAQButton3:"Chat", // text label for third link on the top of FAQ widget

				//for callback request
				textCallbackTitle: 'Request a call back',
				textCallbackWorkingHours: "Monday - Friday: 9:00am - 6:00pm",
				textCallbackInputName: "Name",
				textCallbackInputEmail: "Email",
				textCallbackInputQuestion: "Question",
				textCallbackInputPhone: "Phone",
				textNameInvalid: "You must enter a valid name",
				textMessageEmailInvalid: "You must enter a valid email address",
				textMsgToCall: "When to call",
				textBtnSend: "Send request",
				textBtnCancel: "Cancel",
				textDateError: "Please select another date or time",
				textMsgSendSuccess: 'You have requested a callback through phone number at {SCHEDULE_TIME}, we will reach you at the appointed time.'
				//END_TEXT
			}

			//*---Start of Config Functions---*//

			//FAQ SKIN CONFIG
			function getCustomSkin() {
				var value = "";
				if(typeof params.skin != "undefined"){
					//check if objects value legit
					if(params.skin) {
						value = params.skin;
					}else {
						value = defaultFAQSkin;
					}
				}else if(typeof params.faqWidgetCfg.skin != "undefined"){
					if(params.faqWidgetCfg.skin){
						value = params.faqWidgetCfg.skin;
					}else {
						value = defaultFAQSkin;
					}
				}else{
					value = defaultFAQSkin;
				}
				return value;
			}

			//FAQ CSS CONFIG
			function getCustomConfig(name) {
				var value = "";
				if(typeof params.css != "undefined"){
					//check if objects value legit
					if(params.css[name]) {
						value = params.css[name];
					}else {
						value = defaultFAQConfig[name];
					}
				}else if(typeof params.faqWidgetCfg.css != "undefined"){
					if(params.faqWidgetCfg.customize && params.faqWidgetCfg.css[name]){
						value = params.faqWidgetCfg.css[name];
					}else {
						value = defaultFAQConfig[name];
					}
				}else{
					value = defaultFAQConfig[name];
				}
				return value;
			}

			//FAQ UI CONFIG
			function getCustomFAQUI(name) {
				var value = "";
				if(typeof params.ui != "undefined"){
					if(params.ui[name] ||( params.ui[name] == false)){
						value = params.ui[name];
					}else {
						value = defaultFAQConfig[name];
					}
				}else if(typeof params.faqWidgetCfg.ui != "undefined"){
					if(params.faqWidgetCfg.customize && (params.faqWidgetCfg.ui[name] ||( params.faqWidgetCfg.ui[name] == false))){
						value = params.faqWidgetCfg.ui[name];
					}else {
						value = defaultFAQConfig[name];
					}
				}else {
					value = defaultFAQConfig[name];
				}
				return value;
			}

			//FAQ TEXT CONFIG
			function getCustomText(name) {
				var value = "";
				if(typeof params.text != "undefined"){
					if(params.text[name]) {
						value = params.text[name];
					}else {
						value = defaultFAQText[name];
					}
				}else if(typeof params.faqWidgetCfg.text != "undefined"){
					if(params.faqWidgetCfg.customize && params.faqWidgetCfg.text[name]) {
						value = params.faqWidgetCfg.text[name];
					}else {
						value = defaultFAQText[name];
					}
				}else{
					value = defaultFAQText[name];
				}
				return value;
			}

			let clickLibraryFromHome = false;
			let openQuestionFromBrowseTree = false;

			//Start of SKIN
			if(getCustomSkin() == SKIN_MODERN){
				c3jQuery('#Skin-Default').html("");
				c3jQuery('#Skin-Frictionless').html("");
				c3jQuery("#FAQSearchBar").hide();
				c3jQuery("#BrowseLibrary").hide();
			} else if (getCustomSkin() == SKIN_FRICTIONLESS){
				c3jQuery('#Skin-Modern').html("");
				c3jQuery('#Skin-Default').html("");
			} else {
				c3jQuery('#Skin-Modern').html("");
				c3jQuery('#Skin-Frictionless').html("");
			}
			FAQ_SKIN = getCustomSkin();

			const gradientHeaderBg = "linear-gradient(0deg, rgba(255,255,255,1) 0%, "+ getCustomConfig("headerBgColor") +" 62%)";
			const solidHeaderBgColor = getCustomConfig("headerBgColor");

			loadBasicUI();
			function resetFrictionlessDefaultHeader() {
				document.getElementById("CentionFAQHeader").style.background = "none";
				document.getElementById("CentionFAQHeader").style.backgroundColor = solidHeaderBgColor;
			}

			if(hasEnableCallback){
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					document.getElementById("tabCallbackWrapper").style.display = "block";
					c3jQuery("#CentionFAQHeader").addClass("with-callback");
				}
			}

			//configs const
			const config_hideAgentsAvatar = getCustomFAQUI("hideAgentAvatarList");
			const config_full_screen_mobile = getCustomFAQUI("faqFullScreenOnSmallScreen");
			/**************************************************************/
			/* Apply Basic Custom Style If Provided By Third Party Client */
			/**************************************************************/

			var agentAvatarList = [];
			if(document.getElementsByClassName("agentAvatar")) {
				agentAvatarList = document.getElementsByClassName("agentAvatar");
			}
			if(getCustomFAQUI("hasChat")) {
				c3jQuery("#ContactChat").show();
				if(config_hideAgentsAvatar) {
					for (var i = 0; i < agentAvatarList.length; i++) {
						agentAvatarList[i].style.display = "none";
					}
				} else {
					for (var i = 0; i < agentAvatarList.length; i++) {
						agentAvatarList[i].style.display = "block";
					}
				}
			} else  {
				document.getElementById("agentAvatarList").style.display = "none";
			}
			if(getCustomText("textButtonStart")){
				c3jQuery("#textButtonStart").val(getCustomText("textButtonStart"));
				c3jQuery("#textButtonStart").html(getCustomText("textButtonStart"));
				c3jQuery("#textButtonStart").addClass("text");
			}

			//zIndex
			if(getCustomFAQUI("zIndex") != ""){
				c3jQuery("#cention-faq-container").css("z-index", getCustomFAQUI("zIndex"));
			}

			if (!c3jQuery('#CentionChannelView').is(':visible')) {
				var messengerFAQ = "messengerFAQ";
				var telegramFAQ = "telegramFAQ";
				var whatsAppFAQ = "whatsAppFAQ";
				var chatFAQ = "chatFAQ";
				if((getCustomText(messengerFAQ) && getCustomText(messengerFAQ).trim() !== "") ||
				   (getCustomText(telegramFAQ) && getCustomText(telegramFAQ).trim() !== "") ||
				   (getCustomText(whatsAppFAQ) && getCustomText(whatsAppFAQ).trim() !== "")
				){
					hasChannel = true;
					c3jQuery("#CentionStartView").hide();
					c3jQuery("#CentionChannelView").show();
					var ChannelURL = "";
					var ChannelClass = "channelStyle";
					var ChannelListClass = "channelListStlye";
					var ChannelLabelClass = "channelLabelStyle";
					if(getCustomText(messengerFAQ) && getCustomText(messengerFAQ).trim() !== "") {
						ChannelURL = getCustomText("messengerFAQ").trim();
						var ChannelStyleClass = ChannelClass + " " + messengerFAQ;
						var ChannelListStyleClass = ChannelListClass + " " + "icon-v5-messenger";
						c3jQuery(".channelList").append("<a href=\"" +ChannelURL+"\" class=\"" +ChannelStyleClass +"\" target='_blank'><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">Messenger</p></a>");
					}
					if(getCustomText(telegramFAQ) && getCustomText(telegramFAQ).trim() !== "") {
						ChannelURL = getCustomText("telegramFAQ").trim();
						var ChannelStyleClass  = ChannelClass + " " + telegramFAQ;
						var ChannelListStyleClass = ChannelListClass + " " + "icon-telegram";
						c3jQuery(".channelList").append("<a href=\"" +ChannelURL+"\" class=\"" +ChannelStyleClass +"\" target='_blank'><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">Telegram</p></a>");
					}
					if(getCustomText(whatsAppFAQ) && getCustomText(whatsAppFAQ).trim() !== "") {
						ChannelURL = getCustomText("whatsAppFAQ").trim();
						var ChannelStyleClass  = ChannelClass + " " + whatsAppFAQ;
						var ChannelListStyleClass = ChannelListClass + " " + "icon-v5-whatsapp";
						c3jQuery(".channelList").append("<a href=\"" +ChannelURL+"\" class=\"" +ChannelStyleClass +"\" target='_blank'><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">WhatsApp</p></a>");
					}
					var ChannelStyleClass  = ChannelClass + " " + chatFAQ;
					var ChannelListStyleClass = ChannelListClass + " " + "icon-chat";
					c3jQuery(".channelList").append("<div id='chatWithUs' class=\"" +ChannelStyleClass +"\"><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">Chat With Us</p></div>");
				}
			}
			//Main Base
			c3jQuery("#CentionFAQContainer").css("background-color", getCustomConfig("mainBgColor"));
			c3jQuery("#CentionFAQContainer").css("color", getCustomConfig("mainTextColor"));

			const isMobileDeviceSize = window.innerWidth <= 768;
			let mobileFullScreenMode = false;

			if(config_full_screen_mobile && isMobileDeviceSize) {
				mobileFullScreenMode = true;
				document.getElementById("CentionFAQContainer").classList.add("cention-full-screen-faq");
				document.getElementById("CentionFAQPanelTriggerContainer").classList.add("cention-full-screen-faq");
				if(getCustomSkin() == SKIN_MODERN){
					if(c3jQuery('#ViewPage-Contact, #ViewPage-Answer, #ViewPage-Chat').is(":visible")){
						c3jQuery('#FAQSearchBar').hide()
					}
				}
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					if(getCustomFAQUI("hasChat") && !config_hideAgentsAvatar) {
						document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "block";
					}
				}
			}

			//Headers
			document.getElementById("CentionFAQHeader").style.backgroundColor = solidHeaderBgColor;

			c3jQuery("#CentionFAQHeader").css("color", getCustomConfig("headerTextColor"));
			if(getCustomSkin() == SKIN_FRICTIONLESS){
				if(document.getElementById("iconMinimmize")) {
					document.getElementById("iconMinimmize").style.color = getCustomConfig("headerTextColor");
				}
				document.getElementById("textTitle-FAQ").style.color = getCustomConfig("headerTextColor");
				document.getElementById("CentionFAQHeader").style.background = gradientHeaderBg;

				const contactHeader = document.querySelectorAll(".contactHeader");
				contactHeader.forEach((header) => {
					header.style.color = getCustomConfig("headerTextColor");
				});
			}
			//Icons
			c3jQuery("#FAQ-search-icon").css("color", getCustomConfig("iconSearchColor"));
			c3jQuery(".FAQ-icon-contact").css("color", getCustomConfig("iconContactColor"));
			//Buttons
			// c3jQuery("#CentionFAQBtnContact").css("background-color", getCustomConfig("btnContactBgColor"));
			// c3jQuery("#CentionFAQBtnContact").css("color", getCustomConfig("btnContactTextColor"));

			if(getCustomSkin() !== SKIN_FRICTIONLESS){
				c3jQuery(".FAQ-Button-feedback").css("background-color", getCustomConfig("btnFeedbackBgColor"));
				c3jQuery(".FAQ-Button-feedback").css("color", getCustomConfig("btnFeedbackTextColor"));
			} else {
				c3jQuery(".FAQ-Button-feedback").css("background-color", 'transparent');
			}

			c3jQuery(".buttonLaunchChat").css("background-color", getCustomConfig("btnLaunchChatBgColor"));
			c3jQuery(".buttonLaunchChat").css("color", getCustomConfig("btnLaunchChatTextColor"));

			//Trigger Panel
			c3jQuery("#CentionFAQPanelTrigger").css("background-color", getCustomConfig("triggerPanelBgColor"));
			c3jQuery("#CentionFAQPanelTrigger").css("color", getCustomConfig("triggerPanelTextColor"));
			c3jQuery("[id*='trigger-panel-']").css("color", getCustomConfig("triggerPanelTextColor"))

			//Footers
			c3jQuery("#CentionFAQFooterContainer").css("background-color", getCustomConfig("faqChatFooterBgColor"));
			// c3jQuery("#CentionWidgetFooter > a").css("color", getCustomConfig("footerTextColor"));
			if(!CentionChatStatus.feature['chat.hide-cention-logo']){
				c3jQuery("#CentionFAQFooterLogo > a").css("color", getCustomConfig("footerLogoTextColor"));
				c3jQuery("#CentionFAQFooterLogo > a").attr("href", getCustomConfig("footerLink"));
				c3jQuery("#CentionFAQFooterLogo > a").html(getCustomConfig("footerText"));
			}
			//others
			c3jQuery(".CentionFAQSearchWrapper2").css("background-color", getCustomConfig("searchBarBgColor"));
			c3jQuery("#FAQ-search-close").css("color", getCustomConfig("searchBarTextColor"));
			c3jQuery("#FAQ-search-input").css("color", getCustomConfig("searchBarTextColor"));
			c3jQuery("#CentionChatBody").css("background-color", getCustomConfig("mainBgColor"));

			c3jQuery(".tab-icon").css("background-color", getCustomConfig("tabIconBgColor"));
			c3jQuery(".tab-icon").css({"color": getCustomConfig("tabIconTextColor"),"border-color": getCustomConfig("tabIconTextColor")});

			c3jQuery(".active-icon").css({
				"color": getCustomConfig("tabIconBgColor"),
				"background-color": getCustomConfig("tabIconTextColor")
			});

			//widget position (by css class)
			if(getCustomFAQUI("position") == "bottomLeft"){
				c3jQuery("#CentionFAQContainer").addClass("left-align");
				c3jQuery("#CentionFAQPanelTriggerContainer").addClass("left-align");
			} else {
				//defaults to position "bottomRight"
				c3jQuery("#CentionFAQContainer").addClass("right-align");
				c3jQuery("#CentionFAQPanelTriggerContainer").addClass("right-align");
			}

			//Hide list of agent's avatars on the FAQ widget
			var agentAvatars = document.getElementsByClassName("agentAvatar");
			if(config_hideAgentsAvatar) {
				if(agentAvatarList && agentAvatarList.length > 0) {
					for (var i = 0; i < agentAvatarList.length; i++) {
						agentAvatars[i].style.display = "none";
					}
				}
			}else{
				if(agentAvatarList && agentAvatarList.length > 0) {
					for (var i = 0; i < agentAvatarList.length; i++) {
						agentAvatars[i].style.display = "block";
					}
				}
			}
			//Gradient removed for now
			// var gradient = "linear-gradient(to bottom, rgba(255, 0, 0, 0),"+ getCustomConfig("mainBgColor") + "," + getCustomConfig("mainBgColor") + ")";
			// c3jQuery(".footerFadeBottom").css("background", gradient);

			/****************/
			/* Text Configs */
			/****************/
			// c3jQuery("#textPanelTrigger").text(getCustomText("textPanelTrigger"));
			//FAQBUTTON
			c3jQuery("#FAQButton1").text(getCustomText("textFAQButton1"));
			c3jQuery("#FAQButton2").text(getCustomText("textFAQButton2"));
			c3jQuery("[id=FAQButton3]").text(getCustomText("textFAQButton3"));

			c3jQuery("#NavTextTopList").text(getCustomText("textFAQButton1"));
			c3jQuery("#NavTextLibrary").text(getCustomText("textFAQButton2"));
			c3jQuery("#NavTextChat, #ChatFormText").text(getCustomText("textFAQButton3"));

			c3jQuery("#textTitle-FAQ").text(getCustomText("textHeaderTitle"));
			const faqSearchInput = document.querySelectorAll("#FAQ-search-input");
			faqSearchInput.forEach((searchInput) => {
				searchInput.placeholder = getCustomText("textInputSearch");
			});
			c3jQuery("#textCategoryHeading").text(getCustomText("textCategoryHeading"))
			// c3jQuery("#textFeatured").text(getCustomText("textFeatured"));
			// c3jQuery("#textBrowseKnowledgebase").text(getCustomText("textBrowseKnowledgebase"));
			c3jQuery("#textSearchResult span:first-child").text(getCustomText("textSearchResult"));
			// c3jQuery("#CentionFAQBtnContact").text(getCustomText("textBtnContact"));
			c3jQuery(".textFeedbackHeading").text(getCustomText("textFeedbackQuestion"));
			if(getCustomSkin() !== SKIN_FRICTIONLESS){
				c3jQuery(".FeedbackUpvote span").text(getCustomText("textBtnUpvote"));
				c3jQuery(".FeedbackDownvote span").text(getCustomText("textBtnDownvote"));
			} else {
				document.querySelector(".FeedbackUpvote > img").title = getCustomText("textBtnUpvote");
				document.querySelector(".FeedbackDownvote > img").title = getCustomText("textBtnDownvote");
			}

			c3jQuery(".FeedbackMessage").text(getCustomText("textFeedbackMsg"));
			c3jQuery("#textContactHeading").text(getCustomText("textContactHeading"));
			c3jQuery("[id=textEULA]").html(getCustomText("textEULA"));
			c3jQuery("#ListNotFoundDesc1").text(getCustomText("textListNotFoundDesc1"));
			c3jQuery("#ListNotFoundDesc2").text(getCustomText("textListNotFoundDesc2"));

			//text configs for callback form / request
			c3jQuery("#CallbackRequestTitle").text(getCustomText("textCallbackTitle"));
			c3jQuery("#CallbackRequestWorkingHours").text(getCustomText("textCallbackWorkingHours"));

			c3jQuery("#callbackInputName").attr("placeholder", getCustomText("textCallbackInputName")+" *");
			c3jQuery("#callbackInputEmail").attr("placeholder", getCustomText("textCallbackInputEmail")+" *");
			c3jQuery("#callbackInputPhone").attr("placeholder", getCustomText("textCallbackInputPhone")+" *");
			c3jQuery("#callbackInputQuestion").attr("placeholder", getCustomText("textCallbackInputQuestion")+" *");

			c3jQuery('[id=CentionMsgFAQSend]').prop('disabled', true);
			c3jQuery('[id=CentionMsgToCall]').text(getCustomText("textMsgToCall"));

			c3jQuery("#CentionMsgFAQSend").val(getCustomText("textBtnSend"));
			c3jQuery("#CentionMsgFAQCancel").val(getCustomText("textBtnCancel"));

			/*******************/
			/* Contact Configs */
			/*******************/
			c3jQuery("#ContactNumTitle").text(getCustomText("textContactNumTitle"));
			c3jQuery("#ContactNumLine1").text(getCustomText("textContactNumLine1"));
			c3jQuery("#ContactHoursTitle").text(getCustomText("textContactHoursTitle"));
			c3jQuery("#ContactHoursLine1").text(getCustomText("textContactHoursLine1"));
			c3jQuery("#ContactHoursLine2").text(getCustomText("textContactHoursLine2"));
			c3jQuery("[id=ContactChatTitle]").text(getCustomText("textContactChatTitle"));
			c3jQuery("#FAQChatLauncher").attr("title", getCustomText("textFAQChatLauncher"));
			c3jQuery("[id=ContactChatLine1]").text(getCustomText("textContactChatLine1"));
			c3jQuery("[id=ContactChatLine2]").text(getCustomText("textContactChatLine2"));
			c3jQuery("#ContactEmailTitle").text(getCustomText("textContactEmailTitle"));
			c3jQuery("#ContactEmailLine1").text(Linker.linkifyUrls(getCustomText("textContactEmailLine1")));
			var mailLink = getCustomText("textContactEmailLine1")
			c3jQuery("#ContactEmailLine1").attr("href","mailto:"+mailLink);
			c3jQuery("#ContactEmailLine2").text(Linker.linkifyUrls(getCustomText("textContactEmailLine2")));
			c3jQuery("#ContactEmailLine3").text(Linker.linkifyUrls(getCustomText("textContactEmailLine3")));

			c3jQuery('#ViewPage-Contact a').attr('target', '_blank');

			/****************************************/
			/* Register Cention FAQ event handlers */
			/****************************************/

			var faqLoader = document.getElementById("CentionFAQLoader");
			var faqFeaturedListDiv = document.getElementById("CentionFAQFeaturedListDiv");
			if(faqLoader){
				faqLoader.style.display = "block";
				if(faqFeaturedListDiv){
					faqFeaturedListDiv.style.overflow = "hidden";
				}
			}
			function hidesFAQLoader(){
				if(faqLoader){
					faqLoader.style.display = "none";
					if(faqFeaturedListDiv){
						faqFeaturedListDiv.style.overflow = "auto";
					}
				}
			}
			CentionFAQ.registerAction("FAQ_TOPLIST", function(data) {
				c3jQuery("#CentionFAQBrowseKnowledgebase").html("");
				c3jQuery("#BrowseLibrary").show();
				hidesFAQLoader();
				if (topList) {
					c3jQuery.each(data.list, function(k, q) {
						c3jQuery("#CentionFAQFeaturedList").append("<li id='" + q.Id +"' class='"+classFAQList+"'>" + Linker.linkifyUrls(q.Q) + "</li>");	
					});
					c3jQuery('#CentionFAQFeaturedList a').attr('target', '_blank');
					
					if(getCustomSkin() !== SKIN_FRICTIONLESS){
						c3jQuery("."+classFAQList).css("background-color", getCustomConfig("questionListBgColor"));
					}
					c3jQuery("."+classFAQList).css("color", getCustomConfig("questionListTextColor"));
				}
				if (browseLibrary) {
					if(featuredFirstLibrary) {
						c3jQuery("#CentionFAQBrowseKnowledgebase").addClass("featured-first-lib");
					}
					c3jQuery.each(data.libs, function(k, l) {
						if(getCustomSkin() == SKIN_MODERN || getCustomSkin() == SKIN_FRICTIONLESS) {
							var libraryName = Linker.linkifyUrls(l.Name);
							const randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
							c3jQuery("#CentionFAQBrowseKnowledgebase")
							.append("<li id='" + l.Id +"' class='"+classFAQLibrary+"'>" +
							 `<div class="library-icon-wrap" style="background-color:${randomColor}">
							 <div class="alphabet-icon">${libraryName.charAt(0)}</div>
							 </div>`+
								`<div class="library-text">${libraryName}</div>` + "</li>");

								if (getCustomSkin() == SKIN_FRICTIONLESS) {
									//if library more than one
									//if only one, display it's child categories
									//if multiple, display all libraries
									//todo: review this logic
									if (data.libs.length == 1) {
										if (l.Cats && l.Cats.length > 0) {
											c3jQuery.each(l.Cats, function (k, c) {
												var cName = Linker.linkifyUrls(c.Name);
												const randomCatColor = "#" + Math.floor(Math.random()*16777215).toString(16);
												c3jQuery("#CentionFAQBrowseKnowledgebase")
												.append("<li id='" + c.Id +"' class='"+classFAQCategory+"'>" +
												`<div class="library-icon-wrap" style="background-color:${randomCatColor}">
												<div class="alphabet-icon">${cName.charAt(0)}</div>
												</div>`+
													`<div class="library-text">${cName}</div>` + "</li>");
													});
										}
										//render questions if any
										if (l.Qs && l.Qs.length > 0) {
											c3jQuery.each(l.Qs, function (k, q) {
												c3jQuery("#CentionFAQBrowseKnowledgebase").append("<li id='" + q.ID + "' class='" + classFAQList + "'>" + Linker.linkifyUrls(q.S) + "</li>");
											});
										}
									}
								}
						} else {
							//Default skin
							c3jQuery("#CentionFAQBrowseKnowledgebase")
								.append("<li id='" + l.Id + "' class='" + classFAQLibrary + "'>" + `<span>${Linker.linkifyUrls(l.Name)}</span>` + rightChevron + "</li>");
						}
					});
					c3jQuery('#CentionFAQBrowseKnowledgebase a').attr('target', '_blank');
					if(getCustomSkin() !== SKIN_FRICTIONLESS){
						c3jQuery("."+classFAQLibrary).css("background-color", getCustomConfig("libraryListBgColor"));
					}
					c3jQuery("."+classFAQLibrary).css("color", getCustomConfig("libraryListTextColor"));
				}
				if(Object.keys(data).length == 0){
					c3jQuery("#CentionFAQFeaturedList").html("");
					c3jQuery("#CentionFAQFeaturedList").append("<div>No results found</div>");
					hidesFAQLoader();
				}
			});

			CentionFAQ.registerAction("FAQ_SEARCH", function(data) {
				c3jQuery("#CentionFAQSearchedList").html("");
				c3jQuery.each(data, function(k, q){
					c3jQuery("#CentionFAQSearchedList").append("<li data-question-key='"+k+"' id='" + q.Id + "' class='"+classFAQList+"'>" + q.Q + "</li>");
					if(getCustomSkin() !== SKIN_FRICTIONLESS){
						c3jQuery("."+classFAQList).css("background-color", getCustomConfig("questionListBgColor"));
					}
					c3jQuery("."+classFAQList).css("color", getCustomConfig("questionListTextColor"));
				});
				if(Object.keys(data).length == 0){
					c3jQuery("#CentionFAQSearchedList").html("");
					c3jQuery("#CentionFAQSearchedList").append("<div class='searchResult'>No results found</div>");
				}
				c3jQuery('#CentionFAQSearchedList a').attr('target', '_blank');
			});

			CentionFAQ.registerAction("FAQ_VOTE_STATUS", function(status) {
				// client vote status true/false
				if (status === true) {
					c3jQuery(".FeedbackMessage").show();
					c3jQuery("#FeedbackButtons").hide();
				} else {
					c3jQuery(".FeedbackMessage").hide();
					c3jQuery("#FeedbackButtons").show();
				}
			});

			CentionFAQ.registerAction("connect", function(data) {
				if (data && data.status) {
					// websocket connected and ready
					FAQIsConnected = true;
					if(document.getElementById("FeaturedListNotFound")) {
						document.getElementById("FeaturedListNotFound").style.display = "none";
					}
				} else {
					FAQIsConnected = false;
					if(data.status === false) {
						//loads the no faq view
						if(document.getElementById("FeaturedListNotFound")) {
							document.getElementById("FeaturedListNotFound").style.display = "block";
						}
					}
				}
				if(faqFeaturedListDiv){
					faqFeaturedListDiv.style.overflow = "auto";
				}
			});

			if (CentionChatStatus && CentionChatStatus.resumable) {
				expandFAQWidget();
				c3jQuery("#ViewPage-Chat").show();
				c3jQuery("#iconReturnPrevious").hide();
				c3jQuery("#iconBackHeader").show();
				c3jQuery("#FAQBreadcrumbs").show();

				if(CentionChatStatus.error) {
					c3jQuery('#CentionStartView').hide();
					c3jQuery("#CentionChatView").hide();
					c3jQuery("#CentionNoAgentsView").hide();
					c3jQuery("#CentionErrorInfo").show();
					c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
				}else {
					if(hasChannel) {
						document.getElementById("CentionChannelView").style.display = "none";
					}
					c3jQuery("#CentionChatView").show();
					c3jQuery("#CentionNoAgentsView").hide();
					c3jQuery("#CentionErrorInfo").hide();
				}

				c3jQuery("#ViewPage-Home").hide();
				c3jQuery("#FAQStandardContainer").hide();
				c3jQuery("#FAQChatContainer").show();
				c3jQuery("#BackHome").show();
				
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					document.querySelector(".FAQFooterContainer").style.display = "none";
					document.getElementById("CentionFAQHeader").style.display = "none";
					document.getElementById("FAQStandardContainer").style.display = "none";
					document.getElementById("CentionChatBody").style.display = "block";
					document.getElementById("CentionChatWelcome").style.display = "none";

					document.getElementById("CentionFAQHeader").style.display = "block";
					document.getElementById("CentionFAQHeader").classList.add("header-shrink");
					document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "none";
					resetFrictionlessDefaultHeader();

					document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").classList.add("livechat");
					document.querySelector(".agentAvatarStatusWrapper").style.display = "block";

					document.querySelector(".FAQ-HeaderWrapper .contact-header-text").style.display = "none";
					document.querySelector("#FAQBreadcrumbs").style.display = "none";

					document.querySelector("#contactBackHeader").classList.remove("icon-knowledgebase");
					document.querySelector("#contactBackHeader").classList.add("icon-chevron-left");

					document.querySelector("#FAQHeaderPath").style.display = "none";
					document.querySelector(".FAQFooterContainer").style.display = "block";
				}
			}

			/******************************/
			/* Regster DOM event handlers */
			/******************************/

			//expand FAQ widget
			function expandFAQWidget() {
				//No need to connect again if already connected
				if(!FAQIsConnected) {
					CentionFAQ.connect({
						id: libraryId,				// list of library IDs
						minVotes: topListMinVotes,	// minimum votes required
						limit: topListLimit,		// toplist questions limit
						topList: topList,			// enable top list
						browseLibrary: browseLibrary // enable browse library
					});
				}
				c3jQuery("#CentionFAQContainer").show(FAQ_TOGGLE_DURATION);
				c3jQuery("#trigger-panel-open").hide();
				c3jQuery("#trigger-panel-close").show();

				if(c3jQuery("#ViewPage-Home").is(":visible")) {
					c3jQuery("#FAQSearchBar").hide();
				} else{
					c3jQuery("#FAQSearchBar").show();
				}
				if(getCustomSkin() == SKIN_MODERN){
					c3jQuery('#CentionFAQPanelTriggerContainer').toggle()
				}
				if(mobileFullScreenMode) {
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						document.getElementById("CentionFAQPanelTrigger").style.display = "none";
					}
				}
			}
			//minimize FAQ widget
			function minimizeFAQWidget() {
				c3jQuery("#CentionFAQContainer").hide(FAQ_TOGGLE_DURATION);
				c3jQuery("#trigger-panel-close").hide();
				c3jQuery("#trigger-panel-open").show();
				CentionFAQ.disconnect();
				if(mobileFullScreenMode) {
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						document.getElementById("CentionFAQPanelTrigger").style.display = "block";
					}
				}
			}

			//return to Home Screen for Frictionless skin
			function returnToHomeFrictionless() {
				document.getElementById("FAQ-HomeLogo").style.display = "block";
				document.getElementById("CentionFAQHeader").style.background = gradientHeaderBg;
				document.querySelector(".FAQ-HeaderWrapper").style.display = "block";
				document.getElementById("FAQBreadcrumbs").style.display = "none";
				document.getElementById("CentionFAQHeader").classList.remove("header-minimized");
				document.getElementById("CentionFAQHeader").classList.remove("header-shrink");
				document.getElementById("CentionFAQHome-search").style.display = "block";
				document.getElementById("CentionFAQHeader-search").style.display = "none";
				document.getElementById("ViewPage-BrowseTree").style.display = "none";
				document.getElementById("ViewPage-Home").style.display = "block";
				document.getElementById("ViewPage-Contact").style.display = "none";
				document.getElementById("CentionCallbackBody").style.display = "none";
				document.getElementById("CentionFAQBrowseKnowledgebase").style.display = "none";
				document.getElementById("CentionFAQFeaturedList").style.display = "block";
				document.getElementById("ViewPage-Answer").style.display = "none";
				document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath").style.display = "none";
				document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "block";
				document.getElementById("FAQChatContainer").style.display = "none";
				document.getElementById("FAQStandardContainer").style.display = "block";
				document.getElementById("FAQResizeWrapper").style.display = "none";
				document.querySelector(".agentAvatarStatusWrapper").style.display = "none";
				if(getCustomFAQUI("hasChat") && !config_hideAgentsAvatar) {
					document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "block";
					document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").classList.remove("livechat");
				}
				document.getElementById("CentionFAQSearched").style.display = "none";
				document.getElementById("CentionFAQFeatured").style.display = "block";
				document.getElementById("textCategoryHeading").style.display = "none";
				if(hasChannel) {
					document.getElementById("CentionChannelView").style.display = "none";
				}

				document.querySelector("#CentionFAQBody .breadcrumb").style.display = "none";
				collapseFAQQuestion();
				openQuestionFromBrowseTree = false;

				//if browse library enabled and top faq is false, then show list of library
				if(browseLibrary && !topList){
					document.getElementById("CentionFAQBrowseKnowledgebase").style.display = "block";
				}

				c3jQuery(".frictionless-breadcrumb li").remove();
			}

			//return to Home Screen
			function returnToHome() {
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					returnToHomeFrictionless();
				} else {
					c3jQuery("#FAQStandardContainer").show();
					c3jQuery("#FAQChatContainer").hide();
					c3jQuery("#ViewPage-Home").show();
					c3jQuery("#CentionFAQFeatured").show();
					c3jQuery("#CentionFAQBrowse").show(); //TOFIX: wrap with Faqfeatured at HTML
					c3jQuery("#ViewPage-Answer").hide();
					c3jQuery("#ViewPage-BrowseLibrary").hide();
					c3jQuery("#ViewPage-BrowseTree").hide();
					c3jQuery("#ViewPage-Contact").hide();
					c3jQuery("#ViewPage-Chat").hide();
					c3jQuery("#CentionChatBody").hide();
					c3jQuery("#CentionFAQSearched").hide();
					c3jQuery(".breadcrumb").empty();
					c3jQuery(".breadcrumb").append("<span></span>");
					c3jQuery("#FAQBreadcrumbs").hide();
					c3jQuery("#BackHome").hide();
					//modern
					c3jQuery("#FAQSearchBar").hide();
					c3jQuery("#CentionCallbackBody").hide();
				}				
			}

			//click on FAQ Trigger Panel
			c3jQuery("#trigger-panel-open").on('click', function(){
				expandFAQWidget();
				if(getCustomSkin() == SKIN_MODERN){
					if(c3jQuery('#ViewPage-Contact, #ViewPage-Answer, #ViewPage-Chat').is(":visible")){
						c3jQuery('#FAQSearchBar').hide()
					}
				}
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					if(getCustomFAQUI("hasChat") && !config_hideAgentsAvatar) {
						document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "block";
					}
				}
			});

			if(document.getElementById("iconExpandFAQ")) {
				document.getElementById("iconExpandFAQ").addEventListener("click", function() {
					expandFAQQuestion();
				});
			}

			if(document.getElementById("iconCollapseFAQ")) {
				document.getElementById("iconCollapseFAQ").addEventListener("click", function() {
					collapseFAQQuestion();
				});
			}

			function expandFAQQuestion() {
				document.getElementById("CentionFAQContainer").style.height = FAQ_CONTENT_MAX_HEIGHT;
				document.getElementById("CentionFAQContainer").style.width = FAQ_CONTENT_MAX_WIDTH;
				document.getElementById("iconCollapseFAQ").style.display = "block";
				document.getElementById("iconExpandFAQ").style.display = "none";
				document.getElementById("CentionFAQContainer").classList.add("expanded");
			}

			function collapseFAQQuestion() {
				if(mobileFullScreenMode) {
					document.getElementById("CentionFAQContainer").style.height = FAQ_CONTENT_FULL_HEIGHT;
					document.getElementById("CentionFAQContainer").style.width = FAQ_CONTENT_FULL_WIDTH;
				} else {
					document.getElementById("CentionFAQContainer").style.height = FAQ_CONTENT_DEFAULT_HEIGHT;
					document.getElementById("CentionFAQContainer").style.width = FAQ_CONTENT_DEFAULT_WIDTH;
				}
				document.getElementById("iconExpandFAQ").style.display = "block";
				document.getElementById("iconCollapseFAQ").style.display = "none";
				document.getElementById("CentionFAQContainer").classList.remove("expanded");
			}

			c3jQuery("#trigger-panel-close").on('click', function(){
				minimizeFAQWidget();
			});

			c3jQuery("#iconMinimize").on('click', function(){
				minimizeFAQWidget();
				c3jQuery('#CentionFAQPanelTriggerContainer').show();
			});

			c3jQuery("#contactBackHeader").on('click', function() {
				c3jQuery("#ViewPage-Home").show();
				c3jQuery("#FAQSearchBar").show();
				c3jQuery("#ViewPage-BrowseLibrary").hide();
				c3jQuery("#ViewPage-BrowseTree").hide();
				c3jQuery("#ViewPage-Answer").hide();
				c3jQuery("#ViewPage-Contact").hide();
				c3jQuery("#FAQChatContainer").hide();

				if(c3jQuery("#ViewPage-Chat").is(":visible")) {
					c3jQuery("#ViewPage-Chat").hide();
					c3jQuery("#FAQStandardContainer").show();
					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-Contact").show();
				}

				//browseLibrary
			
				//for feature, the background color and text color is toggled from the config
				//means for text, it uses the background color and vice versa
				c3jQuery("#iconFeatured").css({
					"color": getCustomConfig("tabIconBgColor")
				});

				c3jQuery("#iconBrowse").css({
					"color": getCustomConfig("tabIconTextColor")
				});

				c3jQuery("#iconContact").css({
					"color": getCustomConfig("tabIconTextColor")
				});

				if(getCustomSkin() !== SKIN_FRICTIONLESS){
					c3jQuery("#iconFeatured").css({
						"background-color": getCustomConfig("tabIconTextColor")
					});
	
					c3jQuery("#iconBrowse").css({
						"background-color": getCustomConfig("tabIconBgColor")
					});
	
					c3jQuery("#iconContact").css({
						"background-color": getCustomConfig("tabIconBgColor")
					});
				}

				//topList

				if(getCustomSkin() == SKIN_FRICTIONLESS){
					returnToHomeFrictionless();
				}
			});

			c3jQuery("#searchBackHeader").on('click', function() {
				c3jQuery("#ViewPage-Home").show();
				c3jQuery("#FAQSearchBar").hide();
				c3jQuery("#ViewPage-BrowseLibrary").hide();
				c3jQuery("#ViewPage-BrowseTree").hide();
				c3jQuery("#ViewPage-Answer").hide();
				c3jQuery("#ViewPage-Contact").hide();
				c3jQuery("#CentionFAQSearchedList").html('');
				c3jQuery("#CentionFAQSearched").hide();
				c3jQuery("#FAQ-search-close").hide();
				c3jQuery("#CentionFAQFeatured").show();
				c3jQuery("#CentionFAQBrowse").show(); //TOFIX: wrap with Faqfeatured at HTML

				if(c3jQuery("#ViewPage-Chat").is(":visible")) {
					c3jQuery("#ViewPage-Chat").hide();
					c3jQuery("#FAQStandardContainer").show();
					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-Contact").show();
				}
			});

			//Callback request action
			c3jQuery("#CallbackWrapper").on('click', function() {
				c3jQuery("#CentionCallbackBody").show();
				c3jQuery("#FAQStandardContainer").hide();
				c3jQuery("#FAQChatContainer").hide();

				if(getCustomFAQUI("askEULA")){
					c3jQuery("[id=EULAAcceptance]").show();
				}

				//modern skin
				c3jQuery("#BackHome").show();
				//default skin
				c3jQuery("#FAQBreadcrumbs").show();
			});

			// Check email validity
			function isValidEmail(emailAddress) {
				const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i;
				return re.test(emailAddress);
			}

			// Check phone number validity
			function isValidPhone(phone) {
				const validOnePhoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*/;
				return validOnePhoneRegex.test(phone);
			}

			let selectedDateTime, plainSelectedDateTime;
			function reFormatDateToUnix(dateStr) {
				const date = new Date(dateStr);
				return Math.floor(date.getTime() / 1000);
			}

			function checkWorkingHours(day, time, min) {
				const workingTime = CentionChatStatus.WorkingTimeRules;
				let isOpen = false, strTime = "", strMin = "";
				for (let d in workingTime) {
					const dayInfo = workingTime[d];
					const startHours = dayInfo.startHours+":"+dayInfo.startMinutes;
					const endHours = dayInfo.endHours+":"+dayInfo.endMinutes;
					if(day == d) {
						if(dayInfo.working) {
							if (time === 0) {
								strTime = "00";
							} else {
								if (time < 10) {
									strTime = "0"+time.toString();
								} else {
									strTime = time.toString();
								}
							}
							if (min === 0) {
								strMin = "00";
							} else {
								strMin = min.toString();
							}
							const selectedTimeStr = strTime+":"+strMin;
							if(selectedTimeStr >= startHours && selectedTimeStr <= endHours) {
								isOpen = true;
								c3jQuery(".date-error").hide();
								break;
							} else {
								console.log("outside working hours which is", startHours, " - " ,endHours);
								c3jQuery(".date-error").text(getCustomText("textDateError"));
								c3jQuery(".date-error").show();
								break;
							}
						} else {
							console.log("agent not working on ", day);
							c3jQuery(".date-error").text(getCustomText("textDateError"));
							c3jQuery(".date-error").show();
							break;
						}
					}
				}
				return isOpen;
			}

			let isValidDate = false;
			function onSelectDateTimeFAQ(datetime) {
				const selectedDay = datetime.getDay();
				let day = "monday";
				switch( selectedDay ) {
					case 1:
						day = "monday";
						break;
					case 2:
						day = "tuesday";
						break;
					case 3:
						day = "wednesday";
						break;
					case 4:
						day = "thursday";
						break;
					case 5:
						day = "friday";
						break;
					case 6:
						day = "saturday";
						break;
					case 0:
						day = "sunday";
						break;
				}
				const time = datetime.getHours();
				const min =  datetime.getMinutes();
				let isOpen = checkWorkingHours(day, time, min);
				if(!isOpen) {
					c3jQuery('[id=CentionMsgFAQSend]').prop('disabled', true);
					isValidDate = false;
				} else {
					const now = new Date();
					if(datetime > now) {
						isValidDate = true;
						c3jQuery('[id=CentionMsgFAQSend]').prop('disabled', false);
					} else {
						c3jQuery('[id=CentionMsgFAQSend]').prop('disabled', true);
						console.log("you can't select past time");
						isValidDate = false;
					}
				}
			}

			c3jQuery("#callbackButtonSubmit").on('click', function() {
				const formName = c3jQuery('#callbackInputName').val();
				const formEmail = c3jQuery('#callbackInputEmail').val();
				const formPhone = c3jQuery('#callbackInputPhone').val();
				const formQuestion = c3jQuery('#callbackInputQuestion').val();
				const emailIsValid = isValidEmail(formEmail);

				let  phoneIsValid = false;
				if(formPhone == "" || (formPhone != "" && isValidPhone(formPhone))) {
					phoneIsValid = true;
				}
				if (formName && formQuestion && emailIsValid && phoneIsValid) {

					//If EULA enabled force user to check before start
					if(getCustomFAQUI("askEULA")){
						if(c3jQuery("#askEULAInput").is(':checked')){
							c3jQuery("#EULAAcceptance").removeClass("warning-label");
							c3jQuery("#textEULA").removeClass("warning-label");
						}else{
							c3jQuery("#EULAAcceptance").addClass("warning-label");
							c3jQuery("[id=textEULA]").addClass("warning-label");
							c3jQuery(".warning-label").css("color", "#da5f62"); //we use to set this customized on chat
							return false;
						}
					}

					c3jQuery("#CentionCallbackContactForm").hide();
					c3jQuery("#CentionCallbackView").show();
					// c3jQuery("[id=agentAvatarList]").hide();

					const today = new Date();
					const zeroPad = (n, digits) => n.toString().padStart(digits, '0');
					function formatDate(date, format) {
						const map = {
							mm: zeroPad(date.getMonth() + 1, 2),
							dd: zeroPad(date.getDate(), 2),
							yyyy: date.getFullYear(),
							hh: zeroPad(date.getHours(), 2),
							MM: zeroPad(date.getMinutes(), 2),
						}
						return format.replace(/mm|dd|yyyy|hh|MM/gi, matched => map[matched])
					}
					const todayDate = formatDate(today, 'yyyy/mm/dd');
					c3jQuery('.CentionDateSelect').datetimepicker({
						ownerDocument: document,
						contentWindow: window,
						value: '',
						rtl: false,
						format: 'Y/m/d H:i',
						formatTime: 'H:i',
						formatDate: 'Y/m/d',
						defaultTime:false,
						defaultDate:todayDate,
						minDate:todayDate,
						maxDate:false,
						minTime: false,
						maxTime: false,
						minDateTime: false,
						maxDateTime: false,
						onSelectDate:function (date) {
							onSelectDateTimeFAQ(date);
							selectedDateTime = reFormatDateToUnix(date);
							plainSelectedDateTime = date;
							c3jQuery(".CentionDateSelect").val(formatDate(date, 'yyyy/mm/dd hh:MM'));
						},
						onSelectTime:function (time) {
							onSelectDateTimeFAQ(time);
							selectedDateTime = reFormatDateToUnix(time);
							plainSelectedDateTime = time;
							c3jQuery(".CentionDateSelect").val(formatDate(time, 'yyyy/mm/dd hh:MM'));
						},
						onChangeDateTime:function (datetime) {
							onSelectDateTimeFAQ(datetime);
							selectedDateTime = reFormatDateToUnix(datetime);
							plainSelectedDateTime = datetime;
						},
						todayButton: true,
						prevButton: true,
						nextButton: true,
						defaultSelect: true,
						scrollMonth: true,
						scrollTime: true,
						allowBlank: false
					});
				} else {
					if ((formName == "") || (formQuestion == "")) {
						if(formName == ""){
							c3jQuery("#callbackInputName").addClass("warning");
						}
						if(formPhone == ""){
							c3jQuery("#callbackInputPhone").addClass("warning");
						}
						if(formQuestion == ""){
							c3jQuery("#callbackInputQuestion").addClass("warning");
						}
					} else if (formEmail != "" && !emailIsValid){
						c3jQuery("#callbackInputEmail").addClass("warning");
						c3jQuery('#callbackInputEmail').val("");
					} else if (!phoneIsValid) {
						c3jQuery("#callbackInputPhone").addClass("warning");
						c3jQuery("#callbackInputPhone").val("");
					}
					return false;
				}
			});

			c3jQuery("#CentionMsgFAQSend").on('click', function() {
				if(!isValidDate) {
					alert("Can't select past time / outside working hours, please select different time.");
					return false;
				}
				const formName = c3jQuery('#callbackInputName').val();
				const formEmail = c3jQuery('#callbackInputEmail').val();
				const formPhone = c3jQuery('#callbackInputPhone').val();
				const formQuestion = c3jQuery('#callbackInputQuestion').val();
				const subj = formQuestion.substring(0, 30);
				const createErrandURL = baseURL + spacePrefix + "/socket/external.api/createerrand";
				c3jQuery.post( createErrandURL, {"area": areaId, "name": formName, "from": formEmail, "phone": formPhone, "subject": subj, "body": formQuestion, "callbackSchedule": selectedDateTime, channel: "VOICE"})
				.done(function( data ) {
					const dateTimeDOM = "<span class='message-scheduled-time'>"+plainSelectedDateTime+"</span>";
					const confirmTxt = getCustomText("textMsgSendSuccess").replace("{SCHEDULE_TIME}", dateTimeDOM);
					c3jQuery("[id=CentionCallbackList]").append("<div class='message'><div class='message-content'><div class='text'><span class='textMsg'>"+confirmTxt+"</span></div></div><div class='message-avatar'><i class='icon-user-circle'></i></div></div>");
				})
				.fail(function( err ) {
					console.log("Error", err);
				});
			});

			c3jQuery("#CentionMsgFAQCancel").on('click', function() {
				c3jQuery("#CentionCallbackView").hide();
				c3jQuery("#CentionCallbackContactForm").show();
				c3jQuery("#CentionCallbackList").hide();
				// c3jQuery("[id=agentAvatarList]").show();
			});

			//MODERN-Click on ReturnHome button
			c3jQuery("#iconReturnHome, #BackHome").on('click', function() {
				if(getCustomSkin() == SKIN_FRICTIONLESS) {
					returnToHomeFrictionless();
				}
				returnToHome();
			});

			c3jQuery("#ReturnToLibrary").on('click', function() {
				if(getCustomSkin() !== SKIN_FRICTIONLESS){
					c3jQuery("#ViewPage-BrowseTree").hide();
					c3jQuery("#FAQBreadcrumbs").hide();
					c3jQuery("#FAQBreadcrumbs li").remove();
					c3jQuery("#ViewPage-BrowseLibrary").show();
				} else {
					//should be back on the previous view
					//back on what being opened before, if it's category, back to library
					//if it's library, back to home
					//if it's question, back to category (or library)
				}
			});

			//Click on iconReturnPrevious
			c3jQuery("#iconReturnPrevious").on('click', function() {
				c3jQuery("#ViewPage-BrowseTree").show();
				c3jQuery("#ViewPage-Answer").hide();
				c3jQuery("#ViewPage-Contact").hide();
				c3jQuery("#iconReturnPrevious").hide()
			});


			//click on close (x) btn/icon on HEADER
			c3jQuery("#iconCloseWidget").click(function(){
				c3jQuery("#CentionFAQPanelTrigger").show();
				c3jQuery("#CentionFAQ").hide();
			})

			//click x (close) icon on search input
			c3jQuery("#FAQ-search-close").on('click',function(){
				c3jQuery("#FAQ-search-input").val("");
				c3jQuery("#CentionFAQSearchedList").html("");
				c3jQuery("#CentionFAQSearched").hide();
				c3jQuery("#CentionFAQFeatured").show();
				c3jQuery("#CentionFAQBrowse").show();
				c3jQuery("#FAQ-search-close").hide();
				if(getCustomSkin() == SKIN_MODERN){
					c3jQuery("#ViewPage-BrowseLibrary").show();
					c3jQuery("#CentionFAQCategory").show();
					c3jQuery("#ViewPage-Answer").hide();
				}
			});

			//click on feedback buttons - Upvote
			c3jQuery(".FeedbackUpvote").on("click", function(){
				// c3jQuery(".FeedbackMessage").toggle();
				var currentQuestionID = c3jQuery(this).attr('data-id');
				CentionFAQ.setVote(currentQuestionID);
			});
			//click on feedback buttons - Downvote on Answer page
			c3jQuery("#ViewPage-Answer").on("click", ".FeedbackDownvote", function(){
				// c3jQuery("#ViewPage-BrowseTree").hide();
				c3jQuery("#ViewPage-Answer").hide();
				if(!hasChat){
					c3jQuery("#ViewPage-Contact").show();
					c3jQuery("#iconBackHeader").show();

					if(getCustomSkin() == SKIN_FRICTIONLESS){
						document.getElementById("agentAvatarList").style.display = "none";
					}
				} else {
					c3jQuery("#FAQStandardContainer").hide();
					c3jQuery("#FAQChatContainer").show();
					c3jQuery("#ViewPage-Chat").show();
					c3jQuery("#CentionChatBody").show();
					c3jQuery("#iconReturnPrevious").hide();
					c3jQuery("#iconBackHeader").show();
					c3jQuery(".breadcrumb").empty();
					c3jQuery(".breadcrumb").append("<span></span>");
					if(CentionChatStatus && CentionChatStatus.error) {
						c3jQuery('#CentionStartView').hide();
						c3jQuery("#CentionChatView").hide();
						c3jQuery("#CentionNoAgentsView").hide();
						c3jQuery("#CentionErrorInfo").show();
						c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
					}else {
						if(hasChannel) {
							if(CentionChatStatus && CentionChatStatus.resumable) {
								document.getElementById("CentionChannelView").style.display = "none";
							} else {
								document.getElementById("CentionChannelView").style.display = "block";
							}
							c3jQuery('#CentionStartView').hide();
						} else{
							c3jQuery("#CentionChatView").show();
							c3jQuery("#CentionNoAgentsView").hide();
							c3jQuery("#CentionErrorInfo").hide();
						}
						if(getCustomSkin() == SKIN_FRICTIONLESS){
							document.querySelector(".FAQ-HeaderWrapper").style.display = "block";
							document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "none";
							document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").classList.add("livechat");
							document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "flex";
							document.querySelector(".agentAvatarStatusWrapper").style.display = "block";
							document.getElementById("FAQResizeWrapper").style.display = "none";
							document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath").style.display = "flex";
							document.querySelector(".FAQ-HeaderWrapper .contact-header-text").style.display = "none";
							loadFAQFrictionlessStartChat();
							
						}
					}
				}
			});

			//click on feedback button - Downvote on Tree page
			c3jQuery("#ViewPage-BrowseTree").on("click", ".FeedbackDownvote", function(){
				c3jQuery("#ViewPage-BrowseTree").hide();
				c3jQuery("#ViewPage-Answer").hide();
				if(!hasChat){
					c3jQuery("#ViewPage-Contact").show();
					c3jQuery("#iconReturnPrevious").show();
				}else {
					c3jQuery("#FAQStandardContainer").hide();
					c3jQuery("#FAQChatContainer").show();
					c3jQuery("#ViewPage-Chat").show();
					c3jQuery("#CentionChatBody").show();
					c3jQuery("#iconReturnPrevious").hide();
					c3jQuery("#iconBackHeader").show();
					c3jQuery(".breadcrumb").empty();
					c3jQuery(".breadcrumb").append("<span></span>");

					if(CentionChatStatus && CentionChatStatus.error) {
						c3jQuery('#CentionStartView').hide();
						c3jQuery("#CentionChatView").hide();
						c3jQuery("#CentionNoAgentsView").hide();
						c3jQuery("#CentionErrorInfo").show();
						c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
					}else {
						c3jQuery("#CentionChatView").show();
						c3jQuery("#CentionNoAgentsView").hide();
						c3jQuery("#CentionErrorInfo").hide();

						if(getCustomSkin() == SKIN_FRICTIONLESS){
							document.querySelector(".FAQ-HeaderWrapper").style.display = "block";
							document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "none";
							document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").classList.add("livechat");
							document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "flex";
							document.querySelector(".agentAvatarStatusWrapper").style.display = "block";
							document.getElementById("FAQResizeWrapper").style.display = "none";
							document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath").style.display = "flex";
							document.querySelector(".FAQ-HeaderWrapper .contact-header-text").style.display = "none";
							loadFAQFrictionlessStartChat();
						}
					}
				}
			});

			let currentQuestionID = "";
			let currentLibraryID = "";
			let currentCategoryID = "";

			function frictionlessSetDefaultHeader() {
				document.querySelector(".FAQ-HeaderWrapper").style.display = "block";
				document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "none";
				document.querySelector(".FAQ-HeaderWrapper #FAQ-HomeLogo").style.display = "none";
				document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath").style.display = "flex";
				document.getElementById("agentAvatarList").style.display = "none";
				document.getElementById("FAQResizeWrapper").style.display = "none";
			}

			c3jQuery("#ViewPage-Home").on('click','.FAQ-list-questions', function() {
				c3jQuery(".breadcrumb").empty();
				openQuestionFromBrowseTree = false;
			});

			c3jQuery("#ViewPage-Home, #ViewPage-BrowseLibrary").on('click','.FAQ-list-questions', function() {
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					frictionlessSetDefaultHeader();
					document.getElementById("CentionFAQHeader").classList.add("header-minimized");
					document.getElementById("CentionFAQHeader-search").style.display = "none";

					if(!mobileFullScreenMode) {
						document.getElementById("FAQResizeWrapper").style.display = "flex";
					}

					document.querySelector(".FAQ-HeaderWrapper .contact-header-text").style.display = "block";
					document.getElementById("FAQButton2").innerHTML = getCustomText("textFAQButton2");
					document.getElementById("FAQButton3").style.display = "none";

					document.querySelector("#FAQHeaderPathIcon > i").classList.remove("icon-call");
					document.querySelector("#FAQHeaderPathIcon > i").classList.add("icon-knowledgebase");

					//hides id agentAvatarList
					document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "none";

					resetFrictionlessDefaultHeader();
				}
				currentQuestionID = c3jQuery(this).attr('id');
				var data = CentionFAQ.getAnswer(currentQuestionID);
				if (data) {
					CentionFAQ.getVote(currentQuestionID);
					c3jQuery(".FeedbackUpvote").attr("data-id", currentQuestionID);
					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-BrowseLibrary").hide();
					c3jQuery("#ViewPage-BrowseTree").hide();
					c3jQuery("#iconBackHeader").show();
					c3jQuery("#ViewPage-Answer").show();
					c3jQuery("#currentSelectedQuestion").html(Linker.linkifyUrls(data.Q));
					c3jQuery("#currentSelectedAnswer").html(Linker.linkifyUrls(data.A));
					//MODERN skin, we hide
					if(getCustomSkin() == SKIN_MODERN){
						c3jQuery("#FAQBreadcrumbs").hide();
						c3jQuery("#BackHome").show();
						if(c3jQuery("#FAQSearchBar").is(":visible")) {
							c3jQuery('#CentionFAQAnswer').addClass("from-search")
						}else {
							c3jQuery('#CentionFAQAnswer').removeClass("from-search")
						}
					} else {
						c3jQuery("#FAQBreadcrumbs").show();
						if(getCustomSkin() == SKIN_FRICTIONLESS){
							document.querySelector(".frictionless-breadcrumb").style.display = "block";
						}
					}
					//Notes: we do not need to show active question on the breadcrumb path
					//c3jQuery(".breadcrumb").append("<li id='" + currentQuestionID +"' class='"+classFAQQuestionActive+"'>" + data.S +"</li>");
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						document.getElementById("CentionFAQHeader").classList.remove("header-minimized");
						document.getElementById("CentionFAQHeader").classList.add("header-shrink");
						resetFrictionlessDefaultHeader();
					}

					//show attachment files if there available
					var filesHtml = CentionFAQ.getFormattedFiles(currentQuestionID);
					c3jQuery("#currentSelectedAnswerFiles").html(filesHtml);

					c3jQuery('#currentSelectedQuestion a').attr('target', '_blank');
					c3jQuery('#currentSelectedAnswer a').attr('target', '_blank');
				}
			})

			//click on ViewPage-BrowseTree questions
			c3jQuery("#ViewPage-BrowseTree").on('click','.FAQ-list-questions', function() {
				openQuestionFromBrowseTree = true;
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					//check if library clicked from home
					if(clickLibraryFromHome) {
						document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "none";
						document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath").style.display = "flex";
						document.getElementById("FAQButton3").style.display = "none";
						document.querySelector("#FAQHeaderPathIcon > i").classList.remove("icon-call");
						document.querySelector("#FAQHeaderPathIcon > i").classList.add("icon-knowledgebase");
						document.getElementById("FAQ-HomeLogo").style.display = "none";
					}

					document.getElementById("CentionFAQHeader-search").style.display = "none";
					document.getElementById("CentionFAQHeader").classList.add("header-shrink");
					document.getElementById("CentionFAQHeader").classList.remove("header-minimized");
					if(!mobileFullScreenMode) {
						document.getElementById("FAQResizeWrapper").style.display = "flex";
					}
					resetFrictionlessDefaultHeader();
				}
				var currentQuestionID = c3jQuery(this).attr('id');
				var data = CentionFAQ.getAnswer(currentQuestionID);
				if (data) {
					CentionFAQ.getVote(currentQuestionID);
					c3jQuery("#FAQBreadcrumbs").show();
					//Notes: we do not need to show active question on the breadcrumb path
					//c3jQuery(".breadcrumb").append("<li id='" + currentQuestionID +"' class='"+classFAQQuestionActive+"'>" + rightChevron + data.S +"</li>");

					var textBrowseHTML = Linker.linkifyUrls(data.Q);
					c3jQuery("#textBrowse").html(textBrowseHTML);
					var CentionFAQBrowseListHTML = Linker.linkifyUrls(data.A);
					c3jQuery("#CentionFAQBrowseList").html(CentionFAQBrowseListHTML);

					var filesHtml = CentionFAQ.getFormattedFiles(currentQuestionID);
					var filesElem = c3jQuery("<p id='currentSelectedAnswerFiles'></p>").html(filesHtml);
					c3jQuery(filesElem).appendTo("#CentionFAQBrowseList");

					if(getCustomSkin() == SKIN_MODERN){
						c3jQuery("#BrowseListContainer").addClass("content");
						c3jQuery("#CentionFAQBrowseList").addClass("content");
						c3jQuery(".CentionFAQFeedback").clone(true).insertAfter("#CentionFAQBrowseList");
						document.querySelector("#FAQBreadcrumbs").style.display = "flex";
					} else {
						c3jQuery(".CentionFAQFeedback").clone(true).appendTo("#CentionFAQBrowseList");
					}
					c3jQuery("#ViewPage-BrowseTree .FeedbackUpvote").attr("data-id", currentQuestionID);

					if(getCustomSkin() == SKIN_FRICTIONLESS){
						document.querySelector(".frictionless-breadcrumb").style.display = "block";
					}
					c3jQuery("#FAQBreadcrumbs li#"+currentQuestionID+"."+classFAQQuestion).prev().removeClass("active-breadcrumb");

					c3jQuery('#textBrowse a').attr('target', '_blank');
					c3jQuery('#CentionFAQBrowseList a').attr('target', '_blank');
				}
			})

			c3jQuery("#FAQHeaderPathIcon").on('click', function(){
				if(openQuestionFromBrowseTree) {
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						openFAQAreaFrictionless();
					}
				} else {
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						returnToHomeFrictionless();
					}
				}
				openQuestionFromBrowseTree = false;
			});

			function showLibraryBrowseOnFrictionless() {
					currentLibraryID = c3jQuery(this).attr('id');
					var data = CentionFAQ.getQuestionByLibrary(currentLibraryID);
					if (data) {
						c3jQuery("#textBrowse").html(data.Name);
						c3jQuery(".breadcrumb").append("<li id='" + currentLibraryID +"' class='"+classFAQLibraryActive+"'>" + data.Name +"</li>");
						c3jQuery("#FAQBreadcrumbs li#"+currentLibraryID+"."+classFAQLibrary).prev().removeClass("active-breadcrumb");
						c3jQuery("#CentionFAQBrowseList").html("");
						appendLists(data);
					}
					document.getElementById("ViewPage-Contact").style.display = "none";
					document.getElementById("ViewPage-Home").style.display = "block";
					document.getElementById("ViewPage-BrowseTree").style.display = "none";
					document.getElementById("ViewPage-Answer").style.display = "none";
					document.getElementById("CentionFAQHeader").classList.remove("header-shrink");
					document.getElementById("FAQStandardContainer").style.display = "block";
					document.getElementById("FAQChatContainer").style.display = "none";
			}

			//click on Toplist Menu
			c3jQuery("#iconFeatured, #BackHome").on("click", function(){
				//make it toggleable when there is browseLibrary
				if(browseLibrary){
					c3jQuery("#iconFeatured").addClass("active-icon");
					c3jQuery("#iconBrowse").removeClass("active-icon");
					c3jQuery("#CentionFAQFeaturedList").show();
					c3jQuery("#CentionFAQBrowseKnowledgebase").hide();

					c3jQuery("#iconFeatured").css({
						"color": getCustomConfig("tabIconBgColor")
					});

					c3jQuery("#iconBrowse").css({
						"color": getCustomConfig("tabIconTextColor")
					});

					c3jQuery("#iconContact").css({
						"color": getCustomConfig("tabIconTextColor")
					});

					if(getCustomSkin() !== SKIN_FRICTIONLESS){
						c3jQuery("#iconFeatured").css({
							"background-color": getCustomConfig("tabIconTextColor")
						});

						c3jQuery("#iconBrowse").css({
							"background-color": getCustomConfig("tabIconBgColor")
						});

						c3jQuery("#iconContact").css({
							"background-color": getCustomConfig("tabIconBgColor")
						});
					}
				}
				if(getCustomSkin() == SKIN_FRICTIONLESS){
					returnToHomeFrictionless();
				}
			});

			//Click on Browse Tree Menu
			c3jQuery("#iconBrowse").on("click", function(){
				openFAQBrowseContainer();
			});

			function openFAQAreaFrictionless() {
				frictionlessSetDefaultHeader();
					if(currentLibraryID != "" && currentLibraryID != undefined){
						c3jQuery("#FAQBreadcrumbs li#"+currentLibraryID+"."+classFAQLibrary).nextAll().remove();
					} else {
						document.querySelector("#FAQBreadcrumbs").style.display = "none";
					}
					c3jQuery(".frictionless-breadcrumb li").remove();

					document.getElementById("CentionFAQHeader").classList.add("header-minimized");

					document.getElementById("CentionFAQHome-search").style.display = "none";
					document.getElementById("CentionFAQHeader-search").style.display = "block";

					document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath > #FAQButton3").textContent = "";
					document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath > #FAQButton2").textContent = getCustomText("textFAQButton2");
					document.getElementById("textCategoryHeading").style.display = "block";

					document.getElementById("FAQHeaderPathIcon").style.display = "block";
					document.querySelector("#FAQHeaderPathIcon > i").classList.remove("icon-call");
					document.querySelector("#FAQHeaderPathIcon > i").classList.add("icon-knowledgebase");



					resetFrictionlessDefaultHeader();
					showLibraryBrowseOnFrictionless();
			}

			//open FAQ Browse Container
			function openFAQBrowseContainer(){
				clickLibraryFromHome = false;
				c3jQuery("#iconBrowse").addClass("active-icon");
				c3jQuery("#iconFeatured").removeClass("active-icon");
				c3jQuery("#CentionFAQBrowseKnowledgebase").show();
				c3jQuery("#CentionFAQFeaturedList").hide();

				if(getCustomSkin() !== SKIN_FRICTIONLESS){
					c3jQuery("#iconBrowse").css({
						"color": getCustomConfig("tabIconBgColor")
					});
				}

				c3jQuery("#iconFeatured").css({
					"color": getCustomConfig("tabIconTextColor")
				});

				c3jQuery("#iconContact").css({
					"color": getCustomConfig("tabIconTextColor")
				});

				if(getCustomSkin() == SKIN_FRICTIONLESS){
					openFAQAreaFrictionless();
				} else {
					c3jQuery("#iconBrowse").css({
						"background-color": getCustomConfig("tabIconTextColor")
					});
	
					c3jQuery("#iconFeatured").css({
						"background-color": getCustomConfig("tabIconBgColor")
					});
	
					c3jQuery("#iconContact").css({
						"background-color": getCustomConfig("tabIconBgColor")
					});
				}
				if(hasChannel) {
					document.getElementById("CentionChannelView").style.display = "none";
				}
			}

			//MODERN-click browse more
			c3jQuery("#BrowseLibrary, .browseSearch").on("click", function(){
				c3jQuery("#CentionFAQBrowseKnowledgebase").show();
				c3jQuery("#ViewPage-Home").hide();
				c3jQuery("#ViewPage-BrowseLibrary").show();
				c3jQuery("#FAQSearchBar").show();
				c3jQuery("#BackHome").show();

				var searchInputVal = c3jQuery("#FAQ-search-input").val();
				if(searchInputVal.length > 0){
					c3jQuery("#CentionFAQSearched").show();
				}
			});

			//show active Browse Library alone if TOPLIST turned off
			if(!topList) {
				c3jQuery("#CentionFAQBrowseKnowledgebase").show();
				c3jQuery("#CentionFAQBrowse").addClass("featured-only");
			}

			//show Toplist alone if browseLIbrary turned off
			if(!browseLibrary){
				c3jQuery("#CentionFAQBrowse").remove();
				c3jQuery("#CentionFAQFeatured").addClass("featured-only");
			}

			// function append lists of libraries, categories or questions
			function appendLists(dataObj) {
				c3jQuery("#BrowseListContainer").removeClass("content");
				c3jQuery("#CentionFAQBrowseList").removeClass("content");
				c3jQuery("#ViewPage-BrowseTree .CentionFAQFeedback").remove();
				c3jQuery.each(dataObj.Libs, function(k, l) {
					c3jQuery("#CentionFAQBrowseList").append("<li id='" + l.Id +"' class='"+classFAQLibrary+"'>" + Linker.linkifyUrls(l.Name) + "</li>");
				});
				c3jQuery.each(dataObj.Cats, function(k, c) {
					c3jQuery("#CentionFAQBrowseList")
					.append("<li id='" + c.Id +"' class='"+classFAQCategory+"'>" + `<span>${Linker.linkifyUrls(c.Name)}</span>` + rightChevron + "</li>");
				});
				c3jQuery.each(dataObj.Qs, function(k, q) {
					c3jQuery("#CentionFAQBrowseList").
					append("<li id='" + q.Id +"' class='"+classFAQList+"'>" + Linker.linkifyUrls(q.Q) + rightChevron + "</li>");
				});

				c3jQuery('#CentionFAQBrowseList a').attr('target', '_blank');
				if(getCustomSkin() != SKIN_FRICTIONLESS){
					c3jQuery("#CentionFAQBrowseList > li").css("background-color", getCustomConfig("libraryListBgColor"));
				}
				c3jQuery("#CentionFAQBrowseList > li").css("color", getCustomConfig("libraryListTextColor"));
			};

			// click on library
			c3jQuery(".FAQ-list").on('click','.FAQ-list-libraries', function() {
				var currentLibraryID = c3jQuery(this).attr('id');
				var data = CentionFAQ.getQuestionByLibrary(currentLibraryID);
				
				if (data) {
					c3jQuery("#textBrowse").html(data.Name);
					c3jQuery(".breadcrumb").append("<li id='" + currentLibraryID +"' class='"+classFAQLibraryActive+"'>" + data.Name +"</li>");
					c3jQuery("#FAQBreadcrumbs li#"+currentLibraryID+"."+classFAQLibrary ).prev().removeClass("active-breadcrumb");
					c3jQuery("#CentionFAQBrowseList").html("");
					appendLists(data);
					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-BrowseLibrary").hide()
					c3jQuery("#ViewPage-BrowseTree").show();
					c3jQuery("#FAQBreadcrumbs").show();
					if(getCustomSkin() == SKIN_MODERN){
						document.querySelector("#FAQBreadcrumbs").style.display = "flex";
					}
				}

				if(getCustomSkin() == SKIN_FRICTIONLESS){
					if(document.getElementById("textCategoryHeading").style.display == "none"){
						clickLibraryFromHome = true;
					}
				}
			})

			// click on category
			c3jQuery(".FAQ-list").on('click','.FAQ-list-categories', function() {
				currentCategoryID = c3jQuery(this).attr('id');
				var data = CentionFAQ.getQuestionByCategry(currentCategoryID);
				if (data) {
					c3jQuery("#textBrowse").html(data.Name);
					c3jQuery("#faq-header-text").html(data.Name);
					c3jQuery(".breadcrumb").append("<li id='" + currentCategoryID +"' class='"+classFAQCategoryActive+"'>" + rightChevron + `<span>${data.Name}</span>` +"</li>");

					c3jQuery("#FAQBreadcrumbs li#"+currentCategoryID+"."+classFAQCategory).prev().removeClass("active-breadcrumb");

					c3jQuery("#CentionFAQBrowseList").html("");

					appendLists(data);

					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-BrowseLibrary").hide();
					c3jQuery("#ViewPage-BrowseTree").show();
					c3jQuery("#FAQBreadcrumbs").show();
				}
			})

			// click on breadcrumb category
			c3jQuery("#FAQBreadcrumbs").on('click','.FAQ-list-categories', function() {
				var currentCategoryID = c3jQuery(this).attr('id');
				var data = CentionFAQ.getQuestionByCategry(currentCategoryID);
				if (data) {
					c3jQuery("#textBrowse").html(data.Name);
					c3jQuery("#FAQBreadcrumbs li#"+currentCategoryID+"."+classFAQCategory).nextAll().remove();
					//add class active
					c3jQuery("#FAQBreadcrumbs li#"+currentCategoryID+"."+classFAQCategory).addClass("active-breadcrumb");
					c3jQuery("#CentionFAQBrowseList").html("");

					appendLists(data);

					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-BrowseLibrary").hide();
					// c3jQuery("#iconBackHeader").show();
					c3jQuery("#ViewPage-BrowseTree").show();
					c3jQuery("#FAQBreadcrumbs").show();
				}
			})

			// click on breadcrumb library
			c3jQuery("#FAQBreadcrumbs").on('click','.FAQ-list-libraries', function() {
				var currentLibraryID = c3jQuery(this).attr('id');
				var data = CentionFAQ.getQuestionByLibrary(currentLibraryID);

				if (data) {
					c3jQuery("#textBrowse").html(data.Name);
					c3jQuery("#FAQBreadcrumbs li#"+currentLibraryID+"."+classFAQLibrary).nextAll().remove();
					
					c3jQuery("#FAQBreadcrumbs li#"+currentLibraryID+"."+classFAQLibrary).addClass("active-breadcrumb");

					c3jQuery("#CentionFAQBrowseList").html("");

					appendLists(data);

					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-BrowseLibrary").hide();
					c3jQuery("#ViewPage-BrowseTree").show();
					c3jQuery("#CentionFAQBrowse").show();
					c3jQuery("#FAQBreadcrumbs").show();
					c3jQuery("#ViewPage-Answer").hide();
				}
			})

			//variables
			var classFAQList = "FAQ-list-questions";
			var classFAQLibrary = "FAQ-list-libraries";
			var classFAQLibraryActive = "FAQ-list-libraries active-breadcrumb";
			var classFAQCategory = "FAQ-list-categories";
			var classFAQCategoryActive = "FAQ-list-categories active-breadcrumb";
			var classFAQQuestion = "FAQ-list-questions";
			var classFAQQuestionActive = "FAQ-breadcrumb-questions active-breadcrumb";

			// Check if the parent element exists
			var faqHomeSearch = document.querySelector("#CentionFAQHome-search");
			if (faqHomeSearch) {
				// Add event listener to the parent element
				faqHomeSearch.addEventListener('click', function (event) {
					// Check if the clicked element has the specific ID
					if (event.target && event.target.id === 'FAQ-search-input') {
						// Ensure the input element exists before using it
						var faqSearchInput = document.querySelector("#FAQ-search-input");
						if (faqSearchInput) {
							openFAQBrowseContainer();
							faqSearchInput.focus();
						}
					}
				});
			}

			// on keyUp / searching in text field
			c3jQuery("#FAQ-search-input").on("keyup",function(){
				if(getCustomSkin() == SKIN_MODERN){
					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#ViewPage-BrowseTree").hide();
					c3jQuery("#ViewPage-Answer").hide();
					c3jQuery("#ViewPage-Contact").hide();
					c3jQuery("#FAQBreadcrumbs").hide();
					c3jQuery("#ViewPage-BrowseLibrary").show();
					c3jQuery("#CentionFAQSearched").show();
					c3jQuery("#CentionFAQCategory").hide();
				} else {
					c3jQuery("#ViewPage-Home").show();
				};
				c3jQuery("#FAQ-search-close").show();
				c3jQuery("#CentionFAQSearchedList").html("");
				c3jQuery("#CentionFAQFeatured").hide();
				c3jQuery("#CentionFAQBrowse").hide(); //TOFIX: wrap with Faqfeatured at html
				c3jQuery("#ViewPage-Contact").hide();
				c3jQuery("#CentionFAQSearched").show();
				var searchedInputVal = c3jQuery("#FAQ-search-input").val(); // TO FIX : add to global var

				//show typed keywords on the fly
				c3jQuery("#textSearchResult > span:nth-child(2)").text(' "'+ searchedInputVal +'"');

				if(searchedInputVal.length > 0){					// search opt: 0: either, 1: both, 2: question, 3: answer
					CentionFAQ.search(2, searchedInputVal)
				}else{
					c3jQuery("#CentionFAQSearchedList").html('');
					c3jQuery("#CentionFAQSearched").hide();
					c3jQuery("#FAQ-search-close").hide();
					c3jQuery("#CentionFAQFeatured").show();
					c3jQuery("#CentionFAQBrowse").show();
					if(getCustomSkin() == SKIN_MODERN){
						c3jQuery("#CentionFAQCategory").show();
					}
					if(c3jQuery("#ViewPage-Chat").is(":visible")) {
						c3jQuery("#ViewPage-Chat").hide();
						c3jQuery("#FAQStandardContainer").show();
						c3jQuery("#ViewPage-Home").hide();
						c3jQuery("#ViewPage-Contact").show();
					} //TOFIX: wrap with Faqfeatured at HTML
				};
			});

			function loadFAQFrictionlessStartChat(){
				if(typeof haveChatExtraInputField !== "undefined" && haveChatExtraInputField === true){
					if(document.getElementById("textInputExtraField")) {
						document.getElementById("textInputExtraField").placeholder = "";
						document.getElementById("textInputExtraFieldWrapper").style.display = "block";
						document.getElementById("textInputExtraFieldWrapper").querySelector("label").textContent = extraChatFieldLabel;
						if(extraChatFieldMandatory === true){
							document.getElementById("textInputExtraField").required = true;
							document.getElementById("textInputExtraFieldWrapper").querySelector("label").textContent = extraChatFieldLabel + " *";
						}
					}
				} else {
					document.getElementById("textInputExtraFieldWrapper").style.display = "none";
				}
				document.querySelector("#FAQBreadcrumbs").style.display = "none";
				document.querySelector("#FAQHeaderPath").style.display = "none";
				if(CentionChatStatus && CentionChatStatus.resumable) {
					c3jQuery("#ViewPage-Chat").show();
					c3jQuery("#iconReturnPrevious").hide();
					c3jQuery("#iconBackHeader").show();
					c3jQuery("#FAQBreadcrumbs").show();
				} else {
					document.getElementById("CentionChatView").style.display = "none";
					document.getElementById("CentionStartView").style.display = "block";
				}

				//back to normal size for chat
				collapseFAQQuestion();
			}

			//Chat Section
				c3jQuery("#chatWithUs").on("click", function(){
					c3jQuery("#CentionStartView").show();
					c3jQuery("#CentionChannelView").hide();
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						loadFAQFrictionlessStartChat();
					}
				});

				c3jQuery("#iconContact, #BrowseContact").on("click", function(){
					c3jQuery("#ViewPage-Home").hide();
					c3jQuery("#iconBackHeader").show();
					c3jQuery("#ViewPage-Contact").show();
					c3jQuery("#BackHome").show();
					if(hasChannel === true){
						c3jQuery('#CentionStartView').hide();
						if(CentionChatStatus && CentionChatStatus.resumable) {
							document.getElementById("CentionChannelView").style.display = "none";
						} else {
							document.getElementById("CentionChannelView").style.display = "block";
						}
					}

					if(typeof params.ui != "undefined"){
						if(!ui.showContactPage){
							c3jQuery("#FAQStandardContainer").hide();
							c3jQuery("#FAQChatContainer").show();
							c3jQuery("#ViewPage-Chat").show();
							c3jQuery("#CentionChatBody").show();
							c3jQuery("#iconReturnPrevious").hide();
							c3jQuery("#iconBackHeader").show();
							c3jQuery("#FAQBreadcrumbs").show();

							if(CentionChatStatus && CentionChatStatus.error) {
								c3jQuery('#CentionStartView').hide();
								c3jQuery("#CentionChatView").hide();
								c3jQuery("#CentionNoAgentsView").hide();
								c3jQuery("#CentionErrorInfo").show();
								c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
							}else {
								c3jQuery("#CentionChatView").show();
								c3jQuery("#CentionNoAgentsView").hide();
								c3jQuery("#CentionErrorInfo").hide();
							}
						} else{
								c3jQuery("#ViewPage-Home").hide();
								c3jQuery("#iconBackHeader").show();
								c3jQuery("#ViewPage-Contact").show();
						}
					} else{
						if(getCustomFAQUI("showContactPage")){
							c3jQuery("#ViewPage-Home").hide();
							c3jQuery("#iconBackHeader").show();
							c3jQuery("#ViewPage-Contact").show();
							if(document.querySelector(".FAQ-HeaderWrapper #agentAvatarList")) {
								document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "none";
							}
						}else {
							c3jQuery("#FAQStandardContainer").hide();
							c3jQuery("#FAQChatContainer").show();
							c3jQuery("#ViewPage-Chat").show();
							c3jQuery("#CentionChatBody").show();
							c3jQuery("#iconReturnPrevious").hide();
							c3jQuery("#iconBackHeader").show();
							c3jQuery("#FAQBreadcrumbs").show();

							if(CentionChatStatus && CentionChatStatus.error) {
								c3jQuery('#CentionStartView').hide();
								c3jQuery("#CentionChatView").hide();
								c3jQuery("#CentionNoAgentsView").hide();
								c3jQuery("#CentionErrorInfo").show();
								c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
							}else {
								c3jQuery("#CentionChatView").show();
								c3jQuery("#CentionNoAgentsView").hide();
								c3jQuery("#CentionErrorInfo").hide();

								if(getCustomSkin() == SKIN_FRICTIONLESS){
									document.querySelector(".FAQ-HeaderWrapper").style.display = "block";
									document.getElementById("CentionStartView").style.display = "block";
									loadFAQFrictionlessStartChat();

									document.getElementById("CentionChatWelcome").style.display = "none";
									document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "flex";
									document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").classList.add("livechat");
									
									if(document.querySelector(".FAQ-HeaderWrapper .agentAvatarStatusWrapper")) {
										document.querySelector(".FAQ-HeaderWrapper .agentAvatarStatusWrapper").style.display = "block";
									}

									document.querySelector("#FAQBreadcrumbs").style.display = "none";
									document.getElementById("FAQHeaderPathIcon").style.display = "block";
									document.querySelector("#FAQHeaderPathIcon > i").classList.add("icon-call");

									document.querySelector("#contactBackHeader").classList.remove("icon-knowledgebase");
									document.querySelector("#contactBackHeader").classList.add("icon-chevron-left");
								}
							}
						}
					}
					if(getCustomSkin() == SKIN_FRICTIONLESS){
						frictionlessSetDefaultHeader();
						document.getElementById("CentionFAQHeader").classList.add("header-shrink");
						document.getElementById("CentionFAQHeader").classList.remove("header-minimized");
						document.querySelector(".FAQ-HeaderWrapper .title-Row").style.display = "none";
						document.getElementById("FAQResizeWrapper").style.display = "none";
						document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath").style.display = "flex";
						document.querySelectorAll("#ViewPage-BrowseTree").forEach(function(e){
							e.style.display = "none";
						});
						document.getElementById("ViewPage-Answer").style.display = "none";

						document.getElementById("CentionFAQHeader-search").style.display = "none";

						document.querySelector("#contactBackHeader").classList.remove("icon-knowledgebase");
						document.querySelector("#contactBackHeader").classList.add("icon-chevron-left");

						document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath > #FAQButton2").textContent = "";
						document.querySelector(".FAQ-HeaderWrapper #FAQHeaderPath > #FAQButton3").textContent = getCustomText("textFAQButton3");
						document.querySelector("#FAQBreadcrumbs").style.display = "none";
						document.getElementById("CentionFAQHeader-search").style.display = "none";

						collapseFAQQuestion();
						resetFrictionlessDefaultHeader();
					}
					openQuestionFromBrowseTree = false;
					clickLibraryFromHome = false;
				});

			//Chat Section
			c3jQuery("#FAQChatLauncher").on("click", function(){
				c3jQuery("#FAQStandardContainer").hide();
				c3jQuery("#FAQChatContainer").show();
				c3jQuery("#ViewPage-Chat").show();
				c3jQuery("#CentionChatBody").show();
				c3jQuery("#iconReturnPrevious").hide();
				c3jQuery("#iconBackHeader").show();
				c3jQuery("#FAQBreadcrumbs").show();

				if(CentionChatStatus && CentionChatStatus.error) {
					c3jQuery('#CentionStartView').hide();
					c3jQuery("#CentionChatView").hide();
					c3jQuery("#CentionNoAgentsView").hide();
					c3jQuery("#CentionErrorInfo").show();
					c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
				}else {
					c3jQuery("#CentionChatView").show();
					c3jQuery("#CentionNoAgentsView").hide();
					c3jQuery("#CentionErrorInfo").hide();

					if(getCustomSkin() == SKIN_FRICTIONLESS){
						document.getElementById("CentionStartView").style.display = "block";

						loadFAQFrictionlessStartChat();
						document.getElementById("CentionChatWelcome").style.display = "none";
						document.querySelector(".FAQ-HeaderWrapper").style.display = "block";
						document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").style.display = "flex";
						document.querySelector(".FAQ-HeaderWrapper #agentAvatarList").classList.add("livechat");
						document.querySelector(".FAQ-HeaderWrapper .contact-header-text").style.display = "none";
						document.getElementById("FAQHeaderPathIcon").style.display = "none";
						if(document.querySelector(".FAQ-HeaderWrapper .agentAvatarStatusWrapper")) {
							document.querySelector(".FAQ-HeaderWrapper .agentAvatarStatusWrapper").style.display = "block";
						}

						if(CentionChatStatus && CentionChatStatus.resumable){
							if(hasChannel) {
								document.getElementById("CentionChannelView").style.display = "none";
							}
							document.getElementById("CentionStartView").style.display = "none";
							document.querySelector(".agentAvatarStatusWrapper").style.display = "block";
							document.querySelector("#FAQBreadcrumbs").style.display = "none";
							document.querySelector("#FAQHeaderPath").style.display = "none";
							document.querySelector(".FAQFooterContainer").style.display = "block";
						}
					}
				}

				if(getCustomSkin() == SKIN_FRICTIONLESS){
					document.querySelector("#contactBackHeader").classList.remove("icon-knowledgebase");
					document.querySelector("#contactBackHeader").classList.add("icon-chevron-left");
					document.querySelector("#FAQHeaderPath").style.display = "none";
				}
			});
		}
	});
};
