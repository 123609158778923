const WIDGET = 1;
const CLASSIC = 2;
let useFAQChatInChat, haveChatExtraInputField = false, extraChatFieldMandatory = false
, extraChatFieldLabel = "";
if(typeof FAQ_WITH_CHAT == "undefined" || FAQ_WITH_CHAT == false){
	useFAQChatInChat = false;
} else {
	useFAQChatInChat = true;
}

const SKIN_DEFAULT = 'default';
const SKIN_MODERN = 'modern';
const SKIN_FRICTIONLESS = 'frictionless';

const USE_STREAM_LOADER = true;
// Clicking thumbs/rating icon
const thumbsUpSolid = "icon-thumbs-up-fill";
const thumbsUpRegular = "icon-thumbs-up";
const thumbsDownSolid = "icon-thumbs-down-fill";
const thumbsDownRegular = "icon-thumbs-down";

//TODO: use this on all related width
const CHAT_WIDGET_WIDTH = "334px" ;
var CentionChatStatus = {};
var CentionChatActive = false;
var CentionChatUseDynamicArea = false;
//style script
function loadScripts(fileName, type, url) {
	if(type === "CSS") {
		var widgetStyle = document.createElement("link");
		widgetStyle.setAttribute("rel", "stylesheet");
		widgetStyle.setAttribute("type", "text/css");
		if (typeof url === 'undefined') {
			var chatWidgetCSS = CloudFrontURL+"/chat/css/"+fileName;
			widgetStyle.setAttribute("href", chatWidgetCSS);
		} else {
			//load style from the url
			widgetStyle.setAttribute("href", url);
		}
		document.head.appendChild(widgetStyle);
		if(useFAQChatInChat){
			document.head.removeChild(widgetStyle); //TO CONFIRM: we remove to use FAQ's css
		}
	} else if(type === "JS") {
		if(typeof url === 'undefined') {
			var script = document.createElement("script");
			script.src = CloudFrontURL+"/chat/js/"+fileName;
			document.head.appendChild(script);
		} else {
			//load the script from url
			var script = document.createElement("script");
			script.src = url;
			document.head.appendChild(script);
		}
	}
}
// Dynamically load Pikaday library
function loadPikadayScript(callback) {
    var script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/pikaday/pikaday.js";
    script.async = true;
    script.onload = callback;
    document.head.appendChild(script);
}


function loadVideoCSS(fileName) {
	var chatWidgetCSS = CloudFrontURL+"/chat/css/"+fileName;
	var widgetStyle = document.createElement("link");
	widgetStyle.setAttribute("rel", "stylesheet");
	widgetStyle.setAttribute("type", "text/css");
	widgetStyle.setAttribute("href", chatWidgetCSS);
	document.head.appendChild(widgetStyle);
}
function setupCentionBaseURLandSpace(baseURL) {
	var urlInfo = {};
	urlInfo.CentionBaseURL = baseURL;
	if ( baseURL.indexOf("cloud.cention.com") || baseURL.indexOf("cloud.cust.cention.se") || baseURL.indexOf("api.cention.com") || baseURL.indexOf("apid.cention.com") ) {
		const i = baseURL.indexOf("/s/");
		if ( i != -1 ) {
			urlInfo.spacePrefix = baseURL.substring(i);
			urlInfo.CentionBaseURL = baseURL.substring(0, i);
		} else {
			//ensure spacePrefix is defined but empty when not found
			urlInfo.spacePrefix = "";
		}
	}
	return urlInfo;
}

//setupCentionBaseURLandSpace(CentionBaseURL);
var uiHTML = 'widget.html';
var urlInfo = setupCentionBaseURLandSpace(CentionBaseURL);
CentionBaseURL = urlInfo.CentionBaseURL;
spacePrefix = urlInfo.spacePrefix;

loadVideoCSS("videoWidget.css")
loadScripts("chatWidget.css", "CSS");
loadScripts("cention-icon.css", "CSS");

function CentionWrapperSelector(selector) {
    var element = document.querySelector(selector);
    if (!element) {
        this.length = 0;
        return this;
    }
    this[0] = element;
    this.length = 1;
}

CentionWrapperSelector.prototype = {
    constructor: CentionWrapperSelector,
    each: function (callback) {
        for (var i = 0; i < this.length; i++) {
            callback.call(this[i], i, this[i]);
        }
        return this;
    },
};

function setTextContentById(elementId, customText) {
	const element = document.getElementById(elementId);
	if (element) {
		element.textContent = customText;
	}
}

function setInnerHTMLByElem(element, customText) {
	if (element) {
		element.innerHTML = customText;
	}
}

function setElementStyle(element, styleProperty, styleValue) {
    if (element) {
        element.style[styleProperty] = styleValue;
    }
}

var CentionChat = function(wrapper, params) {
        var container = new CentionWrapperSelector("#"+wrapper);
        const customChatBtn = params.customChatTriggerId;
		const checkStatusRealTime = true;
		const checkStatusInterval = 60000;
		var statusInterval = null;
		var url = null; 
		var areaId = params.faqWidgetCfg ? params.faqWidgetCfg.areaId[0].Id : params.areaId;
		var areas = params.faqWidgetCfg ? params.faqWidgetCfg.areaId : null;
		var Id= params.widgetId;
		var translation;
		var hasChannel = false;
		let isValidDate = false;
		let globalSelectedDate = "";

		if (Array.isArray(params.areaId)) {
			CentionChatUseDynamicArea = true;
			if (params.areaId.length > 0) {
				areaId = params.areaId[0].id; //auto selected for first time
			}
		}

		if(!spacePrefix){
			spacePrefix = "";
		}

		params.chatWidgetCfg = "";
		if(typeof params.faqWidgetCfg == "undefined"){
			params.faqWidgetCfg = "";
		}
		if(areaId != null && params.faqWidgetCfg == null) {
			url = CentionBaseURL+spacePrefix+"/cention/chat/chatWidgetConfig";
			fetchChatConfig(areaId);

		} else if(areaId == null && params.faqWidgetCfg == null) {
			url = CentionBaseURL+spacePrefix+"/cention/chat/chatWidgetConfigWidgetId";
			fetchChatConfigWidgetId(Id);
		} else if (params.faqWidgetCfg != null && params.faqWidgetCfg.areaId != null) {
			url = CentionBaseURL+spacePrefix+"/cention/chat/chatWidgetConfig";
			fetchChatConfig(params.faqWidgetCfg.areaId[0].Id);
		} else if(params.faqWidgetCfg != null && params.faqWidgetCfg.areaId == null) {
			url = CentionBaseURL+spacePrefix+"/cention/chat/chatWidgetConfigWidgetId";
			fetchChatConfigWidgetId(Id);
		}
		function fetchChatConfig(Id) {
			fetch(url + '?a=' + Id)
				.then(response => response.json())
				.then(data => {
					if (data.error) {
						CentionChatBase();
						return;
					}
					if (data.chatWidgetCfg) {
						params.chatWidgetCfg = data.chatWidgetCfg;
						CentionChatBase();
					}
				})
				.catch(error => {
					console.error('There has been a problem with your fetch operation:', error);
					CentionChatBase();
				});
		}
		function fetchChatConfigWidgetId(Id) {
			fetch(url + '?a=' + Id)
				.then(response => response.json())
				.then(data => {
					areas = data.chatWidgetCfg.areaId
					areaId = data.chatWidgetCfg.areaId[0].Id;
					if (data.chatWidgetCfg.areaId.length > 1) {
						CentionChatUseDynamicArea = true;
					}
					if (data.error) {
						CentionChatBase();
						return;
					}
					if (data.chatWidgetCfg) {
						params.chatWidgetCfg = data.chatWidgetCfg;
						CentionChatBase();
					}
				})
				.catch(error => {
					console.error('There has been a problem with your fetch operation:', error);
					CentionChatBase();
				});
		}
		var uiSkin = SKIN_DEFAULT;
		function CentionChatBase() {
			var uiStyle = "";
			if(typeof params.ui != "undefined"){
				if(typeof params.ui.style != "undefined"){
					uiStyle = params.ui.style;
				}else {
					uiStyle = WIDGET;
				}
			}else {
				uiStyle = WIDGET;
			}

			if(typeof params.chatWidgetCfg.skin != "undefined") {
				if(params.chatWidgetCfg.skin == SKIN_MODERN) {
					uiSkin = SKIN_MODERN;
					uiHTML = 'widgetModern.html';
				}
				if(params.chatWidgetCfg.skin == SKIN_FRICTIONLESS) {
					uiSkin = SKIN_FRICTIONLESS;
					uiHTML = 'widgetFrictionless.html';
				}
			}

			function initializePikaday() {
				const todayDate = formatDate(today, 'yyyy/mm/dd');
				const dateSelectElement = document.querySelector('.CentionDateSelect');
				let selectedDateTime, plainSelectedDateTime;
				new Pikaday({
					field: dateSelectElement,
					format: 'YYYY/MM/DD HH:mm',
					defaultDate: todayDate,
					setDefaultDate: true,
					onSelect: function(date) {
						onSelectDateTime(date);
						selectedDateTime = reFormatDateToUnix(date);
						plainSelectedDateTime = date;
						dateSelectElement.value = formatDate(date, 'yyyy/mm/dd hh:MM');
						globalSelectedDate = selectedDateTime;
					},
					onSelectTime: function(time) {
						onSelectDateTime(time);
						selectedDateTime = reFormatDateToUnix(time);
						globalSelectedDate = selectedDateTime;
						plainSelectedDateTime = time;
						dateSelectElement.value = formatDate(time, 'yyyy/mm/dd hh:MM');
					},
					onSelectDateTime: function(datetime) {
						onSelectDateTime(datetime);
						selectedDateTime = reFormatDateToUnix(datetime);
						plainSelectedDateTime = datetime;
						globalSelectedDate = selectedDateTime;
					},
					showTodayButton: true,
					showPreviousMonth: true,
					showNextMonth: true,
					defaultSelect: true,
					bound: false,
					toString(date, format) {
						// Output format function
						return formatDate(date, format);
					}
				});
			}

			const today = new Date();
			const zeroPad = (n, digits) => n.toString().padStart(digits, '0');
			function formatDate(date, format) {
				const map = {
					mm: zeroPad(date.getMonth() + 1, 2),
					dd: zeroPad(date.getDate(), 2),
					yyyy: date.getFullYear(),
					hh: zeroPad(date.getHours(), 2),
					MM: zeroPad(date.getMinutes(), 2),
				}
				return format.replace(/mm|dd|yyyy|hh|MM/gi, matched => map[matched])
			}

			function reFormatDateToUnix(dateStr) {
				const date = new Date(dateStr);
				return Math.floor(date.getTime() / 1000);
			}

			function checkWorkingHours(day, time, min) {
				let workingTime;
				let isOpen = false, strTime = "", strMin = "";
				if(CentionChatStatus) {
					workingTime = CentionChatStatus.WorkingTimeRules;
					if(workingTime) {
						for (let d in workingTime) {
							const dayInfo = workingTime[d];
							const startHours = dayInfo.startHours+":"+dayInfo.startMinutes;
							const endHours = dayInfo.endHours+":"+dayInfo.endMinutes;
							if(day == d) {
								if(dayInfo.working) {
									if (time === 0) {
										strTime = "00";
									} else {
										if (time < 10) {
											strTime = "0"+time.toString();
										} else {
											strTime = time.toString();
										}
									}
									if (min === 0) {
										strMin = "00";
									} else {
										strMin = min.toString();
									}
									const dateErrorElements = document.querySelectorAll(".date-error")[0];
									const selectedTimeStr = strTime+":"+strMin;
									if(selectedTimeStr >= startHours && selectedTimeStr <= endHours) {
										isOpen = true;
										if(dateErrorElements) {
											dateErrorElements.style.display = "none";
										}
										break;
									} else {
										console.log("outside working hours which is", startHours, " - " ,endHours);
										if(dateErrorElements) {
											dateErrorElements.textContent = getCustomText("textDateError");
											dateErrorElements.style.display = "block";
										}
										break;
									}
								} else {
									console.log("agent not working on ", day);
									if(dateErrorElements) {
										dateErrorElements.textContent = getCustomText("textDateError");
										dateErrorElements.style.display = "block";
									}
									break;
								}
							}
						}
						return isOpen;
					}
				}
			}

			function onSelectDateTime(datetime) {
				const selectedDay = datetime.getDay();
				let day = "monday";
				switch( selectedDay ) {
					case 1:
						day = "monday";
						break;
					case 2:
						day = "tuesday";
						break;
					case 3:
						day = "wednesday";
						break;
					case 4:
						day = "thursday";
						break;
					case 5:
						day = "friday";
						break;
					case 6:
						day = "saturday";
						break;
					case 0:
						day = "sunday";
						break;
				}
				const time = datetime.getHours();
				const min =  datetime.getMinutes();
				const isOpen = checkWorkingHours(day, time, min);
				if (!isOpen) {
					document.getElementById('CentionMsgSend').disabled = true;
					isValidDate = false;
				} else {
					const now = new Date();
					const selectedDateTime = new Date(datetime);
					if (selectedDateTime > now) {
						isValidDate = true;
						document.getElementById('CentionMsgSend').disabled = false;
					} else {
						document.getElementById('CentionMsgSend').disabled = true;
						console.log("You can't select a past time");
						isValidDate = false;
					}
				}
			}
			let selectedDateTime, plainSelectedDateTime;
			if(typeof params.chatWidgetCfg.ui != "undefined") {
				if(params.chatWidgetCfg.ui.enableCallbackRequest) {
					function initializePikaday() {
						const todayDate = formatDate(today, 'yyyy/mm/dd');
						const dateSelectElement = document.querySelector('.CentionDateSelect');
						new Pikaday({
							field: dateSelectElement,
							format: 'YYYY/MM/DD HH:mm',
							defaultDate: todayDate,
							setDefaultDate: true,
							onSelect: function(date) {
								onSelectDateTime(date);
								selectedDateTime = reFormatDateToUnix(date);
								globalSelectedDate = selectedDateTime;
								plainSelectedDateTime = date;
								dateSelectElement.value = formatDate(date, 'yyyy/mm/dd hh:MM');
							},
							onSelectTime: function(time) {
								onSelectDateTime(time);
								selectedDateTime = reFormatDateToUnix(time);
								plainSelectedDateTime = time;
								globalSelectedDate = selectedDateTime;
								dateSelectElement.value = formatDate(time, 'yyyy/mm/dd hh:MM');
							},
							onSelectDateTime: function(datetime) {
								onSelectDateTime(datetime);
								selectedDateTime = reFormatDateToUnix(datetime);
								globalSelectedDate = selectedDateTime;
								plainSelectedDateTime = datetime;
							},
							showTodayButton: true,
							showPreviousMonth: true,
							showNextMonth: true,
							defaultSelect: true,
							bound: false,
							toString(date, format) {
								// Output format function
								return formatDate(date, format);
							}
						});
					}
					loadPikadayScript(initializePikaday);
					loadScripts("pikaday.css", "CSS", "https://cdn.jsdelivr.net/npm/pikaday/css/pikaday.css");
				}
			}

			if (typeof params.translation !== 'object') {
				if(isJsonStringObj(params.faqWidgetCfg.translation)) {
					translation = JSON.parse(params.faqWidgetCfg.translation);
				}else if(isJsonStringObj(params.chatWidgetCfg.translation)) {
					translation = JSON.parse(params.chatWidgetCfg.translation);
				}else {
					translation = {};
				}
			}else{
				translation = params.translation;
			}

			if (!window.console) window.console = {};
			if (!window.console.log) window.console.log = function () { };
			if (!String.prototype.startsWith) {
				String.prototype.startsWith = function(searchString, position){
					position = position || 0;
					return this.substring(position, searchString.length) === searchString;
				};
			}

			if(uiStyle == WIDGET) {
				let widgetFile = "widget";
				if ( uiSkin === SKIN_MODERN ) {
					widgetFile = "widgetModern";
				}

				//check if spacePrefix text already included in the CentionBaseURL
				if (spacePrefix) {
					console.log("CentionBaseURL 1 -> ", CentionBaseURL);
					if (CentionBaseURL.includes(spacePrefix)) {
						CentionBaseURL = CentionBaseURL.replace(spacePrefix, "");
					}
					console.log("CentionBaseURL 2-> ", CentionBaseURL);
				}

				var standardChatWidgetHTML = CentionBaseURL+spacePrefix+"/cention/chat/"+widgetFile+"?a="+areaId
					+ (params.languageCode?"&lang="+params.languageCode:"");
				
				//fetching html from static.cention.com
				fetch(CloudFrontURL + "/chat/" + uiHTML)
				.then(function(response) {
					return response.text(); // Assuming the response is HTML content
				})
				.then(function(html) {
					if(container) {
						if(container[0]) {
							container[0].innerHTML = html;
						}
						loadWidget();
					}
				})
				.catch(function(error) {
					console.error('Error fetching HTML:', error);
				});

				function loadWidget() {
					const t0 = performance.now();
					var xhr = new XMLHttpRequest();
					xhr.onreadystatechange = function() {
						if (xhr.readyState === XMLHttpRequest.DONE) {
							if (xhr.status === 200) {
								const result = JSON.parse(xhr.responseText);
								const t1 = performance.now();
								console.log(`Widget loads took ${t1 - t0} milliseconds.`);
								var assignedText;
								if (params.hooks && params.hooks.onWidgetLoaded) {
									params.hooks.onWidgetLoaded();
								}
								CentionChatStatus = result.data.CentionChatStatus;
								container.innerHTML += CentionChatStatus;
								if (document.getElementById('CentionChatConnected')) {
									assignedText = document.getElementById('CentionChatConnected').innerHTML
										.replace("{AGENT_NAME}", '<span id="CentionChatConnected"></span>');
									document.getElementById('CentionChatConnected').innerHTML = assignedText;
								}
								if (CentionChatStatus && CentionChatStatus.error) {
									document.getElementById("CentionChat").style.display = "none";
									if (useFAQChatInChat) {
										document.getElementById("FAQChatContainer").style.display = "none";
										document.getElementById("ViewPage-Chat").style.display = "none";
									}
									console.error("Error: ", CentionChatStatus.error);
								} else {
									loadMiniChat(CentionBaseURL, areaId);
								}
								if (spacePrefix) {
									const spaceName = spacePrefix.substring(spacePrefix.lastIndexOf('/') + 1);
									if(document.getElementById("CentionChat")) {
										document.getElementById("CentionChat").classList.add(spaceName);
									}
								}
							} else {
								console.error("Error:", xhr.statusText);
							}
						}
					};
					xhr.open("GET", standardChatWidgetHTML);
					xhr.setRequestHeader("Content-Type", "application/json");
					xhr.withCredentials = true;
					xhr.send();
				}
			}
		}

		function isJsonStringObj(text){
			try{
				if(typeof JSON.parse(text) === "object"){
					return true;
				}else{
					return false;
				}
			} catch(error){
				return false;
			}
		}

		function loadMiniChat(baseURL, areaId) {
			const UA = window.navigator.userAgent;
			let parser, browserName, browserVersion, browserMajor, deviceVendor, deviceModel, deviceType, osName, osVersion;

			if (typeof UAParser !== "undefined") {
				parser = new UAParser(UA);
				const browserInfo = parser.getBrowser();
				const { name: parsedBrowserName, version: parsedBrowserVersion, major: parsedBrowserMajor } = browserInfo;

				const deviceInfo = parser.getDevice();
				const { vendor: parsedDeviceVendor, model: parsedDeviceModel, type: parsedDeviceType } = deviceInfo;

				const osInfo = parser.getOS();
				const { name: parsedOSName, version: parsedOSVersion } = osInfo;

				browserName = parsedBrowserName;
				browserVersion = parsedBrowserVersion;
				browserMajor = parsedBrowserMajor;
				deviceVendor = parsedDeviceVendor;
				deviceModel = parsedDeviceModel;
				deviceType = parsedDeviceType;
				osName = parsedOSName;
				osVersion = parsedOSVersion;
			}


			function isMobile(ua) {
				// detectmobilebrowsers.com JavaScript Mobile Detection Script
				const dataString = ua || navigator.vendor || window.opera;
				return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(dataString) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(dataString.substring(0, 4)));
			};

			const CentionChat = _CentionChat();
			CentionChat.baseURL = baseURL;
			CentionChat.areaId = areaId;
			if(!spacePrefix){
				spacePrefix = "";
			}
			CentionChat.I = function(text) {
				return translation[text]||text;
			};
			var cookieDisabled = false;
			const _preloaderURL = CloudFrontURL + "/chat/images/chat-loader.gif";
			var _chatIsMinimized = true;
			var useCustomChatTrigger = false;
			var _tempImageAttachments = [];
			const ccs = CentionChatStatus;
			// Use to indicates agent is assigned instead of data.agent field from 'chat message' event
			var agentResponded = false;
			var clientAvatar = "";
			var triggerSatisfaction = true;
			const defaultSkin = SKIN_DEFAULT;
			var skinFrictionless = params.chatWidgetCfg.skin === SKIN_FRICTIONLESS;
			var isChatWithChatBot = false;
			const defaultChatConfig = {
			// BEGIN_UI
				skin: SKIN_DEFAULT,
				buttonAwayEnable: false, //  buttonAwayEnable is whether to show the away button
				// buttonAwayLink is the link to
				// where it redirected to when clicked
				buttonAwayLink: "#",

				//  Position is where to position the chat widget. Possible values are:
				// 	bottomLeft
				// 	bottomRight
				// 	centerLeft
				// 	centerRight
				position: "bottomRight",
				zIndex: "",

				bottomPosition: 0, // bottomPosition is y position of the widget from the bottom, in pixels

				leftPosition: 0, // leftPosition is x position of the widget from the left side of the page, in pixels
				// Or:
				rightPosition: 0, // rightPosition is x position of the widget from the right side of the page, in pixels

				extraFieldEnabled: false, // extraFieldEnabled is extra input field for chat start form
				extraFieldMandatory: false, // extraFieldMandatory is to set if the extra field is mandatory
				extraFieldDisplayName: "", // extraFieldDisplayName i extra input field label name

				chatRatingsEnabled: false, // showChatRatings is to enable customer to rate chat


				proactiveEnable: false, // proactiveEnable is whether proactive chat offer should appear
				proactiveDelayTime: 30, // proactiveDelayTime is how long after page load proactive chat offer should be appear
				proactiveTimeAfterDecline: 45, // proactiveTimeAfterDecline is how long after declined offer next offer should appear
				proactiveRepeatedContact: 2, // proactiveRepeatedContact is how many times proactive chat should be appear for visitor
				proactiveTimeout: 20, // proactiveTimeout is how long until proactive chat offer disappears by itself
				proactiveImmediateStart: false, // proactiveImmediateStart is whether proactive chat should start without forcing visitor to enter name/email/etc
				proactivePopupType: 1, //1 : Portrait, 2: Landscape
				proactiveImageURL: '',

				askEULA: false, // askEULA is to ask user to agree to the End User License Agreement before start chatting
				cookieWarning: false, //enable to show CookieWarning
				// hideChatWhenOffline is for not showing the chat widget
				// if or chat is not open and no agent available
				hideChatWhenOffline: false,
				//Hide list of agent's avatars on the initial widget startup page
				hideAgentAvatarList: false,

				showMenuAsDefault: false, // showMenuAsDefault will decide whether to show chat Menu as default before toggle it

				// if areaId option is less than 5, and this is TRUE,
				// it will show all areas by default using radio buttons
				showAllAreaList: false,
				avoidPreselectArea: false,
				// Show contact form when chat is not available
				showContactFormWhenAway: false,
				// Video chat support
				enableVideoChat: false,
				enableCallbackRequest: false,
			// END_UI
			// BEGIN_CSS
				// logo is shown at the top left hand side of the widget
				// if it starts with '<' then it is assumed to be font-awesome icon
				// otherwise it is treated as an image and will be put in <img src="{logo}">
				logo: skinFrictionless ? "<i class='icon-chat'></i>" : "<i class='icon-chats'></i>",
				headerColor: "#eee",
				// headerBorderColor: "transparent",
				headerTextColor: "#000",
				// mainColor: "#eee", // mainColor is chat widget main body color
				mainColor: "#FFF", // mainColor is chat widget main body color
				// mainBorderColor: "transparent", // mainBorderColor is chat window border color
				fontColor: "#000", // fontColor is message text color
				warningTextColor: "#da5f62", // warningTextColor is a warning text color inside input/label default to use light red color
				buttonStartColor: "#0000001a", // buttonStartColor is "Start chat" button background color
				buttonStartBorderColor: "transparent", // buttonStartBorderColor "Start chat" button border color
				buttonStartTextColor: "#000000", // buttonStartTextColor is "Start chat" text color
				buttonAwayColor: "#FFFFFF", //  buttonAwayColor is away button background color
				buttonAwayTextColor: "#000000", // buttonAwayTextColor is away button text color
				buttonAwayBorderColor: "#00000033", // buttonAwayBorderColor is away button border color
				buttonAwayCustomClass: "CentionAwayButton",
				buttonRetryTextColor: "#000",
				chatConversationBgColor: "#FFF", //background color of chat conversation body
				chatConversationTextColor: "#000", //overall text color of chat conversation body
				buttonToggleMenuColor: "#FFF", // buttonToggleMenuColor is the background button color that use to hide/expand the chat menu
				//Tofix: rename messageBackground -> chatConversationBgColor
				messageBackgroundColor: "#def8f9", // is CUSTOMER conversation bubble background color
				messageBackgroundColorAgent: "#f2f2f2", //is AGENT conversation bubble background color
				chatConversationInfoTextColor: '#888', //text sender & time from conversationBubble
				messageAwayTextColor: "#000", // messageAwayTextColor is away message text color
				toggleMenuTextColor: "#000", // toggleMenuTextColor is the text color of the buttonToggleMenuColor
				messageSendIconColor: "#000", // messageSendIconColor is the color of the send icon which default to arrow ->
				menuTextColor: "#000",
				footerTextColor: "#000",
				footerLink: "https://cention.com",
				footerText: "Powered by <img src='"+CloudFrontURL+"/img/cention-logo-black_smallest.png' style='width: 40%' />",
				askEULATextColor: "#000",
				feedbackBgColor: "#7fcdffe0",
				feedbackTextColor: "#FFFFFF",
				cookieWarningBgColor: "#F5DEB3",
				cookieWarningTextColor: "#da5f62",
				cookieWarningSymbolColor: "#da5f62",
				//feedback ModalBox
				feedbackBoxBgColor: '#FFBF19',
				feedbackBoxTitleColor: "#FFF",
				feedbackBoxTextareaBgColor: "#00000033",
				feedbackBoxTextareaTextColor: "#FFF",
				feedbackBoxBtnBgColor: "#FFF",
				feedbackBoxBtnTextColor: "#555",

				// chatConversationTextareaBgColor: "#00000020",
				chatConversationTextareaBgColor: "#eaeaea",
				chatConversationTextareaColor: "#000",
				chatToggleMenuIconsColor: "#000", //color of icons on the footer
				chatToggleMenuIconsTextColor: "#000", //text below the icons

				tickKerningOffset: '-6px', // tickKerningOffset is kerning offset between the two ticks
				tickUnsentColor: '#D3D3D3',
				tickUnreadColor: '#D3D3D3',
				tickSentColor: '#008000',
				tickReadColor: '#008000',
				//proactive dialog color
				proactiveTitleTextColor: '#1f3c56',
				proactiveDescTextColor: '#1f3c56',
				proactiveDialogBgColor: '#FFF',


			// END_CSS

			// BEGIN_HOOKS
				onChatMaximize: function() {
					// This function is called when the chat is maximized
				},
				onChatMinimize: function() {
					// This function is called when the chat is minimized
				},
				onWidgetLoaded: function() {
					// This function is called when the chat widget is loaded
				},
				onChatStarted: function() {
					// This function is called when the chat is started
				},
				onChatStopped: function() {
					// This function is called when the chat is stopped
				},
			// END_HOOKS
			}

			const defaultChatText = {
			// BEGIN_TEXT
				// The following attributes names are also used as their respective html element id, so
				// you can inspect the html element on the text that you would like to change in order
				// to find out the corresponding entry to be customized here.
				textButtonStart: "", // textButtonStart is label for Start chat button
				textTitle: 'Chat', // textTitle is title for chat header
				textTitleProactive: 'Need a little help?', // textTitleProactive is title for proactive chat dialog
				// textButtonAway is the label for the link/button for
				// away text which only visible when
				// buttonAwayEnable is set to 'true' and
				// a link added to buttonAwayLink config.
				// This is displayed under textMessageAway text.
				textButtonAway: "Contact us",
				textButtonRetry: "Retry", // textButtonRetry is label for retry button
				textButtonProactiveStart: "Yes", // textButtonProactiveStart is label for Chat Now button in proactive chat dialog
				textButtonProactiveLater: "No", // textButtonProactiveLater is label for Maybe Later button in proactive chat dialog
				textAvailableNote: "We're available",
				textNoSelectArea: "Please select",
				textInputName: "Name", // textInputName is label for name input
				textInputEmail: "Email", // textInputEmail is label for email input
				textInputPhone: "Phone number", // textInputPhone is label for phone input
				textInputQuestion: "Question", // textInputQuestion is label for question input
				textScreenSharing: "You are sharing your screen", //textStatusConnecting is used in the screen preview when chat user share their screen
				textStatusConnecting: "Connecting...",
				textStatusQueue: "Please wait, an agent will assist you shortly ...",
				textStatusConnected: "You have been connected to",
				textMessageQueueing: "Please hold while we find an agent to assist you. Your current queue position is:",
				textMessageCurrentQueue: "Your current queue position is:",
				textMessageAgentBusy: "We are sorry, all our agents are busy at the moment.",
				textMessageAway: "We are sorry, either the chat is closed or we don't have any agents available at the moment.",
				textNameInvalid: "You must enter a valid name",
				textMessageEmailInvalid: "You must enter a valid email address",
				textQuestionInvalid: "You must enter a valid question",
				textMessageChatEnded: "Your chat has ended.",
				textMenuSendFile: "Attach", // textMenuSendFile is label for send file button
				textMenuNewChat: "New", // textMenuNewChat is label for new chat button
				textMenuFinishChat: "End", // textMenuFinishChat is label for finish chat button
				textMenuPrintChat: "Print", // textMenuPrintChat is label for print menu
				textMenuSaveChat: "Save", // textMenuSaveChat is label for save a copy button
				textMenuVideoChat: "Video",
				textTickSent: '✓', // textTickSent is indicator for when message is received by the chat server
				textTickRead: '✓', // textTickRead is indicator for when agent receives the message
				// textEULA is the text displayed next to EULA checkbox if enabled through 'askEULA' settings
				textEULA: 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.',
				textCookieWarning: "* Please allow cookie from {CENTION_BASE_URL} to make sure your chat continues if you browse to other pages during the chat.",
				//Satisfaction Feedback
				textSatisfactionMessage: "Enjoying the chat? Please rate it.",
				textSatisfactionReceived: "Thank you!",
				textFeedbackButton: "Leave a comment",
				textCommentBoxTitle: "Leave a comment (optional)",
				textFeedbackBtnSend: "Send",
				textFeedbackBtnCancel: "Cancel",
				//Contact Form
				textContactFormSentNotice: "Thank you <br>Your message has been successfully sent. <br>We will get back to you as soon as possible.",
				//Proactive
				textTitleProactive: "Hello, may I help you?",
				textMessageProactive: "",

				//Video call related
				textVideoCallHeader: "Video",

				//Callback request
				textMsgWhereToCall: "Where would you like me to call you?",
				textMsgCallNow: "Call me on the phone",
				textMsgCallSchedule: "Schedule a call",
				textMsgToCall: "When to call",
				textBtnSend: "Send request",
				textBtnCancel: "Cancel",
				textDateError: "Please select another date or time"

			// END_TEXT
			};

			//UI on Agent avatar
			function createAvatarUI(agents) {
				let counts = 0;
				let agentAvatarElements = document.querySelectorAll(".agentAvatar");
				let agentFaceURL;
				agentAvatarElements.forEach(function(agentAvatarElement) {
					agentAvatarElement.innerHTML = "";
					agents.forEach(function(q, k) {
						if (counts < 7) {
							const imgRand = Math.floor(Math.random() * 5) + 1;
							agentFaceURL = CloudFrontURL + "/img/avatar-" + imgRand + ".png";
							if (q.avatar) {
								agentFaceURL = baseURL + q.avatar;
							}
							let imgElement = document.createElement("img");
							imgElement.src = agentFaceURL;
							imgElement.title = q.chatName;
							imgElement.alt = "Avatar";
							imgElement.classList.add("avatar");
							agentAvatarElement.appendChild(imgElement);
							counts++;
						}
						//Why was below code needed in the first place?
						/* if (counts === 7) {
							let agentChatAvatarElement = document.createElement("img");
							agentChatAvatarElement.src = agentFaceURL;
							agentChatAvatarElement.title = q.chatName;
							agentChatAvatarElement.alt = "Avatar";
							agentChatAvatarElement.classList.add("avatar");
							agentAvatarElement.classList.add("agentChat");
							agentAvatarElement.appendChild(agentChatAvatarElement);
						} */
					});
				});
			}

			//GET ALL AGENTS (NON-ACTIVE INCLUDED) AND GENERATE PROFILE PIC
			function generateAgentsAvatar(fd) {
				var xhr = new XMLHttpRequest();
				var url = baseURL + spacePrefix +"/socket/external.api/agentlist?areas=" +CentionChat.areaId;
				xhr.open("GET", url, true);
				xhr.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var resp = JSON.parse(this.responseText);
						createAvatarUI(resp.agents);
					}
				};
				xhr.send(fd);
			}

			//GET AGENT LIST
			function getAgentList(areaId) {
				var xhr =[];
				var formData = new FormData();
				formData.append( 'area', areaId);
				formData.append( 'includeOffline', false);
				formData.append( 'groupByArea', false);
				xhr = new XMLHttpRequest();
				xhr.open("GET",baseURL + spacePrefix +"/socket/external.api/agentlist?areas=" +areaId+"&who=active");
				xhr.onreadystatechange = function(ev){
					if (ev.target.readyState == 4 && ev.target.status == 200) {
						var ro = JSON.parse(ev.target.responseText);
						if (ro){
							if (ro.agents.length > 0) {
								createAvatarUI(ro.agents);
								if (!getCustomUI("hideAgentAvatarList")) {
									document.querySelectorAll(".agentStatus > .logged-in").forEach(function(element) {
										element.style.display = "block";
									});
								}
							} else {
								document.querySelectorAll(".agentStatus > .logged-in").forEach(function(element) {
									element.style.display = "none";
								});
								var agentAvatarElements = document.querySelectorAll(".agentAvatar");
								agentAvatarElements.forEach(function(agentAvatarElement) {
									agentAvatarElement.innerHTML = "";
								});
								generateAgentsAvatar(formData);
							}
						}
					}
				};
				xhr.send(formData);
			}
			//FAQ SKIN CONFIG
			function getCustomSkin() {
				var value = "";
				if(typeof params.skin != "undefined"){
					//check if objects value legit
					if(params.skin) {
						value = params.skin;
					}else {
						value = defaultSkin;
					}
				}else if(typeof params.faqWidgetCfg.skin != "undefined"){
					if(params.faqWidgetCfg.skin){
						value = params.faqWidgetCfg.skin;
					}else {
						value = defaultSkin;
					}
				}else if(typeof params.chatWidgetCfg.skin != "undefined"){
					if(params.chatWidgetCfg.skin){
						value = params.chatWidgetCfg.skin;
					}else {
						value = defaultSkin;
					}
				}else{
					value = defaultSkin;
				}
				return value;
			}
			function getCustomConfig(name) {
				var value = "";
				if(typeof params.css != "undefined"){
					if(typeof name != "undefined" && name == "logo") {
						if(params.css[name]) {
							if(params.css[name][0] == "<"){
								value = params.css[name];
							}else{
								value = "<img src="+params.css[name]+">";
							}
						}else{
							value = defaultChatConfig[name];
						}
					} else{
						if(params.css[name]) {
							value = params.css[name];
						}else {
							value = defaultChatConfig[name];
						}
					}
				}else if(typeof params.faqWidgetCfg.css != "undefined"){
					if(typeof name != "undefined" && name == "logo") {
						if(params.faqWidgetCfg.customize && params.faqWidgetCfg.css[name]) {
							if(params.faqWidgetCfg.css[name][0] == "<"){
								value = params.faqWidgetCfg.css[name];
							}else{
								value = "<img src="+params.faqWidgetCfg.css[name]+">";
							}
						}else{
							value = defaultChatConfig[name];
						}
					} else{
						if(params.faqWidgetCfg.customize && params.faqWidgetCfg.css[name]) {
							value = params.faqWidgetCfg.css[name];
						}else {
							//check chat config first, then uses the defaultChatConfig[name] if not found
							if(defaultChatConfig[name]){
								if(params.chatWidgetCfg.customize && params.chatWidgetCfg.css[name]) {
									value = params.chatWidgetCfg.css[name];
								}
							} else {
								value = defaultChatConfig[name];
							}
						}
					}
				}else if(typeof params.chatWidgetCfg.css != "undefined"){
					if(typeof name != "undefined" && name == "logo") {
						if(params.chatWidgetCfg.customize && params.chatWidgetCfg.css[name]) {
							if(params.chatWidgetCfg.css[name][0] == "<"){
								value = params.chatWidgetCfg.css[name];
							}else{
								value = "<img src="+params.chatWidgetCfg.css[name]+">";
							}
						}else{
							value = defaultChatConfig[name];
						}
					} else{
						if(params.chatWidgetCfg.customize && params.chatWidgetCfg.css[name]) {
							value = params.chatWidgetCfg.css[name];
						}else {
							value = defaultChatConfig[name];
						}
					}
				}else{
					value = defaultChatConfig[name];
				}
				return value;
			}
			function getCustomUI(name) {
				var value = "";
				if(typeof params.ui != "undefined"){
					if(params.ui[name] ||( params.ui[name] == false)){
						value = params.ui[name];
					}else {
						value = defaultChatConfig[name];
					}
				}else if(typeof params.faqWidgetCfg.ui != "undefined"){
					if(params.faqWidgetCfg.customize && (params.faqWidgetCfg.ui[name] ||( params.faqWidgetCfg.ui[name] == false))){
						value = params.faqWidgetCfg.ui[name];
					}else {
						//if said config not exist in FAQ, check if config exist in chat
						if(typeof params.chatWidgetCfg.ui != "undefined"){
							if(params.chatWidgetCfg.customize && (params.chatWidgetCfg.ui[name] ||( params.chatWidgetCfg.ui[name] == false))){
								value = params.chatWidgetCfg.ui[name];
							}
						} else {
							value = defaultChatConfig[name];
						}
					}
				}else if(typeof params.chatWidgetCfg.ui != "undefined"){
					if(params.chatWidgetCfg.customize && (params.chatWidgetCfg.ui[name] ||( params.chatWidgetCfg.ui[name] == false))){
						value = params.chatWidgetCfg.ui[name];
					}else {
						value = defaultChatConfig[name];
					}
				}else {
					value = defaultChatConfig[name];
				}
				return value;
			}
			function getCustomText(name) {
				var value = "";
				if(typeof params.text != "undefined"){
					if(params.text[name]) {
						value = params.text[name];
					}else {
						value = defaultChatText[name];
					}
				}else if(typeof params.faqWidgetCfg.text != "undefined" && typeof params.faqWidgetCfg.text[name] != "undefined"){
					if(params.faqWidgetCfg.customize && params.faqWidgetCfg.text[name]) {
						value = params.faqWidgetCfg.text[name];
					}else {
						value = defaultChatText[name];
					}
				}else if(typeof params.chatWidgetCfg.text != "undefined"){
					if(params.chatWidgetCfg.customize && params.chatWidgetCfg.text[name]) {
						value = params.chatWidgetCfg.text[name];
					}else {
						value = defaultChatText[name];
					}
				}else{
					value = defaultChatText[name];
				}
				return value;
			}

			var hasChatEnableCallBack = false;
			if(typeof params.chatWidgetCfg.ui != "undefined"){
				if(ccs.feature['sip.enabled'] && params.chatWidgetCfg.customize){
					hasChatEnableCallBack = params.chatWidgetCfg.ui.enableCallbackRequest;
				}
			}

			if(!cookieDisabled) {
				if(!hasChatEnableCallBack) {
					if(CentionChatStatus && !CentionChatStatus.resumable) {
						localStorage.setItem("chatName", "");
						localStorage.setItem("chatEmail", "");
						localStorage.setItem("chatPhone", "");
						localStorage.setItem("chatSubject", "");
					}
				}
			}

			var previewTimeout = null;
			var previewCount = 0;

			//Hide chat entirely when this setting is `true`
			if(getCustomUI("hideChatWhenOffline")){
				//agentsAvailable equal to 0 also means chat queue is disabled
				if (!ccs.chatOpen || ccs.agentsAvailable === 0) {
					if(document.getElementById("cention-chat-container")) {
						document.getElementById("cention-chat-container").style.display = "none";
						return;
					}
				}
			}

			const callbackEnabled = hasChatEnableCallBack;
			if (callbackEnabled) {
				if(document.getElementById("CentionPhoneField")) {
					document.getElementById("CentionPhoneField").style.display = "block";
				}
				document.getElementById("textInputPhone").style.display = "block";
				document.getElementById("CentionChatAudioCallWrapper").style.display = "block";
			}
			//Show channel when there's channel added to the config
			// Check if the element with id "CentionChatChannelView" is not visible
			var centionChatChannelView = document.querySelector('#CentionChatChannelView');
			if (centionChatChannelView && window.getComputedStyle(centionChatChannelView).display === 'none') {
				// Define constants
				const messengerChat = "messengerChat";
				const telegramChat = "telegramChat";
				const whatsAppChat = "whatsAppChat";
				const chatChat = "chatChat";

				if ((getCustomText(messengerChat) && getCustomText(messengerChat).trim() !== "") ||
					(getCustomText(telegramChat) && getCustomText(telegramChat).trim() !== "") ||
					(getCustomText(whatsAppChat) && getCustomText(whatsAppChat).trim() !== "")
				) {
					document.querySelector("#CentionStartView").style.display = 'none';
					centionChatChannelView.style.display = 'block';

					var ChannelURL = "";
					const ChannelClass = "channelStyle";
					const ChannelListClass = "channelListStlye";
					const ChannelLabelClass = "channelLabelStyle";

					if (getCustomText(messengerChat) && getCustomText(messengerChat).trim() !== "") {
						ChannelURL = getCustomText("messengerChat").trim();
						const ChannelStyleClass = ChannelClass + " " + messengerChat;
						const ChannelListStyleClass = ChannelListClass + " " + "icon-v5-messenger";
						document.querySelector(".channelList").innerHTML += "<a href=\"" + ChannelURL + "\" class=\"" + ChannelStyleClass + "\" target='_blank'><div class=\"" + ChannelListStyleClass + "\"></div><p class=\"" + ChannelLabelClass + "\">Messenger</p></a>";
					}
					if (getCustomText(telegramChat) && getCustomText(telegramChat).trim() !== "") {
						ChannelURL = getCustomText("telegramChat").trim();
						const ChannelStyleClass = ChannelClass + " " + telegramChat;
						const ChannelListStyleClass = ChannelListClass + " " + "icon-telegram";
						document.querySelector(".channelList").innerHTML += "<a href=\"" + ChannelURL + "\" class=\"" + ChannelStyleClass + "\" target='_blank'><div class=\"" + ChannelListStyleClass + "\"></div><p class=\"" + ChannelLabelClass + "\">Telegram</p></a>";
					}
					if (getCustomText(whatsAppChat) && getCustomText(whatsAppChat).trim() !== "") {
						ChannelURL = getCustomText("whatsAppChat").trim();
						const ChannelStyleClass = ChannelClass + " " + whatsAppChat;
						const ChannelListStyleClass = ChannelListClass + " " + "icon-v5-whatsapp";
						document.querySelector(".channelList").innerHTML += "<a href=\"" + ChannelURL + "\" class=\"" + ChannelStyleClass + "\" target='_blank'><div class=\"" + ChannelListStyleClass + "\"></div><p class=\"" + ChannelLabelClass + "\">WhatsApp</p></a>";
					}
					const ChannelStyleClass = ChannelClass + " " + chatChat;
					const ChannelListStyleClass = ChannelListClass + " " + "icon-chat";
					document.querySelector(".channelList").innerHTML += "<div id='chatWithUs' class=\"" + ChannelStyleClass + "\"><div class=\"" + ChannelListStyleClass + "\"></div><p class=\"" + ChannelLabelClass + "\">Chat With Us</p></div>";
				}
			}


			if((getCustomText("messengerFAQ") && getCustomText("messengerFAQ").trim() !== "") ||
			   (getCustomText("telegramFAQ") && getCustomText("telegramFAQ").trim() !== "") ||
			   (getCustomText("whatsAppFAQ") && getCustomText("whatsAppFAQ").trim() !== "")
			){
				hasChannel = true;
			}

			/**************************************************************/
			/* Apply Basic Custom Style If Provided By Third Party Client */
			/**************************************************************/


			//For Chat Start View
			//Header
			const getPosition  = getCustomUI("position");
			const bottomPosition = getCustomUI("bottomPosition");
			const leftPosition = getCustomUI("leftPosition");
			const rightPosition = getCustomUI("rightPosition");

			getAgentList(CentionChat.areaId);

			//TODO: refactor & impove codes for widgetLocation & position (setUpWidgetPosition)!

			//Render fullscreen on mobile devices
			const chat_config_full_screen_mobile = getCustomUI("chatFullScreenOnSmallScreen");
			const skinFrame = document.querySelector(".skin__frame");
			const isChatMobileDeviceSize = window.innerWidth <= 768;
			const autoExpandOnMobile = false;

			if(chat_config_full_screen_mobile && isChatMobileDeviceSize) {
				document.querySelector(".skin__frame").classList.add("cention-full-screen-chat");
				if(autoExpandOnMobile) {
					toggleChatWindow();
				}
			}
			if(chat_config_full_screen_mobile && skinFrame && skinFrame.classList.contains("cention-full-screen-chat")) {
				document.getElementById("iconExpand").style.display = "none";
			}

			//TODO: This function below seems no longer used anymore, need to be removed
			function widgetLocation(){
				var left, right;
				var widgetLocation = [];

				if(getPosition == 'bottomLeft'){
					if(leftPosition && rightPosition){ // both left and right exist
						left  = leftPosition + "px";
						right = "inherit";
					}else if(leftPosition && rightPosition == undefined){ // if only leftPosition available
						left  = leftPosition + "px";
						right = "";
					}else if(leftPosition == undefined && rightPosition){ // if only rightPosition available
						left  = "inherit";
						right = rightPosition + "px";
					}
				}else if(getPosition == 'bottomRight'){
					if(leftPosition && rightPosition){ // both left and right available
						left  = "inherit";
						right = rightPosition + "px";
					}else if(leftPosition && rightPosition == undefined){ // if only leftPosition available
						left  = leftPosition + "px";
						right = "inherit";
					}else if(leftPosition == undefined && rightPosition){ // if only rightPosition available
						left  = "";
						right = rightPosition + "px";
					}
				}
				widgetLocation.push(left, right);
				return widgetLocation;
			}

			function setUpWidgetPosition() {
				const proactiveContainer = document.querySelector(".proactive__container");
				if(getCustomUI("position") == "bottomRight"){
					if(document.querySelector(".skin__frame")) {
						document.querySelector(".skin__frame").classList.add("bottomRight");
					}
					if(proactiveContainer){
						proactiveContainer.classList.add("bottomRight");
					}
				}
				if(getCustomUI("position") == "bottomLeft"){
					if(document.querySelector(".skin__frame")) {
						document.querySelector(".skin__frame").classList.add("bottomLeft");
					}
					if(proactiveContainer){
						proactiveContainer.classList.add("bottomLeft");
					}
				}
				if(getCustomUI("position") == "centerRight"){
					if(document.querySelector(".skin__frame")) {
						document.querySelector(".skin__frame").classList.add("centerRight");
					}
					if(proactiveContainer){
						proactiveContainer.classList.add("centerRight");
					}
					document.querySelector("#CentionChatHeader").style.bottom = "20%";
				}
				if(getCustomUI("position") == "centerLeft"){
					if(document.querySelector(".skin__frame")) {
						document.querySelector(".skin__frame").classList.add("centerLeft");
					}
					if(proactiveContainer){
						proactiveContainer.classList.add("centerLeft");
					}
					document.querySelector("#CentionChatHeader").style.bottom = "20%";
				}

				//set up bottom position
				if(getCustomUI("position") == "bottomRight" || getCustomUI("position") == "bottomLeft"){
					if(bottomPosition) {
						setElementStyle(document.querySelector(".skin__frame"), "bottom", (bottomPosition ? bottomPosition+'px' : ''));
					}
				}
				//set up right position
				if(getCustomUI("position") == "bottomRight" || getCustomUI("position") == "centerRight"){
					if(rightPosition) {
						setElementStyle(document.querySelector(".skin__frame"), "right", (rightPosition ? rightPosition+'px' : ''));
					}
				}
				//set up left position
				if(getCustomUI("position") == "bottomLeft" || getCustomUI("position") == "centerLeft"){
					if(leftPosition) {
						setElementStyle(document.querySelector(".skin__frame"), "left", (leftPosition ? leftPosition+'px' : ''));
					}
				}
			}

			//for cobrowsing feature
			if(ccs.feature['chat.allow-co-browsing']) {
				//append class .cention-client-wrapper at client's page body html, for future ref
				const centionClientWrapper = document.body;
				centionClientWrapper.classList.add("cention-client-wrapper");

				//add custom cursor element
				document.body.insertAdjacentHTML('beforeend', '<div class="cention-custom-cursor"></div>');
			}

			//send file feature
			if(ccs.feature["chat.attachment"]){
				document.querySelectorAll("[id*='CentionChatSendFileButtonWrapper']").forEach(function(element) {
					element.style.display = "block";
				});
			} else {
				document.querySelectorAll("[id*='CentionChatSendFileButtonWrapper']").forEach(function(element) {
					element.style.display = "none";
				});
			}
			if(document.querySelector("#CentionProactiveButton > span.icon")) {
				document.querySelector("#CentionProactiveButton > span.icon").innerHTML = getCustomConfig("logo");
			}

			if(getCustomText("textMessageProactive") != "") {
				//show proactive msg description
				document.querySelectorAll("#CentionProactiveBody > #textMessageProactive > span.text").forEach(function(element) {
					element.textContent = getCustomText("textMessageProactive");
				});
			} else {
				//hide it
				document.querySelectorAll("#CentionProactiveBody,#textMessageProactive").forEach(function(element) {
					element.style.display = "none";
				});
				document.querySelectorAll("#CentionProactiveTextWrapper").forEach(function(element) {
					element.classList.add("center");
				});
			}
			if(document.getElementById("CentionChatHeader")) {
				document.getElementById("CentionChatHeader").style.backgroundColor = getCustomConfig("headerColor");
				document.querySelector("#CentionChatHeader > span.icon").style.borderRight = "0px";
			}
			if(document.getElementById("CentionProactiveButton")) {
				document.getElementById("CentionProactiveButton").style.backgroundColor = getCustomConfig("headerColor");
			}

			var _chatTitle = getCustomText("textTitle");
			if(ccs.resumable) {
				renderChatLoaderHeader();
				if(document.getElementById("CentionChatHeader")) {
					document.getElementById("CentionChatHeader").style.display = "flex";
				}
			}else {
				const headerSpan = document.querySelector("#CentionChatHeader span.text");
				if(headerSpan) {
					headerSpan.textContent = _chatTitle;
				}
				if(document.getElementById("CentionChatWelcome")) {
					document.getElementById("CentionChatWelcome").style.display = "block";
				}
			}

			if(document.getElementById("CentionChatHeader")) {
				document.querySelector("#CentionChatHeader > #iconLogo").innerHTML = getCustomConfig("logo");
				document.getElementById("CentionChatHeader").style.color = getCustomConfig("headerTextColor");
			}
			if(document.getElementById("CentionProactiveButton")) {
				document.getElementById("CentionProactiveButton").style.color = getCustomConfig("headerTextColor");
			}
			setUpWidgetPosition();
			setTextContentById("textStatusConnecting", getCustomText("textStatusConnecting"))

			//Z-Index
			const zIndex = getCustomUI("zIndex");
			if(zIndex !== "") {
				if(uiSkin == SKIN_MODERN){
					if(document.querySelector("#CentionChat>#Skin-Modern")) {
						document.querySelector("#CentionChat>#Skin-Modern").style.zIndex = zIndex;
					}
				} else if (uiSkin == SKIN_FRICTIONLESS) {
					if(document.querySelector("#CentionChat>#Skin-Frictionless")) {
						document.querySelector("#CentionChat>#Skin-Frictionless").style.zIndex = zIndex;
					}
				} else {
					const chatHeaderDefault = document.querySelector("#CentionChat>#Skin-Default>#CentionChatHeader");
					if (chatHeaderDefault) {
						chatHeaderDefault.style.zIndex = zIndex;
					}
					const chatBodyDefault = document.querySelector("#CentionChat>#Skin-Default>#CentionChatBody");
					if (chatBodyDefault) {
						chatBodyDefault.style.zIndex = zIndex;
					}
				}
				if(document.getElementById("CentionProactiveButton")) {
					document.getElementById("CentionProactiveButton").style.zIndex = zIndex;
				}
				if(document.getElementById("CentionChatProactiveDialog")) {
					document.getElementById("CentionChatProactiveDialog").style.zIndex = zIndex;
				}
				const proactiveContainer = document.querySelector(".proactive__container");
				if(proactiveContainer){
					proactiveContainer.style.zIndex = zIndex;
				}
			}
			//Body
			if(document.getElementById("CentionChatBody")) {
				document.getElementById("CentionChatBody").style.backgroundColor = getCustomConfig("mainColor");
			}
			document.querySelectorAll("[id=textAvailableNote]").forEach(function(element) {
				element.textContent = getCustomText("textAvailableNote");
			});

			function setElementPlaceholderById(elementId, text) {
				const element = document.getElementById(elementId);
				if (element) {
					element.placeholder = text;
				}
			}

			if(FAQ_SKIN && FAQ_SKIN == "frictionless"){
				const labelName = document.querySelector("label[for='textInputName']");
				if(labelName){
					labelName.textContent = getCustomText("textInputName") + " *"
				}
				const labelEmail = document.querySelector("label[for='textInputEmail']");
				if(labelEmail){
					labelEmail.textContent = getCustomText("textInputEmail") + " *"
				}
				const labelPhone = document.querySelector("label[for='textInputPhone']");
				if(labelPhone){
					labelPhone.textContent = getCustomText("textInputPhone");
				}
				const labelQuestion = document.querySelector("label[for='textInputQuestion']");
				if(labelQuestion){
					labelQuestion.textContent = getCustomText("textInputQuestion") + " *"
				}
			} else {
				setElementPlaceholderById("textInputName", getCustomText("textInputName") + " *");
				setElementPlaceholderById("textInputEmail", getCustomText("textInputEmail") + " *");
				setElementPlaceholderById("textInputPhone", getCustomText("textInputPhone"));
				if(getCustomUI("questionFieldMandatory") && !getCustomUI("questionFieldHide")) {
					setElementPlaceholderById("textInputQuestion", getCustomText("textInputQuestion") + " *");
				} else {
					setElementPlaceholderById("textInputQuestion", getCustomText("textInputQuestion"));
				}
			}

			if(getCustomUI("questionFieldHide")) {
				document.getElementById("textInputQuestion").style.display = "none";
			}

			setTextContentById("textStatusQueue", getCustomText("textStatusQueue"));
			setTextContentById("textButtonRetry", getCustomText("textButtonRetry"));
			if(document.getElementById("textButtonRetry")) {
				document.getElementById("textButtonRetry").style.color = getCustomConfig("buttonRetryTextColor");
			}

			//Form warning color
			const warningColor = getCustomConfig("warningTextColor");
			var warningStyle = 'input.warning:-moz-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'input.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'input.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'textarea.warning:-moz-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'textarea.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'textarea.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';

			const warningStyleBlock = '<style id="cention-warning-style">' + warningStyle + '</style>';
			document.head.insertAdjacentHTML('beforeend', warningStyleBlock);

			//Start button
			const textButtonStart = document.getElementById("textButtonStart");
			const textEULA = document.getElementById("textEULA");
			if(textButtonStart) {
				textButtonStart.style.cssText = "color:" + getCustomConfig("buttonStartTextColor") + " !important;border-color:" + getCustomConfig("buttonStartBorderColor") + "!important;background-color:" + getCustomConfig("buttonStartColor") + "!important";
			}

			if (getCustomText("textButtonStart")) {
				textButtonStart.value = getCustomText("textButtonStart");
				textButtonStart.innerHTML = getCustomText("textButtonStart");
				textButtonStart.classList.add("text");
			}
			if (getCustomText("messengerChat")) {
				//console.log("Messenger chat url", getCustomText("messengerChat"));
			}
			setInnerHTMLByElem(textEULA, getCustomText("textEULA"));
			setElementStyle(textEULA, "color", getCustomConfig("askEULATextColor"));

			function removeCreateErrandPlaceholder() {
				const element = document.getElementById("CentionNoAgentsView");
				if(element) {
					const textMessageAway = element.querySelector("#textMessageAway");
					if(textMessageAway) {
						textMessageAway.innerHTML = textMessageAway.innerHTML.replace(/\{CREATE_ERRAND\}/g, "");
					}
				}
			}

			// Away button
			const awayMessageParagraph = document.querySelector("#CentionNoAgentsView > div > p");
			if (awayMessageParagraph) {
				awayMessageParagraph.style.color = getCustomConfig("messageAwayTextColor");
			}

			const textButtonAway = document.getElementById("textButtonAway");
			if(textButtonAway) {
				setElementStyle(textButtonAway, "backgroundColor", getCustomConfig("buttonAwayColor"));
				setElementStyle(textButtonAway, "color", getCustomConfig("buttonAwayTextColor"));
				setElementStyle(textButtonAway, "borderColor", getCustomConfig("buttonAwayBorderColor"));
				textButtonAway.setAttribute("href", getCustomUI("buttonAwayLink"));
				if(getCustomConfig("buttonAwayCustomClass")) {
					textButtonAway.classList.add(getCustomConfig("buttonAwayCustomClass"));
				}
				textButtonAway.innerHTML = getCustomText("textButtonAway");
			}

			// Footer
			if (!ccs.feature['chat.hide-cention-logo']) {
				const CentionWidgetFooterLink = document.querySelector("#CentionWidgetFooter > a");
				if(CentionWidgetFooterLink) {
					CentionWidgetFooterLink.style.color = getCustomConfig("footerTextColor");
					CentionWidgetFooterLink.setAttribute("href", getCustomConfig("footerLink"));
					setInnerHTMLByElem(CentionWidgetFooterLink, getCustomConfig("footerText"));
				}
			}

			// Send Icon
			setElementStyle(document.getElementById("SubmitChat"), "color", getCustomConfig("messageSendIconColor"));

			// Chat Menu
			const chatElements = ["#CentionChatChannelView", "#CentionChatView", "#CentionChatViewHeader", "#CentionChatList"];
			chatElements.forEach(element => {
				setElementStyle(document.querySelector(element), "backgroundColor", getCustomConfig("chatConversationBgColor"));
			});

			setElementStyle(document.getElementById("CentionChatList"), "color", getCustomConfig("chatConversationTextColor"));

			// Modern skin condition
			if (getCustomSkin() === SKIN_MODERN) {
				const modernSkinElements = ["#CentionStartView", "#CentionNoAgentsView", "#CentionChatFooter", "#CentionToggleableChatMenu"];
				modernSkinElements.forEach(element => {
					setElementStyle(document.querySelector(element), "backgroundColor", getCustomConfig("chatConversationBgColor"));
				});
			} else {
				// Toggle Menu (the + and x button)
				if(FAQ_SKIN !== "frictionless"){
					setElementStyle(document.getElementById("CentionToggleMenuButton"), "backgroundColor", getCustomConfig("buttonToggleMenuColor"));
				}
			}

			// Menu items
			setTextContentById("textMenuSendFile", getCustomText("textMenuSendFile"));
			setTextContentById("textMenuNewChat", getCustomText("textMenuNewChat"));
			setTextContentById("textMenuFinishChat", getCustomText("textMenuFinishChat"));
			setTextContentById("textMenuPrintChat", getCustomText("textMenuPrintChat"));
			setTextContentById("textMenuSaveChat", getCustomText("textMenuSaveChat"));
			setTextContentById("textMenuVideoChat", getCustomText("textMenuVideoChat"));

			// Satisfaction Feedback
			setTextContentById("SatisfactionText", getCustomText("textSatisfactionMessage"));
			const satisfactionWrapper = document.querySelector(".satisfactionWrapper");
			setElementStyle(satisfactionWrapper, "backgroundColor", getCustomConfig("feedbackBgColor"));
			setElementStyle(satisfactionWrapper, "color", getCustomConfig("feedbackTextColor"));

			const feedbackButton = document.getElementById("FeedbackButton");
			setTextContentById("FeedbackButton", getCustomText("textFeedbackButton"));
			setElementStyle(feedbackButton, "borderColor", getCustomConfig("feedbackTextColor"));
			if(feedbackButton) {
				feedbackButton.addEventListener("mouseover", function () {
					this.style.backgroundColor = getCustomConfig("feedbackTextColor");
					this.style.color = getCustomConfig("feedbackBgColor");
				});
				feedbackButton.addEventListener("mouseout", function () {
					this.style.backgroundColor = getCustomConfig("feedbackBgColor");
					this.style.color = getCustomConfig("feedbackTextColor");
				});
			}

			setTextContentById("textCommentBoxTitle", getCustomText("textCommentBoxTitle"));
			setTextContentById("buttonOngoingSend", getCustomText("textFeedbackBtnSend"));
			setTextContentById("buttonOngoingCancel", getCustomText("textFeedbackBtnCancel"));
			setTextContentById("textTitleProactive", getCustomText("textTitleProactive"));

			// Feedback ModalBox
			const satisfactionFeedback = document.getElementById("SatisfactionFeedback");
			setElementStyle(satisfactionFeedback, "backgroundColor", getCustomConfig("feedbackBoxBgColor"));
			setElementStyle(document.getElementById("textCommentBoxTitle"), "color", getCustomConfig("feedbackBoxTitleColor"));
			const ongoingContent = document.getElementById("ongoingContent");
			setElementStyle(ongoingContent, "backgroundColor", getCustomConfig("feedbackBoxTextareaBgColor"));
			setElementStyle(ongoingContent, "color", getCustomConfig("feedbackBoxTextareaTextColor"));
			const satisfactionButtons = document.querySelectorAll(".satisfactionButton");
			if(satisfactionButtons) {
				satisfactionButtons.forEach(button => {
					setElementStyle(button, "backgroundColor", getCustomConfig("feedbackBoxBtnBgColor"));
					setElementStyle(button, "color", getCustomConfig("feedbackBoxBtnTextColor"));
				});
			}

			// Frictionless skin condition
			if(uiSkin === SKIN_FRICTIONLESS) {
				//here all skin follows main body color (mainColor)
				setElementStyle(document.getElementById("CentionChatChannelView"), "backgroundColor", getCustomConfig("mainColor"));
				setElementStyle(document.getElementById("CentionChatView"), "backgroundColor", getCustomConfig("mainColor"));
				setElementStyle(document.getElementById("CentionNoAgentsView"), "backgroundColor", getCustomConfig("mainColor"));
				setElementStyle(document.getElementById("CentionChatList"), "backgroundColor", getCustomConfig("mainColor"));
				setElementStyle(document.getElementById("CentionTextAreaBox"), "backgroundColor", getCustomConfig("chatConversationTextareaBgColor"));
				setElementStyle(document.getElementById("CentionToggleMenuButton"), "color", getCustomConfig("toggleMenuTextColor"));
			
				if(FAQ_SKIN == "frictionless"){
					setElementStyle(document.getElementById("CentionChatFooterWrapper"), "backgroundColor", getCustomConfig("chatConversationTextareaBgColor"));
				} else {
					setElementStyle(document.getElementById("CentionToggleMenuButton"), "backgroundColor", getCustomConfig("mainColor"));
				}
			}

			if(FAQ_SKIN == "frictionless"){
				setElementStyle(document.getElementById("CentionChatFooterWrapper"), "backgroundColor", getCustomConfig("chatConversationTextareaBgColor"));
			}

			if(FAQ_SKIN !== "frictionless"){
				setElementStyle(document.getElementById("CentionChatQuestion"), "backgroundColor", getCustomConfig("chatConversationTextareaBgColor"));
			}

			setElementStyle(document.getElementById("CentionChatQuestion"), "color", getCustomConfig("chatConversationTextareaColor"));

			const chatMenuIcon = document.querySelectorAll("#CentionToggleableChatMenu i");
			chatMenuIcon.forEach((elem) => {
				elem.style.color = getCustomConfig("chatToggleMenuIconsColor");
				if(FAQ_SKIN && FAQ_SKIN == "frictionless"){
					const submitChatIcon = document.querySelector("#SubmitChat i");
					if(submitChatIcon){
						submitChatIcon.style.color = getCustomConfig("chatToggleMenuIconsColor");
					}
				}
			});
			const chatMenuTxt = document.querySelectorAll("#CentionToggleableChatMenu span");
			chatMenuTxt.forEach((elem) => {
				elem.style.color = getCustomConfig("chatToggleMenuIconsTextColor");
			});

			//proactive UI
			if(document.getElementById("CentionChatProactiveDialog")) {
				document.getElementById("CentionChatProactiveDialog").style.backgroundColor = getCustomConfig("proactiveDialogBgColor");
				document.getElementById("CentionProactiveHeader").style.backgroundColor = getCustomConfig("proactiveDialogBgColor");
				document.getElementById("textTitleProactive").style.color = getCustomConfig("proactiveTitleTextColor");
				document.querySelector("#textMessageProactive > .text").style.color = getCustomConfig("proactiveDescTextColor");
			}

			if(getCustomUI("proactiveImageURL") !== "") {
				if(document.getElementById("CentionProactiveAvatar")) {
					document.getElementById("CentionProactiveAvatar").src = getCustomUI("proactiveImageURL");
				}
			} else {
				if(document.getElementById("CentionProactiveAvatarWrapper")) {
					document.getElementById("CentionProactiveAvatarWrapper").style.display = "none";
				}
			}
			if(uiSkin === SKIN_DEFAULT) {
				if(document.getElementById("CentionChatProactiveDialog")) {
					document.getElementById("CentionChatProactiveDialog").classList.add("default");
				}
				if(document.getElementById("CentionProactiveButton")) {
					document.getElementById("CentionProactiveButton").classList.add("default");
					proactiveChatTitle = "<span id='textTitle' class='text'>"+_chatTitle+"</span>";
					document.getElementById("CentionProactiveButton").insertAdjacentHTML('beforeend', proactiveChatTitle);
				}
			}

			//proactive popup layout proactivePopupType
			if(getCustomUI("proactivePopupType") === 1) {
				//portrait
				if(document.getElementById("CentionChatProactiveDialog")) {
					document.getElementById("CentionChatProactiveDialog").classList.add("proactive-portrait");
				}
			} else {
				//landscape
				if(document.getElementById("CentionChatProactiveDialog")) {
					document.getElementById("CentionChatProactiveDialog").classList.add("proactive-landscape");
				}
			}

			//Cookie Warnings
			setElementStyle(document.getElementById("showCookieWarning"), "color", getCustomConfig("cookieWarningSymbolColor"));
			setElementStyle(document.getElementById("CentionCookieWarning"), "color", getCustomConfig("cookieWarningTextColor"));
			setElementStyle(document.getElementById("CentionCookieWarning"), "backgroundColor", getCustomConfig("cookieWarningBgColor"));

			//Video call UI setup
			setElementStyle(document.getElementById("CentionChatVideoCallWrapper"), "display", "none");
			setElementStyle(document.getElementById("CentionChatVideoFrame"), "display", "none");
			setElementStyle(document.getElementById("CentionChatVideoButtonStop"), "display", "none");

			if(getCustomUI("enableVideoChat") && ccs.feature['chat.video-call'] || ccs.feature['chat.agent-video-call'] || ccs.feature['chat.allow-screen-sharing'] || ccs.feature['chat.allow-co-browsing']) {
				const vidHTML = "<div id='CentionChatVideoFrame'>"+
								"<div class='cention-video-header'>"+
									"<span><i class='icon-video-call'></i>"+
										getCustomText("textVideoCallHeader")+
									"</span>"+
									"<div id='CentionCloseVid'><i class='icon-close'></i></div>"+
								"</div>"+
								"<div id='CentionChatVideoFrameWrapper'>"+
									//local cam
									"<div id='CentionChatVideoLocalWrapper' style='display:none'>"+
										"<video id='CentionChatVideoFrameLocal' autoplay playsinline muted></video>"+
										"<div id='AvatarOverlayLocal' class='avatar-overlay local' style='display:none'><i class='icon-user-circle'></i></div>"+
										"<div class='vid-local-info'><span class='vid-local-name'></span><i class='icon-microphone-mute client-mute' style='display:none'></i></div>"+
									"</div>"+
									//remote cam
									"<div id='CentionChatVideoRemoteWrapper' style='display:none'>"+
										"<video id='CentionChatVideoFrameRemote' width='300px' height='200px' autoplay playsinline></video>"+
										"<div id='AvatarOverlayRemote' class='avatar-overlay remote' style='display:none'><i class='icon-user-circle'></i></div>"+
										"<canvas id='agentBlurCanvas' hidden></canvas>"+
										"<div id='bg-container' hidden>"+
											"<div id='vid-bg-container'></div>"+
											"<i id='vid-self-loading' title='Extracting background' class='icon-spinner rotating' hidden></i>"+
											"<canvas id='canvasBg'></canvas>"+
										"</div>"+
										"<div class='vid-remote-info'><span class='vid-remote-name'></span><i class='icon-microphone-mute agent-mute' style='display:none'></i></div>"+
									"</div>"+
									//local screen
									"<div id='CentionChatClientScreenShareWrapper' style='display:none'>"+
										"<div class='screen-share-preview'>"+
											"<video id='client-widget-display-view' autoplay loop playsinline muted></video>"+
											"<div class='screen-self-view-info'>"+
												getCustomText("textScreenSharing")+
											"</div>"+
										"</div>"+
									"</div>"+
									//remote screen
									"<div id='CentionChatAgentScreenShareWrapper' style='display:none'>"+
										"<video id='agent-widget-display-view' autoplay loop playsinline muted></video>"+
										"<div id='CloseAgentVid'><i class='icon-close'></i></div>"+
									"</div>"+
								"</div>"+
								"<div id='CentionChatRemoteDisplay' class='screen-share-wrapper'>"+
								"</div>"+
								"<div id='CentionCoBrowsingStatus'>"+
								CentionChat.I("Co-browsing session status")+
								": <span id='coBrowsingStatusTxt'></span></div>"+
								"<div id='CentionChatVideoBtns' class='btn-wrapper'>"+
									"<div id='screenShare' title='Start Screen Sharing'><i class='icon-sharescreen'></i></div>"+
									"<div id='stopScreenShare' title='Stop Screen Sharing' hidden><i class='icon-sharescreen'></i></div>"+
									"<div id='coBrowsing' title='Start Co Browsing' hidden><i class='icon-cobrowse'></i></div>"+
									"<div id='stopCoBrowsing' title='Stop Co Browsing' hidden><i class='icon-cobrowse'></i></div>"+
									"<div id='toggleRemoteControl' title='Toggle remote control access' hidden><i class='icon-control'></i>"+
										"<div id='toggleRemoteControlListOpt' hidden>"+
											"<span id='toggleRemoteKeyboard' title='Click to toggle keyboard control for agent'>Keyboard <i class='icon-single-tick1 allowed'></i><i class='icon-close disallowed'></i></span>"+
											"<span id='toggleRemoteMouse' title='Click to toggle mouse control for agent'>Mouse <i class='icon-single-tick1 allowed'></i><i class='icon-close disallowed'></i></span></span>"+
										"</div>"+
									"</div>"+
									"<div id='disableVidButton' title='Disable Video'><i class='icon-video-call'></i></div>"+
									"<div id='enableVidButton' title='Enable Video'><i class='icon-video-call-disable'></i></div>"+
									"<div id='disableAudioButton' title='Disable Mic'><i class='icon-microphone'></i></div>"+
									"<div id='enableAudioButton' title='Enable Mic'><i class='icon-microphone-mute'></i></div>"+
									"<div id='startButton' title='Start Camera'><i class='icon-video-call'></i></div>"+
									"<div id='callButton' title='Call'><i class='icon-call'></i></div>"+
									"<div id='hangupButton' title='Hangup Call'><i class='icon-call'></i></div>"+
									"<div id='maximizeVideo' title='"+CentionChat.I("Enter full screen")+"'><i class='icon-fullscreen' id='centionMaxIcon'></i></div>"+
									"<div id='hideVidPreview' title='Hide Video Preview'><i class='icon-chevron-down' id='centionHidePreviewIcon'></i></div>"+
									"<span id='callTimer'>0:00:00</span>"+
								"</div>"+
							"</div>";
				if(document.getElementById("cention-video-container")) {
					document.getElementById("cention-video-container").insertAdjacentHTML('beforeend', vidHTML);
					document.getElementById("maximizeVideo").addEventListener("click", function(e){
						toggleFullScreen();
					});
					document.getElementById("CentionChatVideoFrame").style.backgroundColor = getCustomConfig("mainColor");
					document.getElementById("CentionChatVideoBtns").style.backgroundColor = getCustomConfig("mainColor");

					//Initiated video call frame UI
					const vidbtns = document.getElementById("CentionChatVideoBtns");
					if(vidbtns) {
						document.getElementById("enableAudioButton").style.display = "none";
						document.getElementById("enableVidButton").style.display = "none";
						document.getElementById("callButton").style.display = "none";
						document.getElementById("hangupButton").style.display = "none";
						document.getElementById("disableVidButton").style.display = "none";
						document.getElementById("disableAudioButton").style.display = "none";
						document.getElementById("hideVidPreview").style.display = "none";
					}

					document.querySelectorAll("[id*='coBrowsing']").forEach(function(element) {
						element.style.display = "none";
					}
					);
					document.querySelectorAll("[id*='stopCoBrowsing']").forEach(function(element) {
						element.style.display = "none";
					}
					);
					document.getElementById("CentionCoBrowsingStatus").style.display = "none";
					document.getElementById("toggleRemoteControl").style.display = "none";

					//Support custom element to trigger chat
					let customChatElemExist = false;
					if( typeof customChatBtn != "undefined" && customChatBtn != "" ) {
						if(document.getElementById(customChatBtn)) {
							customChatElemExist = true;
						}
						if ( customChatElemExist ) {
							document.getElementById(customChatBtn).classList.add("centionChatTrigger");
							useCustomChatTrigger = true;
							document.getElementById("CentionChatHeader").style.display = "none";
						}
					}

					if(ccs.feature['chat.allow-screen-sharing']) {
						document.getElementById("screenShare").style.display = "block";
					} else {
						document.getElementById("screenShare").style.display = "none";
					}

				}
			}
			if(ccs.feature["chat.allow-video-call"] && getCustomUI("enableVideoChat")) {
				if(document.getElementById("startButton")) {
					document.getElementById("startButton").style.display = "block";
				}
			} else {
				if(document.getElementById("startButton")) {
					document.getElementById("startButton").style.display = "none";
				}
			}

			//hide/show other video preview
			const hideVidPreview = document.getElementById("hideVidPreview");
			if(hideVidPreview) {
				hideVidPreview.setAttribute("title", CentionChat.I("Hide video preview"));
			hideVidPreview.addEventListener("click", function () {
				const CentionChatVideoFrameWrapper = document.getElementById("CentionChatVideoFrameWrapper");
				const cVidThumbnail = document.querySelectorAll(".cVidThumbnail");
				const CentionChatVideoLocalWrapper = document.getElementById("CentionChatVideoLocalWrapper");
				const CentionChatVideoRemoteWrapper = document.querySelectorAll("#CentionChatVideoRemoteWrapper.thumbnail");
				const CentionChatVideoFrameRemote = document.getElementById("CentionChatVideoFrameRemote");

				CentionChatVideoFrameWrapper.classList.toggle("minimized");

				if (this.classList.contains("clicked")) {
					if (fullScreenMode) {
						hideVidPreview.setAttribute("title", CentionChat.I("Show video preview"));
						document.getElementById("centionHidePreviewIcon").classList.remove("icon-chevron-down");
						document.getElementById("centionHidePreviewIcon").classList.add("icon-chevron-up");
						this.classList.remove("clicked");
					}
				} else {
					if (fullScreenMode) {
						cVidThumbnail.forEach(thumbnail => {
							if (!thumbnail.classList.contains("avatar-overlay")) {
								thumbnail.style.display = "block";
							}
						});
						CentionChatVideoLocalWrapper.style.display = "block";
						CentionChatVideoRemoteWrapper.forEach(wrapper => {
							wrapper.style.display = "block";
						});

						if (blurActivated || bgActivated) {
							CentionChatVideoFrameRemote.style.display = "none";
						}

						if (currentFS !== FS_DISPLAY_LOCAL) {
							document.querySelector(".screen-self-view-info").style.display = "block";
						}

						hideVidPreview.setAttribute("title", CentionChat.I("Hide video preview"));
						document.getElementById("centionHidePreviewIcon").classList.remove("icon-chevron-up");
						document.getElementById("centionHidePreviewIcon").classList.add("icon-chevron-down");
						this.classList.add("clicked");
					}
				}
			});
			}

			// Toggle mouse control
			if(document.getElementById('toggleRemoteMouse')) {
				document.getElementById('toggleRemoteMouse').addEventListener("click", function() {
					if (this.classList.contains("disabled")) {
						this.classList.remove("disabled");
						toggleAgentMouseAccess(true);
						document.querySelectorAll(".cention-custom-cursor").forEach(cursor => {
							cursor.style.display = "block";
						});
					} else {
						this.classList.add("disabled");
						toggleAgentMouseAccess(false);
						document.querySelectorAll(".cention-custom-cursor").forEach(cursor => {
							cursor.style.display = "none";
						});
						removeCoBrowseFocus();
					}
				});
			}

			// Toggle keyboard control
			if(document.getElementById('toggleRemoteKeyboard')) {
				document.getElementById('toggleRemoteKeyboard').addEventListener("click", function() {
					if (this.classList.contains("disabled")) {
						this.classList.remove("disabled");
						toggleAgentKeyboardAccess(true);
						// Todo: indicate (UI) that input is handled by agent
					} else {
						this.classList.add("disabled");
						toggleAgentKeyboardAccess(false);
					}
				});

			}
			// Make main video frame draggable
			function handle_drag(e) {
				if (!fullScreenMode) {
					window.my_dragging = {};
					my_dragging.pageX0 = e.pageX;
					my_dragging.pageY0 = e.pageY;
					my_dragging.elem = this;
					my_dragging.offset0 = this.getBoundingClientRect();
					function handle_dragging(e) {
						var left = my_dragging.offset0.left + (e.pageX - my_dragging.pageX0);
						var top = my_dragging.offset0.top + (e.pageY - my_dragging.pageY0);
						my_dragging.elem.style.left = left + 'px';
						my_dragging.elem.style.top = top + 'px';
					}
					function handle_mouseup(e) {
						document.body.removeEventListener('mousemove', handle_dragging);
						document.body.removeEventListener('mouseup', handle_mouseup);
					}
					document.body.addEventListener('mousemove', handle_dragging);
					document.body.addEventListener('mouseup', handle_mouseup);
				}
			}
			if(document.getElementById('CentionChatVideoFrame')) {
				document.getElementById('CentionChatVideoFrame').addEventListener('mousedown', handle_drag);
			}

			// Handle thumbnail drag
			function handle_thumbnailDrag(e) {
				if (fullScreenMode) {
					window.dragVid = {};
					dragVid.pageX0 = e.pageX;
					dragVid.pageY0 = e.pageY;
					dragVid.elem = this;
					dragVid.offset0 = this.getBoundingClientRect();
					function handle_dragging(e) {
						var left = dragVid.offset0.left + (e.pageX - dragVid.pageX0);
						var top = dragVid.offset0.top + (e.pageY - dragVid.pageY0);
						dragVid.elem.style.left = left + 'px';
						dragVid.elem.style.top = top + 'px';
					}
					function handle_mouseup(e) {
						document.body.removeEventListener('mousemove', handle_dragging);
						document.body.removeEventListener('mouseup', handle_mouseup);
					}
					document.body.addEventListener('mousemove', handle_dragging);
					document.body.addEventListener('mouseup', handle_mouseup);
				}
			}

			//disable draggable since we can hide thumbnail/preview during fullscreen
			//document.getElementById("CentionChatVideoFrameLocal").addEventListener("mousedown", handle_thumbnailDrag);
			//document.getElementById("CentionChatVideoFrameRemote").addEventListener("mousedown", handle_thumbnailDrag);

			function toggleLocalFS() {
				document.querySelector("#CentionChatVideoFrameWrapper > div").classList.remove("no-thumbnail");
				document.querySelector("#CentionChatVideoLocalWrapper").classList.add("no-thumbnail");
				document.querySelectorAll("#CentionChatVideoFrameWrapper video").forEach(video => {
					video.classList.remove("cVidFullScreen");
				});
				document.getElementById("CentionChatVideoFrameLocal").classList.add("cVidFullScreen");
				document.getElementById("CentionChatVideoFrameLocal").classList.remove("cVidThumbnail");
			}

			function toggleRemoteFS() {
				document.querySelector("#CentionChatVideoFrameWrapper > div").classList.remove("no-thumbnail");
				document.querySelector("#CentionChatVideoRemoteWrapper").classList.add("no-thumbnail");
				document.querySelectorAll("#CentionChatVideoFrameWrapper video").forEach(video => {
					video.classList.remove("cVidFullScreen");
				});
				document.getElementById("CentionChatVideoFrameRemote").classList.add("cVidFullScreen");
				document.getElementById("CentionChatVideoFrameRemote").classList.remove("cVidThumbnail");
			}

			function toggleLocalShareFS() {
				document.querySelector("#CentionChatVideoFrameWrapper > div").classList.remove("no-thumbnail");
				document.querySelector("#CentionChatClientScreenShareWrapper").classList.add("no-thumbnail");
				document.querySelectorAll("#CentionChatVideoFrameWrapper video").forEach(video => {
					video.classList.remove("cVidFullScreen");
				});
				document.getElementById("client-widget-display-view").classList.add("cVidFullScreen");
				document.getElementById("client-widget-display-view").classList.remove("cVidThumbnail");
			}

			function toggleRemoteShareFS() {
				document.querySelector("#CentionChatVideoFrameWrapper > div").classList.remove("no-thumbnail");
				document.querySelector("#CentionChatAgentScreenShareWrapper").classList.add("no-thumbnail");
				document.querySelectorAll("#CentionChatVideoFrameWrapper video").forEach(video => {
					video.classList.remove("cVidFullScreen");
				});
				document.getElementById("agent-widget-display-view").classList.add("cVidFullScreen");
				document.getElementById("agent-widget-display-view").classList.remove("cVidThumbnail");
			}

			function toggleFullScreen(e) {
				if(!fullScreenMode) {
					fullScreenMode = true;
					document.body.classList.add("cention-fullscreen");
					document.getElementById("CentionChatVideoFrame").style.cursor = "default";
					document.querySelector(".cention-video-header").style.display = "none";
					document.getElementById("CentionChatVideoFrameRemote").classList.add("cVidFullScreen");
					document.getElementById("CentionChatVideoRemoteWrapper").classList.add("no-thumbnail");
					document.querySelectorAll("#CentionChatVideoFrameWrapper video").forEach(video => {
						video.classList.add("cVidThumbnail");
					});

				} else {
					fullScreenMode = false;
					document.body.classList.remove("cention-fullscreen");
					document.getElementById("CentionChatVideoFrame").style.cursor = "move";
					document.querySelector(".cention-video-header").style.display = "block";
					document.getElementById("CentionChatVideoFrameLocal").style.display = "block";
				}

				if(fullScreenMode) {
					//no screen sharing
					document.getElementById("CentionChatVideoFrame").classList.add("fullscreen-mode");
					document.getElementById("centionMaxIcon").classList.add("icon-fullscreen-end");
					document.getElementById("maximizeVideo").setAttribute("title", CentionChat.I("Exit full screen"));
					document.getElementById("centionMaxIcon").classList.remove("icon-fullscreen");

					if(onVideoCall) {
						document.getElementById("CentionChatVideoFrameRemote").classList.add("cVidFullScreen");
						document.getElementById("CentionChatVideoFrameLocal").classList.add("cVidThumbnail");
						document.getElementById("agent-widget-display-view").classList.remove("cVidFullScreen");
						document.getElementById("CentionChatAgentScreenShareWrapper").classList.remove("fullscreen");
						document.getElementById("CentionChatVideoFrameRemote").classList.remove("cVidThumbnail");
						document.getElementById("CentionChatVideoFrameRemote").classList.remove("left");
						document.getElementById("AvatarOverlayRemote").classList.remove("left");
						document.getElementById("CentionChatClientScreenShareWrapper").classList.remove("thumbnail");
						document.getElementById("CentionChatClientScreenShareWrapper").classList.remove("fullscreen");
						document.getElementById("CentionChatAgentScreenShareWrapper").classList.remove("thumbnail");
						document.getElementById("CentionChatVideoRemoteWrapper").classList.remove("thumbnail");

						document.getElementById("CentionChatVideoFrameLocal").classList.remove("cVidThumbnail side-right");
						document.getElementById("AvatarOverlayLocal").classList.remove("cVidThumbnail side-right");
						document.getElementById("CentionChatVideoFrameRemote").classList.remove("cVidThumbnail side-left");
						document.getElementById("AvatarOverlayRemote").classList.remove("cVidThumbnail side-left");

						document.getElementById("CentionChatVideoFrameLocal").classList.add("cVidThumbnail");
					}
					document.getElementById("CentionChatVideoBtns").style.width = "100%";
					document.getElementById("CentionChatVideoBtns").style.left = "0";
					document.getElementById("CentionCloseVid").style.display = "none";
					if(document.querySelector(".cVidThumbnail").style.display === "block") {
						document.getElementById("hideVidPreview").style.display = "block";
					} else {
						document.getElementById("hideVidPreview").style.display = "none";
					}

					if(currentFS === FS_REMOTE){ //tk checkback
						if(bgActivated) {
							onBgFullScreen();
						} else if(blurActivated) {
							onBlurFullScreen();
						}
					} else {
						if(bgActivated) {
							onBgRemote();
						} else if(blurActivated){
							onBlurRemote();
						}
					}
					switch (currentFS) {
						case FS_REMOTE:
							toggleRemoteFS();
							break;
						case FS_LOCAL:
							toggleLocalFS();
							break;
						case FS_DISPLAY:
							toggleRemoteShareFS();
							break;
						case FS_DISPLAY_LOCAL:
							toggleLocalShareFS();
							break;
						case FS_DEFAULT:
						default:
							toggleLocalFS();
							break;
					}
				} else {
					document.getElementById("AvatarOverlayRemote").classList.remove("cVidFullScreen");
					resetVidUI();
				}
				document.getElementById("CentionChatVideoFrame").classList.toggle("cVidFullScreen");
				document.getElementById("CentionChat").classList.toggle("chatContainerHidden");
			}

			function resetVidUI() {
				document.getElementById("centionMaxIcon").classList.remove("icon-fullscreen-end");
				document.getElementById("centionMaxIcon").classList.add("icon-fullscreen");
				document.getElementById("maximizeVideo").setAttribute("title", CentionChat.I("Enter full screen"));

				document.getElementById("CentionCloseVid").style.display = "block";
				document.getElementById("hideVidPreview").style.display = "none";

				document.getElementById("CentionChatVideoFrame").className = "";
				document.getElementById("CentionChatVideoFrameWrapper").className = "";

				document.getElementById("CentionChatVideoRemoteWrapper").className = "";
				document.getElementById("CentionChatVideoFrameRemote").className = "";
				document.getElementById("AvatarOverlayRemote").className = "avatar-overlay remote";
				document.getElementById("CentionChatVideoLocalWrapper").className = "";
				document.getElementById("CentionChatVideoFrameLocal").className = "";
				document.getElementById("AvatarOverlayLocal").className = "avatar-overlay local";

				document.getElementById("CentionChatRemoteDisplay").className = "screen-share-wrapper";
				document.getElementById("CentionChatClientScreenShareWrapper").className = "";
				document.getElementById("client-widget-display-view").className = "";
				document.getElementById("CentionChatAgentScreenShareWrapper").className = "";
				document.getElementById("agent-widget-display-view").className = "";

				if(onVideoCall) {
					document.getElementById("CentionChatVideoLocalWrapper").style.display = "block";
					document.getElementById("CentionChatVideoFrameLocal").style.display = "block";
					if(bgActivated){
						exitBgFullScreen();
						onBgRemote();
					} else if(blurActivated){
						exitBlurFullScreen();
						onBlurRemote();
					} else {
						onResetRemoteVideo();
					}
				}
			}

			document.onkeydown = function (e) {
				e = e || window.event;
				if(fullScreenMode) {
					if(e.key === "Escape" || e.key === "Esc") {
						toggleFullScreen(e);
					}
				}
			};

			if (useFAQChatInChat) {
				//CHAT CONFIGS
				const chatBodyElement = document.querySelector("#CentionChatBody");
				if (chatBodyElement) {
					chatBodyElement.style.backgroundColor = "transparent";
				}
				const startViewElement = document.querySelector("#CentionStartView");
				if (startViewElement) {
					if (!callbackEnabled) {
						startViewElement.classList.add("withoutCallback");
					}
					if (CentionChatUseDynamicArea) {
						startViewElement.classList.add("withAreas");
					}
				}
			}

			//Status of the arrow icon
			if (document.getElementById('CentionChatBody') && document.getElementById('CentionChatBody').style.display === 'block') {
				if(document.getElementById('toggleChatHeader')) {
					document.getElementById('toggleChatHeader').classList.remove('fa-chevron-up');
					document.getElementById('toggleChatHeader').classList.add('fa-chevron-down');
				}
			} else {
				if(document.getElementById('toggleChatHeader')) {
					document.getElementById('toggleChatHeader').classList.add('fa-chevron-up');
					document.getElementById('toggleChatHeader').classList.remove('fa-chevron-down');
				}
			}

			//Setup extra field setup if provided in the config
			var extraFieldParam = {};
			if(getCustomUI("extraFieldEnabled")){
				haveChatExtraInputField = true;
				const extraFieldlabel = getCustomUI("extraFieldDisplayName") ? getCustomUI("extraFieldDisplayName") : "";
				extraChatFieldLabel = extraFieldlabel;
				extraChatFieldMandatory = getCustomUI("extraFieldMandatory") ? getCustomUI("extraFieldMandatory") : false;
				extraFieldParam = {
					field:[
						{
							id: "textInputExtraField",
							label: extraFieldlabel,
							required: getCustomUI("extraFieldMandatory") ? getCustomUI("extraFieldMandatory") : false,
						},
					],
					template: "<div><span>"+extraFieldlabel+" : </span><span> {EXTRA_FIELD_VALUE} </span></div>"
				}
				if(FAQ_SKIN !== "frictionless"){
					document.getElementById("textInputEmail").insertAdjacentHTML('afterend', "<input id="+extraFieldParam.field[0].id+" type='text'>");
				}
				if(extraFieldParam.field[0].required) {
					if (document.getElementById("textInputExtraField")) {
						document.getElementById("textInputExtraField").required = true;
						document.getElementById("textInputExtraField").setAttribute("aria-required", "true");
						document.getElementById("textInputExtraField").setAttribute("placeholder", extraFieldParam.field[0].label+" *");
					}
				}else {
					if(document.getElementById("textInputExtraField")) {
						document.getElementById("textInputExtraField").setAttribute("placeholder", extraFieldParam.field[0].label);
					}
				}
			}

			//EULA Acceptance setup
			if(getCustomUI("askEULA")){
				setElementStyle(document.getElementById("EULAAcceptance"), "display", "block");
			}else{
				setElementStyle(document.getElementById("EULAAcceptance"), "display", "none");
			}

			//Hide list of agent's avatars on the chat form
			if(getCustomUI("hideAgentAvatarList")){
				document.querySelectorAll(".agentList .agentAvatar").forEach(function(element) {
					element.style.display = "none";
				});
			}

			// Work on dynamic area
			const selectArea = document.getElementById('selectAreaId');
			const selectAreaOpts = document.getElementById('selectAreaIdOpts');
			const CentionStartView = document.getElementById('CentionStartView');

			if (CentionChatUseDynamicArea) {
				if (selectAreaOpts || selectArea) {
					if (selectAreaOpts.parentNode.isSameNode(CentionStartView) || !selectArea.parentNode.isSameNode(CentionStartView)) {
						if (selectAreaOpts && selectAreaOpts.hasChildNodes()) {
							return;
						}
						if (selectArea && selectArea.hasChildNodes()) {
							return;
						}
					}
				}
			}

			if (CentionChatUseDynamicArea) {
				const noPreSelectArea = getCustomUI("avoidPreselectArea");
				if (areas) {
					if (selectArea) {
						areas.forEach(item => {
							let itemHTML;
								if (selectArea) {
									selectArea.style.display = 'block';
								}
								itemHTML = '<option value="' + item.Id + '">' + item.Name + '</option>';
								const newItem = document.createElement('option');
								setInnerHTMLByElem(newItem, itemHTML);
								selectArea.insertBefore(newItem.firstChild, selectArea.firstChild);
						});
						if (noPreSelectArea) {
							if (!getCustomUI("showAllAreaList")) {
								const noArea = '<option value="-1" selected>' + getCustomText("textNoSelectArea") + '</option>';
								const newItem = document.createElement('option');
								newItem.innerHTML = noArea;
								selectArea.insertBefore(newItem.firstChild, selectArea.firstChild);
							}
						}

					}
				} else {
					areas.forEach(item => {
						let itemHTML;
						selectAreaOpts.style.display = 'none';
						itemHTML = '<option value="' + item.Id + '">' + item.Name + '</option>';
						const newItem = document.createElement('option');
						setInnerHTMLByElem(newItem, itemHTML);
						selectArea.insertBefore(newItem.firstChild, selectArea.firstChild);
					});
					if (noPreSelectArea) {
						if (!getCustomUI("showAllAreaList")) {
							const noArea = '<option value="-1" selected>' + getCustomText("textNoSelectArea") + '</option>';
							const newItem = document.createElement('option');
							newItem.innerHTML = noArea;
							selectArea.insertBefore(newItem.firstChild, selectArea.firstChild);
						}
					}
				}
			} else {
				setElementStyle(document.getElementById('selectAreaId'), "display", "none");
				setElementStyle(document.getElementById('selectAreaIdOpts'), "display", "none");
			}

			// This function used to reset the position and width of
			// the header which is glued to the bottom of the page.
			// This function is called when clicking on the header
			// and when the start button is clicked.
			function CentionChatResetBodySize(status) {
				// TODO: clean & refactor this codes
				if(getCustomSkin() == SKIN_FRICTIONLESS) {
				} else {
					var headerWidth = '175px';
					if(getCustomSkin() == SKIN_MODERN || getCustomSkin() == SKIN_FRICTIONLESS){
						headerWidth = '100%';
					}
					if(getPosition == "bottomRight" || getPosition == "bottomLeft") {
						if(status == "resumeSession") {
							setElementStyle(document.getElementById('CentionChatBody'), "display", "none");
							if(document.getElementById('CentionChatHeader')) {
								document.getElementById('CentionChatHeader').classList.remove("maximized");
							}
						}else if (status == "resumeFailed"){
							if(document.getElementById('CentionChatBody')) {
								setElementStyle(document.getElementById('CentionChatBody'), "display", "none");
							}
						}else {
						}
					}else {
						if(getPosition == "centerRight"){
							const CentionChatBody = document.getElementById('CentionChatBody');
							const CentionNoAgentsView = document.getElementById('CentionNoAgentsView');
							if (CentionChatBody && CentionChatBody.style.display !== 'none') {
								if (status === "connected") {
									CentionChatBody.style.right = '0px';
									CentionChatBody.style.display = 'block';		
								} else {
									if (status !== "restart") {
										CentionChatBody.style.right = '0px';
										CentionChatBody.style.display = 'none';
									}
								}
							} else {
								if (CentionNoAgentsView.style.display === 'none') {
									CentionChatBody.style.right = '0px';
									CentionChatBody.style.display = 'block';
								} else {
									CentionChatBody.style.right = '0px';
									CentionChatBody.style.display = 'block';
								}
							}
						}else {
							//left
							const CentionChatBody = document.getElementById('CentionChatBody');
							const CentionNoAgentsView = document.getElementById('CentionNoAgentsView');
							if (CentionChatBody && CentionChatBody.style.display !== 'none') {
								if (status === "connected") {
									CentionChatBody.style.left = '0px';
									CentionChatBody.style.display = 'block';
								} else {
									if (status !== "restart") {
										CentionChatBody.style.left = '0px';
										CentionChatBody.style.display = 'none';
									}
								}
							} else {
								if (CentionNoAgentsView && CentionNoAgentsView.style.display === 'none') {
									CentionChatBody.style.left = '0px';
									CentionChatBody.style.display = 'block';
								} else {
									CentionChatBody.style.left = '0px';
									CentionChatBody.style.display = 'block';
								}
							}
						}
					}
					}
			}
			//The sendPreview function call occurs when user is typing a message.
			//It sends information to the system that user is typing a message.
			function sendPreview () {
				//message is pass through CentionChat.preview(message) function to send notification that user is writing a message.
				// And system shows this notification at agent side.
				const centionQuestionElem = document.getElementById('CentionChatQuestion');
				if (centionQuestionElem) {
					CentionChat.preview(centionQuestionElem.innerHTML);
				}
				//previewCount is reset after sending information. It sends information to system after 5 previewCount.
				previewCount = 0;
			}

			/****************************************/
			/* Register Cention chat event handlers */
			/****************************************/

			// The "connect error" event occurs if it wasn't possible to connect
			// the web browser to the Cention chat server.
			CentionChat.registerAction('connect_error', function(err) {
				console.log("Connecting...");
				renderChatLoaderHeader();
				document.getElementById('CentionChatStatus').style.display = 'block';
				document.getElementById('CentionChatConnected').style.display = 'none';
			});

			//Hides unnecessary menu when chatting with chatbot
			//Advance menu are attachment/file, video and audio call.
			function toggleAdvanceMenu(show) {
				if(!show) {
					document.getElementById("CentionChatSendFileButtonWrapper").style.display = "none";
					document.getElementById("CentionChatVideoCallWrapper").style.display = "none";
					document.getElementById("CentionChatAudioCallWrapper").style.display = "none";
				} else{
					//check feature/config first, and then show if necessary
					if(ccs.feature["chat.attachment"]){
						document.getElementById("CentionChatSendFileButtonWrapper").style.display = "block";
					} else {
						document.getElementById("CentionChatSendFileButtonWrapper").style.display = "none";
					}
					if(getCustomUI("enableVideoChat") && (ccs.feature['chat.allow-video-call'] || ccs.feature['chat.allow-screen-sharing'] || ccs.feature['chat.allow-co-browsing']) ) {
						document.getElementById("CentionChatVideoCallWrapper").style.display = "block";
					} else {
						document.getElementById("CentionChatVideoCallWrapper").style.display = "none";
					}
					if (callbackEnabled) {
						document.getElementById("CentionChatAudioCallWrapper").style.display = "block";
					} else{
						document.getElementById("CentionChatAudioCallWrapper").style.display = "none";
					}
				}
			}

			if (ccs.resumable) {
				// When there's still active chat, the chat will expanded automatically
				// Unless minimized by client

				if(chat_config_full_screen_mobile && isChatMobileDeviceSize) {
					if (!document.querySelector(".skin__frame").classList.contains("cention-full-screen-chat")) {
						document.querySelector(".skin__frame").classList.add("cention-full-screen-chat");
					}
				}

				CentionChat.connect({
					resume: true,
					area: -1,
					callback: function(msg) {
						if(msg.sessionId) {
							document.getElementById('CentionStartView').style.display = 'none';
							showChatBody();
							document.getElementById('CentionChatView').style.display = 'block';
							if(document.getElementById('CentionChatChannelView')) {
								document.getElementById('CentionChatChannelView').style.display = 'none';
							}
							CentionChatResetBodySize("resumeSession");
							document.getElementById('textInputName').value = msg.clientName;

							//todo @sue check if co browse , if yes, re initiated the co browsing session?
							//console.log("TODO Resume co browse ? ", msg.coBrowse);
							if(msg.coBrowse) {
								resumeCoBrowsing(msg.sessionId);
							}
							if(ccs.feature["chat.attachment"]){
								document.getElementById("CentionChatSendFileButtonWrapper").style.display = "block";
							} else {
								document.getElementById("CentionChatSendFileButtonWrapper").style.display = "none";
							}
						}else {
							console.log("Error resume chat:", msg.error);
							if(document.getElementById('CentionChatChannelView').style.display === 'block'
							&& document.getElementById('CentionChannelView').style.display === 'block') {
								document.getElementById('CentionStartView').style.display = 'none';
							} else {
								document.getElementById('CentionStartView').style.display = 'block';
							}
							document.getElementById('CentionChatView').style.display = 'none';
							hideChatBody();
							const CentionChatBody = document.getElementById('CentionChatBody');
							const CentionChatHeader = document.getElementById('CentionChatHeader');
							if (CentionChatBody.style.display === 'none') {
								if (getCustomUI("position") === "centerLeft") {
									CentionChatHeader.style.left = "-105px";
								} else {
									CentionChatHeader.style.right = "-105px";
								}
							}
						}
					}
				});
				if(statusInterval) {
					clearInterval(statusInterval);
				}
			}
			// This event occures when queue is full.
			// If Cention Contact Center is configured to allow clients to enter a queue and wait for an agent then
			// this event occurs if the maximum amount of chats that can be queued at the same time has been reached.
			CentionChat.registerAction('queue full', function() {
				const AGENT_BUSY = getCustomText("textMessageAgentBusy");
				const agentBzElem = document.createElement('div');
				agentBzElem.className = "system";
				agentBzElem.id = "textMessageAgentBusy";
				setInnerHTMLByElem(agentBzElem, Linker.linkifyUrls(AGENT_BUSY));
				document.getElementById('CentionChatList').appendChild(agentBzElem);
			});

			function toggleElementDisplay(elementId, displayStyle) {
				const element = document.getElementById(elementId);
				if (element) {
					element.style.display = displayStyle;
				}
			}

			function expandFAQChat() {
				toggleElementDisplay("CentionFAQ", "block");
				toggleElementDisplay("trigger-panel-open", "none");
				toggleElementDisplay("trigger-panel-close", "block");
				toggleElementDisplay("ViewPage-Chat", "block");
				toggleElementDisplay("iconReturnPrevious", "none");
				toggleElementDisplay("iconBackHeader", "block");
				if(FAQ_SKIN != SKIN_FRICTIONLESS) {
					toggleElementDisplay("FAQBreadcrumbs", "block");
				}

				if (CentionChatStatus.error) {
					toggleElementDisplay("CentionStartView", "none");
					toggleElementDisplay("CentionChatView", "none");
					toggleElementDisplay("CentionNoAgentsView", "none");

					const centionErrorInfo = document.getElementById("CentionErrorInfo");
					if (centionErrorInfo) {
						centionErrorInfo.style.display = "block";
						centionErrorInfo.innerText = "Error: " + CentionChatStatus.error;
					}
				} else {
					toggleElementDisplay("CentionChatView", "block");
					toggleElementDisplay("CentionNoAgentsView", "none");
					toggleElementDisplay("CentionErrorInfo", "none");
				}

				toggleElementDisplay("ViewPage-Home", "none");
				toggleElementDisplay("FAQStandardContainer", "none");
				toggleElementDisplay("FAQChatContainer", "block");
			}

			function expandConnectedChat() {
				CentionChatResetBodySize("connected");
				showChatBody();

				const toggleChatHeader = document.getElementById("toggleChatHeader");
				if (toggleChatHeader) {
					toggleChatHeader.classList.remove("fa-chevron-up");
					toggleChatHeader.classList.add("fa-chevron-down");
				}

				if (useFAQChatInChat) {
					expandFAQChat();
				}
			}

			const classPrefix = 'Cention';
			function className(name) {
				return classPrefix + name;
			}

			const twoticks = [
				'<span class="' + className('twoticks') + '" style="display:none;margin-left:3px;">',
				'<span id="textTickSent" class="'+ className('message_sent') + ' " style="color:' + getCustomConfig('tickUnsentColor') + ';">' + getCustomText('textTickSent') + '</span>',
				'<span id="textTickRead" class="'+ className('message_read') + ' " style="color:' + getCustomConfig('tickUnreadColor') + ';margin-left:' + getCustomConfig('tickKerningOffset') + ';">' + getCustomText('textTickRead') + '</span>',
				'</span>'
			].join('')
			, colorSent = getCustomConfig('tickSentColor')
			, colorRead = getCustomConfig('tickReadColor')
			, selectorTicksSent = '.' + className('twoticks') + ' .' + className('message_sent')
			, selectorTicksRead = '.' + className('twoticks') + ' .' + className('message_read')
			, selectorTwoTicks = '.' + className('twoticks')
			;

			function markAsRead(je) {
				if(je) {
					je.querySelectorAll(selectorTicksRead).forEach(function(element) {
						markTickRead(element);
					});
				}
			}
			function markTickSent(je) {
				if(je) {
					je.css({color:colorSent});
				}
			}
			function markTickRead(je) {
				if(je) {
					je.css({color:colorRead});
				}
			}

			let isAutoScrolling = true;
			function autoScrollToBottomChatList() {
				if (isAutoScrolling) {
					document.getElementById('CentionChatList').scrollTop = document.getElementById('CentionChatList').scrollHeight;
				}
			}

			function handleUserScroll(container) {
				const scrollThreshold = 50;
				const currentScrollPosition = container.scrollTop + container.clientHeight;
				const bottomPosition = container.scrollHeight;

				// Check if the user has scrolled up
				if (bottomPosition - currentScrollPosition > scrollThreshold) {
					isAutoScrolling = false; // Stop auto-scrolling
				} else {
					isAutoScrolling = true; // Resume auto-scrolling if near the bottom
				}
			}

			function decodeHTMLEntities(text) {
				const parser = new DOMParser();
				const doc = parser.parseFromString(text, 'text/html');
				return doc.documentElement.textContent;
			}

			let chatHistory = [];

			function createErrandLink() {
				const formName = localStorage.getItem("chatName");
				const formEmail = localStorage.getItem("chatEmail");
				const formPhone = localStorage.getItem("chatPhone");
				const formQuestion = localStorage.getItem("chatSubject");
				let subj = formQuestion ? formQuestion.substring(0, 30) : "";

				let currentChatHistory = [];

				chatHistory.forEach(function(msg) {
					if(msg.sessionId === CentionChat.sessionId) {
						if(msg.umid !== "empty-message") {
							currentChatHistory.push(msg);
						}
					}
				});

				let chatHistoryReadable = "";
				currentChatHistory.forEach(function(msg) {
					if(subj === "" && msg.text !== "") {
						if(msg.sender === "CLIENT") {
							subj = msg.text.substring(0, 30);
						}
					}
					let plainChat = msg.text.replace(/<[^>]*>?/gm, '');
					plainChat = decodeHTMLEntities(plainChat);
					if(msg.sender === "AGENT") {
						chatHistoryReadable += msg.agent + ": " + plainChat + "\n";
					} else if(msg.sender === "CLIENT") {
						chatHistoryReadable += formName + ": " + plainChat + "\n";
					}
				});

				const formData = new FormData();
				formData.append("area", CentionChat.areaId);
				formData.append("name", formName);
				formData.append("from", formEmail);
				formData.append("phone", formPhone);
				formData.append("subject", subj);
				formData.append("body", chatHistoryReadable);
				const createErrandURL = baseURL + spacePrefix + "/socket/external.api/createerrand";
				fetch(createErrandURL, {
					method: 'POST',
					body: formData
				})
					.then(response => {
						if (response.ok) {
							return response.json();
						} else {
							throw new Error('Failed to create ticket');
						}
					})
					.then(data => {
						console.log("Ticket created");
						const replyTxt = getCustomText("textContactFormSentNotice");
						const newMsg = {
							text: replyTxt,
							sender: "SYSTEM",
							unsent: false,
							sentHuman: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),
						};
						addNewMessage(newMsg, "", "");
					})
					.catch(error => {
						console.error("Error:", error);
					});
			}

			const streamContainer = document.getElementById('CentionChatList');
			streamContainer.addEventListener('scroll', () => handleUserScroll(streamContainer));

			let msgBuffer = "";
			let chatMsgStreamId = "";
			let listOfFinishedStreams = [];
			const avatarsLoadedForStream = new Set();
				

			function addNewMessage(msg, clientName, agentAvatar, istokenStream) {
				let toAppend = false;
				if(msg.text.includes("{CREATE_ERRAND}")) {
					const iconFontColor = getCustomConfig("headerColor");
					msg.text = msg.text.replace(
						"{CREATE_ERRAND}",
						`<i id='centionCreateErrandLink' style='color:${iconFontColor}' class='icon-mail'></i>`
					);
				}

				let lastStream = false;
				if(msg.streamSession != "") {
					if(msg.sender == "AGENT") {
						lastStream = true;
					}
					if(msg.streamId && !listOfFinishedStreams.includes(msg.streamId)) {
						listOfFinishedStreams.push(msg.streamId);
					}
				} else {
					if(msg.streamId != "") {
						if(listOfFinishedStreams.includes(msg.streamId)) {
							return;
						}
					}
				}

				// Every time a new message arrives, expand the connected chat if it is minimized.
				if (_chatIsMinimized) {
					expandConnectedChat();
				}

				const chatList = document.getElementById('CentionChatList');
				let klass = "text";
				let messageClass = '';
				let id = '';
				let newMsg;
				let msgAvatar;
				const no_image = CloudFrontURL + "/chat/images/no_image.webp";
			
				// Avatar icon selection based on the UI skin
				msgAvatar = `<div class="message-avatar"><i class="${uiSkin === SKIN_FRICTIONLESS ? 'icon-user-filled' : 'icon-user-circle'}"></i></div>`;
			
				// Switching based on the message sender
				switch (msg.sender) {
					case "AGENT":
						sender = msg.agent;
						if (agentAvatar) {
							msgAvatar = `<div class="message-avatar"><img loading="lazy" src="${CentionChat.baseURL}${spacePrefix}${agentAvatar}"></div>`;
						}
						break;
					case "SYSTEM":
						sender = "System";
						break;
					case "CLIENT":
						sender = clientName;
						messageClass = 'fromClient';
						if (clientAvatar) {
							if(clientAvatar && clientAvatar.indexOf(CentionChat.baseURL) === -1) {
								if(clientAvatar && clientAvatar.indexOf('gravatar.com') === -1) {
									clientAvatar = `${CentionChat.baseURL}${clientAvatar}`;
								}
							} else {
								clientAvatar = `${clientAvatar}`;
							}
							msgAvatar = `<div class="message-avatar"><img loading="lazy" src="${clientAvatar}"></div>`;
						}
						break;
					default:
						if (msg.fromClient) {
							messageClass = 'fromClient';
						}
						sender = msg.sender;
				}

				if (msg.text !== null) {
					// Adding unsent message class if the message is unsent
					klass += msg.unsent ? ' unsent-message' : '';

					// Assigning id if available
					id = msg.id ? msg.id : '';

					let msgContent = msg.text;

					//fixme @sue, why when this tag exist in msgContent, <code></code> is added to the message, it become codecode>
					if (msg.streamId) {

						//detect if msgContent contains something like \ud83d\\ude0a and convert it to emoji
						msgContent = msgContent.replace(/\\u([\dA-Fa-f]{4})/g, (match, grp) =>
							String.fromCodePoint(parseInt(grp, 16))
						);

						//if \n or \r detected, convert them into line break
						msgContent = msgContent.replace(/\\n/g, "<br>");
						msgContent = msgContent.replace(/\\r/g, "<br>");

						const existingStreamElement = document.querySelector(`[data-stream-id="${msg.streamId}"]`);
						if (existingStreamElement) {
							//console.log("Already have streamed element, no need to create new one");
						} else {
							newMsg = document.createElement('div');
							newMsg.setAttribute('data-stream-id', msg.streamId);

							const chatLoader = document.getElementById("CentionStreamLoader");
							if(chatLoader) {
								chatLoader.remove();
							}
						}
					} else{
						if(msg.text != "") {
							newMsg = document.createElement('div');
							toAppend = true;
						}
					}

					let partialStream = false;
					let streaming = false;
					if (msg.streamId != "" && msg.streamSession === "") {
						partialStream = true;
					}
					if (lastStream) {
						if(document.querySelector(`[data-stream-id="${msg.streamId}"]`)) {
							newMsg = document.querySelector(`[data-stream-id="${msg.streamId}"]`);
							newMsg.setAttribute('data-stream-session', msg.streamSession);
							streaming = false;
							toAppend = true;
						} else{
							if(!streaming) {
								newMsg = document.createElement('div');
								toAppend = true;
							}
						}
					} else {
						if(partialStream) {
							if(document.querySelector(`[data-stream-id="${msg.streamId}"]`)) {
								newMsg = document.querySelector(`[data-stream-id="${msg.streamId}"]`);
								if (!istokenStream) {
									msgBuffer = msg.text; // If flag is not active, assign msg.text to msgBuffer
								} else {
									msgBuffer += msg.text; // If flag is active, append msg.text to msgBuffer for token streaming
								}
							} else {
								msgBuffer = msg.text;
								streaming = true;
							}
							msgContent = msgBuffer;
							if (msg.streamId) {
								if (!avatarsLoadedForStream.has(msg.streamId)) {
									avatarsLoadedForStream.add(msg.streamId);
									toAppend = true;
								}
							}
						} else {
							toAppend = true;
						}
					}
					if(newMsg) {
						newMsg.className = `message ${messageClass}`;
						if(FAQ_SKIN !== SKIN_FRICTIONLESS) {
							newMsg.innerHTML = `<div class="message-content">
												<div id="${id}" class="${klass}"><span class="textMsg">${Linker.linkifyUrls(msgContent)}</span></div>
												<div class="info"><span class="sender">${sender}</span> &middot; <span id="time-${id}" class="sent">${msg.unsent ? "__:__" : msg.sentHuman}</span></div>
											</div>`;
						} else {
							newMsg.innerHTML = `<div class="message-wrapper">
												<div class="message-content">
													<div id="${id}" class="${klass}"><span class="textMsg">${Linker.linkifyUrls(msgContent)}</span></div>
												</div>
												<div class="info"><span class="sender">${sender}</span> &middot; <span id="time-${id}" class="sent">${msg.unsent ? "__:__" : msg.sentHuman}</span></div>
											</div>`;
						}

						// Appending avatar to the message
						const chatAvatar = document.createElement('div');
						chatAvatar.style.width = "24px";
						chatAvatar.style.margin = "0px 6px";
						if(toAppend) {
							setInnerHTMLByElem(chatAvatar, msgAvatar);
							newMsg.append(chatAvatar);
							chatAvatar.style.width = "unset";
							chatAvatar.style.margin = "unset";
						} else{
							setInnerHTMLByElem(chatAvatar, " ");
							newMsg.appendChild(chatAvatar);
						}

						// Adding ticks and styling based on the sender
						if (msg.sender === "CLIENT") {
							const twoticks = ''; // Define the twoticks variable here
							const infoElement = newMsg.querySelector('.info');
							const twoticksElement = document.createElement('div');
							setInnerHTMLByElem(twoticksElement, twoticks);

							// Check if twoticksElement has any child nodes before appending
							if (twoticksElement.firstChild) {
								infoElement.appendChild(twoticksElement.firstChild);
							}

							if (!msg.unsent) {
								markTickSent(newMsg.querySelector(selectorTicksSent));
							}
							if (msg.read) {
								markTickRead(newMsg.querySelector(selectorTicksRead));
							}
							var twoTicksElements = document.querySelectorAll(selectorTwoTicks);
							if(twoTicksElements) {
								twoTicksElements.forEach(function(element) {
									element.style.display = 'block';
								});
							}
						}

						// Appending the new message to the chat list
						const centionStreamLoader = chatList.querySelector('#CentionStreamLoader');
						if (centionStreamLoader) {
							chatList.insertBefore(newMsg, centionStreamLoader);
						} else {
							chatList.appendChild(newMsg);
						}

						// Setting background color for messages based on the UI skin
						if (msg.urlPreview && !msg.text.includes('<img')) {
							const imageUrl = msg.urlPreview.image === "No image available" ? no_image : msg.urlPreview.image;
							if (imageUrl === no_image) {
								newMsg.querySelector('.message-content')
							}else if (imageUrl != no_image) {
							const previewCard = document.createElement('div');
							previewCard.className = 'url-preview-card';
							previewCard.innerHTML = `
								<div class="url-preview-image">
									<img src="${imageUrl}" alt="404-No image found">
								</div>
								<div class="url-preview-content">
									<div class="url-preview-title">${msg.urlPreview.title}</div>
									<div class="url-preview-description">${msg.urlPreview.description}</div>
								</div>
								<style>
								/* CSS for URL Preview Card */
								.url-preview-card {
									margin-top: 10px;
									border: 1px solid #e0e0e0;
									border-radius: 8px;
									overflow: hidden;
									background-color: white;
									box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
									text-align: center;
								}

								.url-preview-image img {
									padding-top: 10px;
									width: 60%;
									height: auto;
									object-fit: cover;
								}
								.url-preview-content {
									padding: 10px;
								}

								.url-preview-title {
									font-size: 11px;
									font-weight: bold;
									color: #333333;
									margin-bottom: 5px;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.url-preview-description {
									font-size: 10px;
									color: #666666;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
								}
								</style>`;

							newMsg.querySelector('.message-content').appendChild(previewCard);
							}
						
						} else {
							const url = extractUrlFromText(msg.text);
							if (url && !msg.text.includes('<img')) {
								if (isAttachment(url)) {
									// Create file preview card
									const { fileName, fileType, fileSize } = extractFileNameAndSize(msg.text);
									const attachmentIcon = getAttachmentIcon(fileType);

									const filePreviewCard = document.createElement('div');
									filePreviewCard.className = 'file-preview-card';
									filePreviewCard.innerHTML = `
										<div class="file-preview-icon">
											<img src="${attachmentIcon}" alt="${fileType} icon">
										</div>
										<div class="file-preview-content">
											<div class="file-preview-title">${fileName}</div>
											<div class="file-preview-type-size"><i>${fileType}, ${fileSize}</i></div>
										</div>
										<style>
										/* CSS for File Preview Card */
										.file-preview-card {
											margin-top: 10px;
											border: 1px solid #e0e0e0;
											border-radius: 8px;
											overflow: hidden;
											background-color: white;
											box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
											text-align: center;
											display: flex;
											align-items: center;
											padding: 10px;
										}

										.file-preview-icon img {
											width: 40px;
											height: 40px;
										}

										.file-preview-content {
											padding-left: 10px;
											text-align: left;
										}

										.file-preview-title {
											font-size: 12px;
											font-weight: bold;
											color: #333333;
											margin-bottom: 5px;
										}

										.file-preview-type-size {
											font-size: 10px;
											color: #666666;
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: nowrap;
										}
										</style>`;

									newMsg.querySelector('.message-content').appendChild(filePreviewCard);
								} else {
									// Create default URL preview card
									// const imageUrl = no_image;
									// const title = new URL(url).hostname.replace(/^www\./, '').replace(/\.com$/, '').replace(/^\w/, c => c.toUpperCase());
									// const description = url;

									// const previewCard = document.createElement('div');
									// previewCard.className = 'url-preview-card';
									// previewCard.innerHTML = `
									// 	<div class="url-preview-image">
									// 		<img src="${imageUrl}" alt="404-No image found">
									// 	</div>
									// 	<div class="url-preview-content">
									// 		<div class="url-preview-title">${title}</div>
									// 		<div class="url-preview-description">${description}</div>
									// 	</div>
									// 	<style>
									// 	/* CSS for URL Preview Card */
									// 	.url-preview-card {
									// 		margin-top: 10px;
									// 		border: 1px solid #e0e0e0;
									// 		border-radius: 8px;
									// 		overflow: hidden;
									// 		background-color: white;
									// 		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
									// 		text-align: center;
									// 	}

									// 	.url-preview-image img {
									// 		padding-top: 10px;
									// 		width: 60%;
									// 		height: auto;
									// 		object-fit: cover;
									// 	}

									// 	.url-preview-content {
									// 		padding: 10px;
									// 	}

									// 	.url-preview-title {
									// 		font-size: 11px;
									// 		font-weight: bold;
									// 		color: #333333;
									// 		margin-bottom: 5px;
									// 		display: -webkit-box;
									// 		-webkit-line-clamp: 1;
									// 		-webkit-box-orient: vertical;
									// 		overflow: hidden;
									// 		text-overflow: ellipsis;
									// 	}

									// 	.url-preview-description {
									// 		font-size: 10px;
									// 		color: #666666;
									// 		display: -webkit-box;
									// 		-webkit-line-clamp: 3;
									// 		-webkit-box-orient: vertical;
									// 		overflow: hidden;
									// 		text-overflow: ellipsis;
									// 	}
									// 	</style>`;
									newMsg.querySelector('.message-content');
								}
							}
						}
					}
				}

				if (getCustomSkin() == SKIN_FRICTIONLESS && FAQ_SKIN != SKIN_FRICTIONLESS) {
					// here only target text for bg
					document.querySelectorAll("#CentionChatList > div.message > div.message-content >.text > .textMsg").forEach(element => {
						element.style.backgroundColor = getCustomConfig("messageBackgroundColorAgent");
					});
			
					document.querySelectorAll("#CentionChatList > div.message.fromClient > div.message-content > .text > .textMsg").forEach(element => {
						element.style.backgroundColor = getCustomConfig("messageBackgroundColor");
					});
				} else {
					let msgBubbleElem = document.querySelectorAll("#CentionChatList > div.message > div.message-content");
					let msgBubbleClientElem = document.querySelectorAll("#CentionChatList > div.message.fromClient > div.message-content");
					if(FAQ_SKIN == SKIN_FRICTIONLESS ) {
						msgBubbleElem = document.querySelectorAll("#CentionChatList > div.message > div.message-wrapper > div.message-content");
						msgBubbleClientElem = document.querySelectorAll("#CentionChatList > div.message.fromClient > div.message-wrapper > div.message-content");
					}
					msgBubbleElem.forEach(element => {
						element.style.backgroundColor = getCustomConfig("messageBackgroundColorAgent");
					});
					msgBubbleClientElem.forEach(element => {
						element.style.backgroundColor = getCustomConfig("messageBackgroundColor");
					});
				}
			
				document.querySelectorAll("#CentionChatList > div.message > div.message-content .info").forEach(element => {
					element.style.color = getCustomConfig("chatConversationInfoTextColor");
				});
				document.querySelectorAll("#CentionChatList > div.message > div.message-content .sender").forEach(element => {
					element.style.color = getCustomConfig("chatConversationSenderTextColor");
				});
				// Scroll the message list to the bottom
				chatList.scrollTop = chatList.scrollHeight;
			}

			document.addEventListener('click', function(e) {
				e.stopPropagation();
				if (e.target && e.target.id === 'centionCreateErrandLink') {
					if (!e.target.classList.contains('disabled')) {
						createErrandLink();
						//comment line below if we allow user to still create another errand during chat
						//after the first errand is created
						e.target.classList.add('disabled');
					}
				}
			});

			function isValidUrl(string) {
				const urlPattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
				return urlPattern.test(string);
			}
			
			function isAttachment(url) {
				const attachmentExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.zip', '.txt', '.mp4', '.rar', '.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.webm'];
				return attachmentExtensions.some(ext => url.toLowerCase().includes(ext));
			}
			
			function getAttachmentIcon(type) {
				const icons = {
					'PDF': CloudFrontURL + '/chat/images/pdf-icon.png',
					'Word Document': CloudFrontURL + '/chat/images/word-icon.png',
					'Excel Spreadsheet': CloudFrontURL + '/chat/images/excel-icon.png',
					'PowerPoint Presentation': CloudFrontURL + '/chat/images/powerpoint-icon.png',
					'ZIP Archive': CloudFrontURL + '/chat/images/zip-icon.png',
					'RAR Archive': CloudFrontURL + '/chat/images/rar-icon.png',
					'Image': CloudFrontURL + '/chat/images/image-icon.png',
					'MP4 Video': CloudFrontURL + '/chat/images/mp4-icon.png',
					'Text File': CloudFrontURL + '/chat/images/txt-icon.png',
					'File': CloudFrontURL + '/chat/images/file-icon.png'
				};
				return icons[type] || icons['File'];
			}
			
			function getAttachmentType(fileName) {
				const extension = fileName.split('.').pop().toLowerCase();
				const attachmentTypes = {
					'pdf': 'PDF',
					'doc': 'Word Document',
					'docx': 'Word Document',
					'xls': 'Excel Spreadsheet',
					'xlsx': 'Excel Spreadsheet',
					'ppt': 'PowerPoint Presentation',
					'pptx': 'PowerPoint Presentation',
					'zip': 'ZIP Archive',
					'rar': 'RAR Archive',
					'jpg': 'Image',
					'jpeg': 'Image',
					'png': 'Image',
					'gif': 'Image',
					'svg': 'Image',
					'webp': 'Image',
					'webm': 'Image',
					'mp4': 'MP4 Video',
					'txt': 'Text File'
				};
				return attachmentTypes[extension] || 'File';
			}
			
			function extractFileNameAndSize(text) {
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = text;
				const anchor = tempDiv.querySelector('a');
				if (anchor) {
					const fileName = anchor.textContent.replace(/\s*\(.*\)\s*/, '').trim();
					const fileSize = anchor.querySelector('span') ? anchor.querySelector('span').textContent.replace(/[()]/g, '') : 'Unknown size';
					const fileType = getAttachmentType(fileName);
					return { fileName, fileType, fileSize };
				}
				return { fileName: 'Unknown file', fileType: 'File', fileSize: '? Kb' };
			}
			
			function extractUrlFromText(text) {
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = text;
				const anchor = tempDiv.querySelector('a');
				if (anchor && anchor.href) {
					if (!anchor.href.startsWith('mailto:')) {
						return anchor.href;
					}
				}
				return isValidUrl(text) ? text : null;
			}
			
			
			

			function updateChatHeader(agentData) {
				var html;
				if (agentData) {
					if(_chatTitle.length > 23){
						_chatTitle = _chatTitle.substring(0,23) + '...';
					}
					html = _chatTitle+" -<i>"+agentData+"</i>";
				} else {
					html = _chatTitle
				}
				const chatHeaderTxt = document.querySelector('#CentionChatHeader > .text');
				setInnerHTMLByElem(chatHeaderTxt, html);
			}

			function showQueuePosition(pos) {
				const chatList = document.getElementById('CentionChatList');
				const chatQueuePosition = document.getElementById('ChatQueuePosition');
				if (chatQueuePosition) {
					chatList.removeChild(chatQueuePosition);
				}
				if (pos > 0) {
					const queuePosition = document.createElement('div');
					queuePosition.className = 'system';
					queuePosition.id = 'ChatQueuePosition';
					setInnerHTMLByElem(queuePosition, getCustomText("textMessageQueueing") + " " + pos);
					chatList.appendChild(queuePosition);
				}
			}

			if (document.getElementById('CentionChatWelcome') && document.getElementById('CentionChatWelcome').style.display === 'block'
			 && document.getElementById('CentionChatList').style.display === 'block') {
				document.getElementById('CentionChatStatus').style.display = 'block';
				renderChatLoaderHeader();
			} else {
				setElementStyle(document.getElementById('CentionChatStatus'), "display", "none");
			}

			// The "chat message" even occurs both when a new message from the agent has been received
			// and when the client has sent a message. The data structure contains information about
			// the whole chat session and new messages sent by the client and agent.
			CentionChat.registerAction('chat message', function(data) {
				if (data.chat) {
					data.chat.forEach(function(msg) {
						if (msg.umid) {
							if (!chatHistory.some(function(e) { return e.umid === msg.umid; })) {
								if(msg.streamId && msg.streamSession === "") {
									//partial, so skip
								} else {
									msg.sessionId = data.sessionId;
									chatHistory.push(msg);
								}
							}
						}
					});
				}

				// Show agent assigned only if agent responded to the chat
				if (!agentResponded) {
					//change above lines to use native js
					for (var i = 0; i < data.chat.length; i++) {
						if (data.chat[i].aid != 0) {
							agentResponded = true;
							break;
						}
					}
				}
				// Display information about who the client was connected to
				if (agentResponded && data.agent) {
					verifyCookie();
					document.getElementById('CentionChatWelcome').style.display = 'none';
					document.querySelector(".agentConnectedTo").style.display = 'block';
					setInnerHTMLByElem(document.getElementById('CentionChatConnectedAgent'), data.agent);

					updateChatHeader(data.agent);
					//change above lines to use native js
					var connectedTxt = document.getElementById("textStatusConnected").innerText;
					connectedTxt = getCustomText("textStatusConnected")+" "+data.agent+".";
					if(document.querySelector(".vid-remote-name")) {
						document.querySelector(".vid-remote-name").innerText = data.agent;
					}
					const clientName = document.getElementById("textInputName").value || "You";
					if(document.querySelector(".vid-local-name")) {
						document.querySelector(".vid-local-name").innerText = clientName;
					}
					document.getElementById("textStatusConnected").innerText = connectedTxt;
					document.getElementById('CentionChatConnected').style.display = 'block';
					document.getElementById('CentionChatStatus').style.display = 'none';
					if(getCustomUI('chatRatingsEnabled')){
						if(triggerSatisfaction){
							document.getElementById('Satisfaction').style.display = 'block';
						}
					}

					if(CentionChat.haveCookie === "no") {
						// To re-adjust position after the cookie warning msg rendered.
						CentionChatResetBodySize("connected");
					}
					showQueuePosition(0);

					// Send signal to agent to indicate client ready to accept video call / screen sharing
					if(ccs.feature['chat.allow-video-call'] || ccs.feature['chat.agent-video-call'] || ccs.feature['chat.allow-screen-sharing'] || ccs.feature['chat.allow-co-browsing']) {
						//Only request when turndToken empty
						if(turndToken === "" && !isChatWithChatBot) {
							CentionChat.registerVideoCall('video ready', user, function() {
								if(getCustomUI("enableVideoChat") && (ccs.feature['chat.allow-video-call'] || ccs.feature['chat.allow-screen-sharing'] || ccs.feature['chat.allow-co-browsing']) ) {
									document.getElementById("CentionChatVideoCallWrapper").style.display = "block";
								}else {
									document.getElementById("CentionChatVideoCallWrapper").style.display = "none";
								}
							});
						}
					}
				}else {
					// We are connected but agent has yet to respond to it
					document.getElementById('CentionChatStatus').style.display = 'none';
					document.getElementById('CentionChatWelcome').style.display = 'block';
					document.getElementById('CentionChatConnected').style.display = 'none';
					renderChatLoaderHeader();
					updateChatHeader(null);
				}

				//check if agent is chatbot
				if(data.agentIsChatbot) {
					if(!isChatWithChatBot) {
						isChatWithChatBot = true;
						const menuBox = document.querySelector('#CentionToggleableChatMenu');
						menuBox.classList.add("withBot");
						toggleAdvanceMenu(false);
					}
				} else {
					if(isChatWithChatBot) {
						isChatWithChatBot = false;
						toggleAdvanceMenu(true);
					}
				}

				// Remove agent typing notification message from the message list
				const chatList = document.getElementById('CentionChatList');
				const agentTypingElements = chatList.getElementsByClassName('agentTyping');
				for (let i = 0; i < agentTypingElements.length; i++) {
					agentTypingElements[i].parentNode.removeChild(agentTypingElements[i]);
				}

				// Walk through the new messages from the client and the agent
				// and add them to the message list so that they are shown to
				// the client

				//first of all, order data.chat by id
				data.chat.sort(function(a, b) {
					return a.id - b.id;
				});

				data.chat.forEach(function(msg) {
					chatMsgStreamId = msg.streamId != chatMsgStreamId ? msg.streamId : chatMsgStreamId;
					var sender, unsent;
					if (document.getElementById(msg.id)) {
						// This message is already rendered.
						return;
					}
					if (msg.umid) {
						unsent = document.getElementById('unsent-' + msg.umid);
						if (unsent) {
							// This is a message that is sent by client but somehow failed to
							// receive the corresponding 'message sent' acknowledgement. Mark it as sent.
							unsent.setAttribute('id', msg.id);
							unsent.classList.remove('unsent-message');
							setInnerHTMLByElem(unsent.querySelector('.sent'), msg.sentHuman);
							return;
						}
					}
					if (data.clientAvatar) {
						clientAvatar = data.clientAvatar.url;
						var avatarClientOverlay = '<img class="photo" src=' + baseURL + clientAvatar + '>';
						if (clientAvatar) {
							setInnerHTMLByElem(document.getElementById("AvatarOverlayLocal"), avatarClientOverlay);
						}
					}

					var replyAgentAvatar = "";
					if (msg.aid) {
						var listAvatar = data.agentsAvatar;
						if (listAvatar.length > 0) {
							for (var i = 0; i < listAvatar.length; i++) {
								var avt = listAvatar[i];
								if (msg.aid === avt.id) {
									replyAgentAvatar = avt.avatar;
								}
							}
						}
					}
					var avatarAgentPath = "";
					if (replyAgentAvatar) {
						if (avatarAgentPath !== replyAgentAvatar) {
							avatarAgentPath = replyAgentAvatar;
							var avatarAgentOverlay = '<img class="photo" src=' + CentionChat.baseURL + spacePrefix + avatarAgentPath + '>';
							setInnerHTMLByElem(document.getElementById("AvatarOverlayRemote"), avatarAgentOverlay);
						}
					}
					if(msg.umid != "empty-message") {
						addNewMessage(msg, data.client, replyAgentAvatar,data.istokenStream);
					}
				});

			});

			CentionChat.registerAction('message acked', function(msg) {
				for (var i=0;i<msg.ids.length;i++) {
					if (document.getElementById(msg.ids[i])) {
						const parentElement = document.getElementById(msg.ids[i]).parentElement;
						if(parentElement) {
							markAsRead(parentElement);
						}
					}
				}
			});

			// This 'agent preview' event occurs when agent is typing a message. It shows notification message at the client side.
			// Notification shows as a message 'agent is typing a message' in the bottom of the message list.
			CentionChat.registerAction('agent preview', function ( data ) {
				// If message length is zero, remove the notification message.
				if (document.getElementById('CentionChatList').getElementsByClassName('agentTyping').length && data.messageLen === 0) {
					// Remove the notification message if agent is not typing anything.
					const agentTypingElements = document.getElementById('CentionChatList').getElementsByClassName('agentTyping');
					for (let i = 0; i < agentTypingElements.length; i++) {
						agentTypingElements[i].parentNode.removeChild(agentTypingElements[i]);
					}
				}
				if (!document.querySelector('.agentTyping') && data.messageLen > 0) {
					// Append the notification message to message list when agent is typing a message.
					var agentTypingDiv = document.createElement('div');
					agentTypingDiv.className = 'agentTyping';
					agentTypingDiv.textContent = CentionChat.I("Agent is typing a message...");
					document.getElementById('CentionChatList').appendChild(agentTypingDiv);

					// When agent starts typing, use it to determine agent assigned and attending to it
					agentResponded = true;
					document.getElementById('CentionChatWelcome').style.display = 'none';
					document.getElementById('CentionChatConnected').style.display = 'block';
				}
				// Scroll the message list to the bottom
				var chatList = document.getElementById('CentionChatList');
				chatList.scrollTop = chatList.scrollHeight;
			});

			// The "queue" event occurs when the client's position in the queue changes.
			CentionChat.registerAction('queue', function(n) {
				setInnerHTMLByElem(document.getElementById('CentionChatStatus'), "");
				document.getElementById('CentionChatWelcome').style.display = 'block';
				document.getElementById('textMessageCurrentQueue').innerText = getCustomText("textMessageCurrentQueue")+" "+n;
				showQueuePosition(n);
			});
			// The "finish chat session" event occurs if the client disconnects, the agent ends
			// the chat or if the chat expires due to inactivity.
			// Inactivity is determined by when the last message was sent.
			CentionChat.registerAction('finish chat session', function(data) { // data.closedBy = client, agent or expired
				var chatEndedElem = document.createElement('div');
				chatEndedElem.className = "system chatEnded";
				chatEndedElem.id = "textMessageChatEnded";
				setInnerHTMLByElem(chatEndedElem, getCustomText("textMessageChatEnded"));
				deactivateChatUI();
			});
			// The 'agent unavailable' occurs when no active agent for chat
			CentionChat.registerAction('agent unavailable', function() {
				var chatEndedElem = document.createElement('div');
				chatEndedElem.className = "system chatEnded";
				chatEndedElem.id = "textMessageChatEnded";
				setInnerHTMLByElem(chatEndedElem, getCustomText("textMessageChatEnded"));
				deactivateChatUI();
			});

			CentionChat.registerAction('message sent', function(um) {
				var div = document.getElementById('unsent-'+um.umid)
				, je
				;
				if (div) {
					div.setAttribute('id', um.id);
					document.getElementById(um.id).classList.remove('unsent-message');
					je = div.parentNode;
					const sentTxt = document.getElementById('time-' + um.id);
					if(sentTxt) {
						sentTxt.forEach(element => {
							element.innerHTML = um.sentHuman;
						});
					} else {
						const sentTxt = document.getElementById('time-unsent-' + um.umid);
						if(sentTxt) {
							sentTxt.innerHTML = um.sentHuman;
						}
					}
					markTickSent(je.querySelector(selectorTicksSent));
				}
			});

			/******************************/
			/* Regster DOM event handlers */
			/******************************/

			// Register even handler for when the chat header
			// is clicked which will either show or hide
			// the chat.

			function hideChatLoader() {
				document.getElementById('CentionChatBody').style.backgroundImage = "";
			}

			function renderChatLoaderHeader() {
				const iconSpinner = "icon-spinner rotating";
				const chatHeaderTxt = document.querySelector('#CentionChatHeader > .text');
				setInnerHTMLByElem(chatHeaderTxt, `<i class="${iconSpinner}"></i> `+getCustomText("textStatusConnecting"));
			}

			function renderChatNotOpen(status, agentsOnline) {
				retryConn(false);
				const getPosition = getCustomUI("position");
				const toggleChatHeader = document.getElementById('toggleChatHeader');
				if(toggleChatHeader) {
					toggleChatHeader.classList.remove("fa-chevron-up");
					toggleChatHeader.classList.add("fa-chevron-down");
				}

				const selectAreaId = document.getElementById("selectAreaId");
				const selectAreaIdOpts = document.getElementById("selectAreaIdOpts");
				const textInput = document.getElementById("textInputName");

				if(!status){
					if(CentionChatUseDynamicArea) {
						// Check if both elements exist before proceeding
						if (selectAreaId && textInput) {
							textInput.parentNode.insertBefore(selectAreaId, textInput);
						} else {
							console.error("Either selectAreaId or textInputName element not found.");
						}
					}
				}else{
					const centionChatRetryWrapper = document.getElementById("CentionChatRetryWrapper");
					if(CentionChatUseDynamicArea) {
						//insert selectAreaId right before centionChatRetryWrapper
						if (selectAreaId) {
							centionChatRetryWrapper.parentNode.insertBefore(selectAreaId, centionChatRetryWrapper);
						} else if (selectAreaIdOpts){
							centionChatRetryWrapper.parentNode.insertBefore(selectAreaIdOpts, centionChatRetryWrapper);
						} else {
							console.error("Either selectAreaId or selectAreaIdOpts element not found.");
						}
					}
				}

				if(getPosition == "bottomRight" || getPosition == "bottomLeft" ) {
					hideChatLoader();
					showChatBody();
					document.getElementById('CentionStartView').style.display = 'none';
					document.getElementById('CentionChatView').style.display = 'none';

					if(agentsOnline <= 0 || typeof agentsOnline == "undefined"){
						document.getElementById('CentionNoAgentsView').style.display = 'block';
						if(document.getElementById('CentionChatChannelView')) {
							document.getElementById('CentionChatChannelView').style.display = 'none';
						}
						removeCreateErrandPlaceholder();
					}else {
						document.getElementById('CentionStartView').style.display = 'block';
					}
					document.getElementById("textMessageAway").innerText = getCustomText("textMessageAway");
					removeCreateErrandPlaceholder();
					if(getCustomUI("buttonAwayEnable") && !getCustomUI("showContactFormWhenAway")){
						document.querySelector('div > a#textButtonAway').style.display = 'block';
					}else {
						document.querySelector('div > a#textButtonAway').style.display = 'none';
					}
					CentionChatResetBodySize("disconnected");
				}else {
					hideChatLoader();
					//should expand left or right

					document.getElementById("CentionStartView").style.display = "none";
					document.getElementById("CentionChatView").style.display = "none";
					setElementStyle(document.getElementById("CentionChatChannelView"), "display", "none");
					document.getElementById("CentionNoAgentsView").style.display = "block";
					document.getElementById("textMessageAway").innerText = getCustomText("textMessageAway");
					removeCreateErrandPlaceholder();
					if(getCustomUI("buttonAwayEnable") && !getCustomUI("showContactFormWhenAway")){
						document.querySelector('div > a#textButtonAway').style.display = 'block';
					} else {
						document.querySelector('div > a#textButtonAway').style.display = 'none';
					}
					CentionChatResetBodySize("disconnected");
				}
				if (getCustomUI("showContactFormWhenAway")) {
					document.getElementById("CentionNoAgentsView").classList.add("showForm");
					document.getElementById("CentionContactForm").style.display = "block";
					document.getElementById("CentionChatRetryWrapper").style.display = "none";
					removeCreateErrandPlaceholder();
				}
				if(document.getElementById("CentionChatProactiveDialog")) {
					document.getElementById("CentionChatProactiveDialog").style.display = "none";
				}
				if(document.getElementById("CentionProactiveButton")) {
					document.getElementById("CentionProactiveButton").style.display = "none";
				}
			}

			function verifyCookie(num) {
				if(getCustomUI("cookieWarning")){
					//verify cookie usage
					if (CentionChat.haveCookie === "no") {
						const cookieWarning = getCustomText("textCookieWarning").replace("{CENTION_BASE_URL}", baseURL);
						document.querySelector("#CentionCookieWarning > .cookieText").textContent = cookieWarning;
						document.getElementById("CentionCookieWarning").style.display = "block";
						cookieDisabled = true;
					} else {
						document.getElementById("CentionCookieWarning").style.display = "none";
					}
				}
			}

			function runWidgetMinimizeHooks(hide){
				if(hide) {
					if(params.hooks && params.hooks.onChatMinimize) {
						params.hooks.onChatMinimize();
					}
				}else{
					if(params.hooks && params.hooks.onChatMaximize) {
						params.hooks.onChatMaximize();
					}
				}
			}

			function showChatBody() {
				if (getComputedStyle(document.getElementById('CentionChatBody')).display === 'none') {
					document.getElementById('CentionChatBody').style.display = 'block';
					runWidgetMinimizeHooks(false);
				}
				if(document.querySelector('.skin__frame')) {
					document.querySelector('.skin__frame').classList.add('maximized');
					document.querySelector('.skin__frame').classList.remove('minimized');
				}

				if (document.getElementById('CentionChatHeader')) {
					document.getElementById('CentionChatHeader').classList.add('maximized');
					document.getElementById('CentionChatHeader').classList.remove('minimized');
				}
				document.getElementById('askEULA').checked = false;
				if(document.getElementById('CentionProactiveButton')){
					document.getElementById('CentionProactiveButton').style.display = 'none';
				}
				if (CentionChat.sessionId !== "" && getCustomUI("proactiveImmediateStart")) {
					setElementStyle(document.getElementById("CentionStartView"), "display", "none");
				}
			}

			function hideChatBody() {
				if (getComputedStyle(document.getElementById('CentionChatBody')).display !== 'none') {
					document.getElementById('CentionChatBody').style.display = 'none';
					runWidgetMinimizeHooks(true);
				}
				document.getElementById('CentionChatHeader').classList.add('minimized');
				document.getElementById('CentionChatHeader').classList.remove('maximized');

				if(document.querySelector('.skin__frame')) {
					document.querySelector('.skin__frame').classList.add('minimized');
					document.querySelector('.skin__frame').classList.remove('maximized');
				}
			}

			function renderChatOpen(retry){
				verifyCookie();
				const getPosition = getCustomUI("position");
				if(document.getElementById("toggleChatHeader")) {
					document.getElementById("toggleChatHeader").classList.remove("fa-chevron-up");
					document.getElementById("toggleChatHeader").classList.add("fa-chevron-down");
				}

				if(!cookieDisabled) {
					if(localStorage.getItem("chatName") != ""){
						if(document.getElementById("textInputName")) {
							document.getElementById("textInputName").value = localStorage.getItem("chatName");
						}
					}
					if(localStorage.getItem("chatEmail") != ""){
						if(document.getElementById("textInputEmail")) {
							document.getElementById("textInputEmail").value = localStorage.getItem("chatEmail");
						}
					}
				}

				hideChatLoader();
				if(getPosition == "bottomRight" || getPosition == "bottomLeft" ) {
					showChatBody();
					if(CentionChat.sessionId == "" ) {
						if(document.getElementById("CentionChatView") && document.getElementById("CentionChatView").style.display !== "none") {
							document.getElementById("CentionStartView").style.display = "none";
						} else {
							if(retry) {
								setElementStyle(document.getElementById("CentionChatView"), "display", "block");
								setElementStyle(document.getElementById("CentionStartView"), "display", "none");
							} else {
								if((document.getElementById("CentionChatChannelView") && document.getElementById("CentionChatChannelView").style.display !== "none") ||
									(document.getElementById("CentionChannelView") && document.getElementById("CentionChannelView").style.display !== "none")) {
									setElementStyle(document.getElementById("CentionStartView"), "display", "none");
								} else {
									setElementStyle(document.getElementById("CentionStartView"), "display", "block");
								}
								setElementStyle(document.getElementById("CentionChatView"), "display", "none");
							}
						}
					}else {
						setElementStyle(document.getElementById("CentionChatView"), "display", "block");
					}
					if(retry == "reconnecting" && CentionChat.sessionId) {
						setElementStyle(document.getElementById("CentionChatStatus"), "display", "block");
						setElementStyle(document.getElementById("CentionChatConnected"), "display", "none");
					}
					setElementStyle(document.getElementById("CentionNoAgentsView"), "display", "none");
					CentionChatResetBodySize("disconnected");
				}else {
					showChatBody();
					if(CentionChat.sessionId == "") {
						if(document.getElementById("CentionChatView") && document.getElementById("CentionChatView").style.display !== "none") {
							document.getElementById("CentionStartView").style.display = "none";
						} else {
							const CentionChatChannelView = document.getElementById("CentionChatChannelView");
							const CentionChannelView = document.getElementById("CentionChannelView");
							if((CentionChatChannelView && CentionChatChannelView.style.display !== "none") || (CentionChannelView && CentionChannelView.style.display !== "none")) {
								document.getElementById("CentionStartView").style.display = "none";
							} else {
								document.getElementById("CentionStartView").style.display = "block";
							}
						}
					}else {
						setElementStyle(document.getElementById("CentionChatView"), "display", "block");
					}
					if(retry == "reconnecting" && CentionChat.sessionId) {
						setElementStyle(document.getElementById("CentionChatStatus"), "display", "block");
						setElementStyle(document.getElementById("CentionChatConnected"), "display", "none");
					}
					setElementStyle(document.getElementById("CentionNoAgentsView"), "display", "none");
					CentionChatResetBodySize("connected");
				}
				if (CentionChatUseDynamicArea) {
					var selectAreaId = document.getElementById("selectAreaId");
					var textInputName = document.getElementById("textInputName");
					textInputName.parentNode.insertBefore(selectAreaId, textInputName);
				}
				if(document.getElementById("CentionChatProactiveDialog")) {
					document.getElementById("CentionChatProactiveDialog").style.display = "none";
				}
				if(document.getElementById("CentionProactiveButton")) {
					document.getElementById("CentionProactiveButton").style.display = "none";
				}
			}

			function retryConn(status) {
				if (!status) {
					setInnerHTMLByElem(document.getElementById("RetryLoader"), "<img src=" + _preloaderURL + " />");
					document.getElementById("RetryLoader").style.display = "block";
					document.getElementById("textButtonRetry").style.display = "none";

					window.setTimeout(function() {
						document.getElementById("RetryLoader").style.display = "none";
						document.getElementById("textButtonRetry").style.display = "block";
					}, 3000);
				}
			}

			function retryChat(retry) {
				//Calling canChat here is to check whether there's
				//available agents or chat is open
				//whenever chat customer is clicking chat widget header
				//to initiate chat session.

				//This only triggered when chat session not started
				//by clicking widget header
				//or clicking "Retry" button

				//The main purpose was to check if chat is available
				//before need to reloaded the page.
				CentionChat.canChat(function(o) {
					renderChatNotOpen(retry, o.agentsAvailable);
					if (o.error) {
						// It is not possible to start a chat
						if(CentionChat.sessionId) {
							renderChatOpen("reconnecting");
						}else {
							renderChatNotOpen("chatError");
						}
						if(retry) {
							retryConn(false);
						}
						return;
					}

					if (!o.chatOpen) {
						// Chat is not open (outside of working hours)
						renderChatNotOpen("chatClosed");
						if(retry) {
							retryConn(false);
						}
						return;
					}

					if (o.agentsAvailable <= 0) {
						// No agents available
						renderChatNotOpen("agentsNotAvailable");
						if(retry) {
							retryConn(false);
						}
						return;
					}

					if (o.agentsAvailable > 0) {
						renderChatOpen(false);
					}
				}, CentionChat.areaId, true);
			}

			// Set selected areaId from areaId list provided
			document.body.addEventListener('change', function (event) {
				if (event.target && event.target.id === 'selectAreaId') {
					const areaID = parseInt(event.target.value);
					CentionChat.areaId = areaID;
					retryChat(false);
					getAgentList(areaID);
				}
			});

			var centionAreaSelectors = document.getElementsByClassName("centionAreaSelector");
			for (var i = 0; i < centionAreaSelectors.length; i++) {
				centionAreaSelectors[i].addEventListener('change', function () {
					CentionChat.areaId = parseInt(this.value);
					retryChat(false);
				});
			}

			var proactiveOffers = 0;
			var proactiveChatOfferTimeout = 0;
			function scheduleProactiveOffer() {
				setTimeout(function() {
					if (document.getElementById('CentionChatBody').style.display !== 'none') {
						scheduleProactiveOffer();
					} else {
						//Calling canChat here is to check whether there's
						//available agents or chat is open
						//to make sure proactive offer popup
						//only appear if agents available and chat is open
						CentionChat.canChat(function(o) {
							if (o.resumable) {
								scheduleProactiveOffer();
							} else {
								if (o.chatOpen && o.agentsAvailable > 0) {
									proactiveOffers++;
									setElementStyle(document.getElementById("CentionChatProactiveDialog"), "display", "block");
									setElementStyle(document.getElementById("CentionProactiveButton"), "display", "flex");
									setElementStyle(document.getElementById("CentionChatHeader"), "display", "none");
									proactiveChatOfferTimeout = setTimeout(function() {
										setElementStyle(document.getElementById("CentionChatProactiveDialog"), "display", "none");
										setElementStyle(document.getElementById("CentionProactiveButton"), "display", "none");
										setElementStyle(document.getElementById("CentionChatHeader"), "display", "flex");
										if (proactiveOffers < getCustomUI("proactiveRepeatedContact")) {
											scheduleProactiveOffer();
										}
									}, getCustomUI("proactiveTimeout") * 1000);
								} else {
									scheduleProactiveOffer();
								}
							}
						}, CentionChat.areaId, false);
					}
				}, (proactiveOffers == 0
					? getCustomUI("proactiveDelayTime") * 1000
					: getCustomUI("proactiveTimeAfterDecline") * 1000));
			}

			if (getCustomUI("proactiveEnable")) {
				if(CentionChat.sessionId == ""){
					scheduleProactiveOffer();
				}
			}

			const proactiveBtnLater = document.getElementById("textButtonProactiveLater");
			if(proactiveBtnLater) {
				proactiveBtnLater.addEventListener("click", function(e) {
					e.preventDefault();
					setElementStyle(document.getElementById("CentionChatProactiveDialog"), "display", "none");
					setElementStyle(document.getElementById("CentionProactiveButton"), "display", "none");
					setElementStyle(document.getElementById("CentionChat"), "display", "block");
					setElementStyle(document.getElementById("CentionChatHeader"), "display", "flex");
					if (proactiveOffers < getCustomUI("proactiveRepeatedContact")) {
						clearTimeout(proactiveChatOfferTimeout);
						proactiveChatOfferTimeout = 0;
						scheduleProactiveOffer();
					}
				});
			}

			var proactiveElements = document.querySelectorAll("#CentionProactiveButton, #CentionChatProactiveDialog");
			proactiveElements.forEach(function(element) {
				element.addEventListener("click", function(e) {
					e.preventDefault();

					if(chat_config_full_screen_mobile && isChatMobileDeviceSize) {
						if (!document.querySelector(".skin__frame").classList.contains("cention-full-screen-chat")) {
							document.querySelector(".skin__frame").classList.add("cention-full-screen-chat");
						}
					}

					CentionChat.resetChat();
					agentResponded = false;
					clearTimeout(proactiveChatOfferTimeout);
					proactiveChatOfferTimeout = 0;
					setElementStyle(document.getElementById("CentionChatProactiveDialog"), "display", "none");
					setElementStyle(document.getElementById("CentionProactiveButton"), "display", "none");
					setElementStyle(document.getElementById("CentionChat"), "display", "block");
					setElementStyle(document.getElementById("CentionChatHeader"), "display", "flex");
					setElementStyle(document.getElementById("Satisfaction"), "display", "none");
					activateChatUI();
					if (!document.getElementById("CentionChatBody").offsetParent) {
						CentionChatResetBodySize();
						showChatBody();
						if (document.getElementById("CentionChatChannelView").offsetParent) {
							setElementStyle(document.getElementById("CentionStartView"), "display", "none");
						} else {
							setElementStyle(document.getElementById("CentionStartView"), "display", "block");
						}
					}

					if (getCustomUI("proactiveImmediateStart")) {
						showChatBody();
						setElementStyle(document.getElementById("CentionStartView"), "display", "none");
						setElementStyle(document.getElementById("CentionChatChannelView"), "display", "none");
						setElementStyle(document.getElementById("CentionChatView"), "display", "block");
						var inputs = document.querySelectorAll('#CentionStartView > input');
						inputs.forEach(function(input) {
							if (input.type !== 'button') {
								input.classList.remove("warning");
							}
						});
						var textareas = document.querySelectorAll('#CentionStartView > textarea');
						textareas.forEach(function(textarea) {
							textarea.classList.remove("warning");
						});
						if(document.getElementById("textStatusConnecting")) {
							document.getElementById("textStatusConnecting").innerText = getCustomText("textStatusConnecting");
						}
						document.getElementById("CentionChatList").querySelectorAll('.chatEnded').forEach(function(el) {
							el.remove();
						});
						document.querySelectorAll(".agentConnectedTo").forEach(function(el) {
							el.style.display = "none";
						});
						document.getElementById("CentionChatList").querySelectorAll('.message').forEach(function(el) {
							el.remove();
						});
						const clientName = "anonymous-" + Math.random().toString(36).substring(2, 5);
						renderChatLoaderHeader();
						CentionChat.connect({
							area: CentionChat.areaId,
							name: clientName,
							email: clientName,
							message: "",
							externalData: "",
							clientDeviceInfo: {
								browserName: browserName ? browserName : '',
								browserVersion: browserVersion ? browserVersion : '',
								deviceVendor: deviceVendor ? deviceVendor : '',
								deviceModel: deviceModel ? deviceModel : '',
								deviceType: deviceType ? deviceType : '',
								osName: osName ? osName : '',
								osVersion: osVersion ? osVersion : '',
								ua: UA ? UA : '',
								isMobile: isMobile(UA)
							}
						});
						//update localstorage for email and name for anon user
						localStorage.setItem("chatName", clientName);
						localStorage.setItem("chatEmail", clientName);
						if (params.hooks && params.hooks.onChatStarted) {
							params.hooks.onChatStarted();
						}
						setElementStyle(document.getElementById("CentionStartView"), "display", "none");
						setElementStyle(document.getElementById("CentionChatStatus"), "display", "block");
						setElementStyle(document.getElementById("CentionChatView"), "display", "block");
						setElementStyle(document.getElementById("CentionChatFinishButtonWrapper"), "display", "block");
						if (ccs.feature["chat.attachment"]) {
							document.querySelectorAll("[id*='CentionChatSendFileButtonWrapper']").forEach(function(el) {
								el.style.display = "block";
							});
						} else {
							document.querySelectorAll("[id*='CentionChatSendFileButtonWrapper']").forEach(function(el) {
								el.style.display = "none";
							});
						}
						setElementStyle(document.getElementById("CentionNewChatButtonWrapper"), "display", "none");
						CentionChatResetBodySize("connected");
						if (statusInterval) {
							clearInterval(statusInterval);
						}
					}
				});
			});
			if(params.faqWidgetCfg == ""){ 
			// This is to select elements
			const toggleIcon = document.getElementById("iconExpand");
			const chatBody = document.getElementById("CentionChatBody");
			const chatHeader = document.getElementById("CentionChatHeader");
			const startView = document.getElementById("CentionStartView");
			const chatView = document.getElementById("CentionChatList");
			const chatQuestion = document.getElementById("CentionChatQuestion");

			// This is to store original dimensions and styles
			const originalDimensions = {
				startViewHeight: startView.style.height,
				startViewWidth: startView.style.width,
				chatViewHeight: chatView.style.height,
				chatViewWidth: chatView.style.width,
				chatBodyHeight: chatBody.style.height,
				chatBodyWidth: chatBody.style.width,
				chatHeaderMaxWidth: chatHeader.style.maxWidth,
				chatQuestionWidth: chatQuestion.style.width
			};

			let chatFootWrapper, originalFootWrapperWidth;

			// This is to determine the widget and frame width based on skin type
			let widget, frameWidth;
			switch (params.chatWidgetCfg.skin) {
				case SKIN_FRICTIONLESS:
					widget = document.getElementById("Skin-Frictionless");
					break;
				case SKIN_MODERN:
					widget = document.getElementById("Skin-Modern");
					chatFootWrapper = document.querySelector("#Skin-Modern #CentionChatFooter .textArea-wrapper");
					originalFootWrapperWidth = chatFootWrapper.style.width;
					originalDimensions.chatFootWrapperWidth = originalFootWrapperWidth;
					break;
				case SKIN_DEFAULT:
					widget = document.getElementById("Skin-Default");
					break;
			}
			frameWidth = widget?.style.width;

			// This is to expand and collapse functionality
			toggleIcon.addEventListener("click", function (e) {
				e.stopPropagation();

				const isExpanded = toggleIcon.classList.contains("icon-expand");
				
				switch (params.chatWidgetCfg.skin) {
					case SKIN_FRICTIONLESS:
						if (isExpanded) {
							expandFrictionless();
						} else {
							collapseToOriginal();
						}
						break;
					case SKIN_MODERN:
						if (isExpanded) {
							expandModern();
						} else {
							collapseToOriginal();
						}
						break;
					case SKIN_DEFAULT:
						if (isExpanded) {
							expandDefault();
						} else {
							collapseToOriginal();
						}
						break;
				}

				toggleIcon.classList.toggle("icon-expand");
				toggleIcon.classList.toggle("icon-collapse");
			});

			// This is to expansion and collapse functions for each skin type
			function expandFrictionless() {
				chatBody.style.height = "85vh";
				chatBody.style.width = "64vw";
				chatBody.style.transition = "0s";
				startView.style.height = "75vh";
				startView.style.width = "64vw";
				chatView.style.height = "68vh";
				chatView.style.width = "62vw";
				chatView.style.transition = "0s";
				widget.style.width = "64vw";
				chatHeader.style.setProperty("max-width", "100%", "important");
			}

			function expandModern() {
				chatHeader.style.setProperty("max-width", "100%", "important");
				chatBody.style.height = "85vh";
				chatBody.style.width = "40vw";
				startView.style.height = "75vh";
				startView.style.width = "39vw";
				chatView.style.height = "70vh";
				chatView.style.width = "38vw";
				chatQuestion.style.setProperty("width", "35vw", "important"); //NOTES: do not uses 'important',find better way, uses class maybe
				if (chatFootWrapper) {
					chatFootWrapper.style.setProperty("width", "35vw", "important");
				}
			}

			function expandDefault() {
				chatBody.style.height = "85vh";
				chatBody.style.width = "43vw";
				startView.style.height = "70vh";
				startView.style.width = "42vw";
				chatView.style.height = "66vh";
				chatView.style.width = "42vw";
				chatQuestion.style.setProperty("width", "35vw", "important");
				chatHeader.style.setProperty("max-width", "100%", "important");
			}

			function collapseToOriginal() {
				widget.style.width = frameWidth;
				chatHeader.style.maxWidth = originalDimensions.chatHeaderMaxWidth;
				chatBody.style.height = originalDimensions.chatBodyHeight;
				chatBody.style.width = originalDimensions.chatBodyWidth;
				startView.style.height = originalDimensions.startViewHeight;
				startView.style.width = originalDimensions.startViewWidth;
				chatView.style.height = originalDimensions.chatViewHeight;
				chatView.style.width = originalDimensions.chatViewWidth;
				chatQuestion.style.width = originalDimensions.chatQuestionWidth;
				if (params.chatWidgetCfg.skin === SKIN_MODERN && chatFootWrapper) {
					chatFootWrapper.style.width = originalFootWrapperWidth;
				}
			}
		}




			if(document.querySelector(".centionChatTrigger")) {
				document.querySelector(".centionChatTrigger").addEventListener("click", function(e){
					e.preventDefault();
					toggleChatWindow();
				});
			}

			function toggleChatWindow() {
				const hasAgent = ccs.agentsAvailable;
				const getPosition = getCustomUI("position");
				if (document.getElementById("CentionChatBody") && document.getElementById("CentionChatBody").style.display !== "none") {
					if(chat_config_full_screen_mobile && isChatMobileDeviceSize) {
						document.querySelector(".skin__frame").classList.remove("cention-full-screen-chat");
					}
					const toggleChatHeader = document.getElementById('toggleChatHeader');
					if(toggleChatHeader) {
						toggleChatHeader.classList.add("fa-chevron-up");
						toggleChatHeader.classList.remove("fa-chevron-down");
					}
					if (getPosition === "bottomRight" || getPosition === "bottomLeft") {
						hideChatBody();
						setElementStyle(document.getElementById("CentionChatHeader"), "width", "");
					} else {
						CentionChatResetBodySize("disconnected");
					}
					_chatIsMinimized = true;
					if (useCustomChatTrigger) {
						setElementStyle(document.getElementById("CentionChatHeader"), "display", "none");
					}
					if (statusInterval) {
						clearInterval(statusInterval);
					}
				} else {
					if (ccs.resumable) {
						renderChatOpen(true);
						if (statusInterval) {
							clearInterval(statusInterval);
						}
						if(chat_config_full_screen_mobile && isChatMobileDeviceSize) {
							if (!document.querySelector(".skin__frame").classList.contains("cention-full-screen-chat")) {
								document.querySelector(".skin__frame").classList.add("cention-full-screen-chat");
							}
						}
					} else {
						if(chat_config_full_screen_mobile && isChatMobileDeviceSize) {
							if (!document.querySelector(".skin__frame").classList.contains("cention-full-screen-chat")) {
								document.querySelector(".skin__frame").classList.add("cention-full-screen-chat");
							}
						}
						CentionChatResetBodySize("init");
						showChatBody();
						if (CentionChat.sessionId === "") {
							retryChat(false, hasAgent);
							if(document.getElementById("agentAvatarList") && document.getElementById("agentAvatarList").style.display !== "none") {
								intervalCheckAvailability(true);
							}
						} else {
							renderChatOpen(true);
							if (statusInterval) {
								clearInterval(statusInterval);
							}
						}
					}
					if (document.getElementById("CentionChatStatus").offsetParent) {
						retryChat(true);
					}
					_chatIsMinimized = false;
				}
			}

			if(document.getElementById("chatWithUs")) {
				document.getElementById("chatWithUs").addEventListener("click", function(){
					setElementStyle(document.getElementById("CentionStartView"), "display", "block");
					setElementStyle(document.getElementById("CentionChatChannelView"), "display", "none");

					if(FAQ_SKIN == SKIN_FRICTIONLESS ) {
						setElementStyle(document.getElementById("agentAvatarList"), "display", "flec");
					}
				});
			}

			if(document.getElementById("CentionChatAudioCallButton")) {
				document.getElementById("CentionChatAudioCallButton").addEventListener("click", function(){
					const callbackOpt = document.getElementById("CentionCallbackList");
					callbackOpt.style.display = "block";
					if(callbackOpt && document.getElementById("CentionChatList")) {
						document.getElementById("CentionChatList").appendChild(callbackOpt);
					}
					setElementStyle(document.getElementById("CentionWhereToCall"), "display", "block");
					setElementStyle(document.getElementById("CentionScheduleCall"), "display", "none");
					// Assigning custom text
					setTextContentById("CentionMsgWhereToCallTxt", getCustomText("textMsgWhereToCall"));
					setTextContentById("CentionMsgToCall", getCustomText("textMsgToCall"));

					if(document.getElementById("CentionCallPhoneNow")) {
						document.getElementById("CentionCallPhoneNow").value = getCustomText("textMsgCallNow");
					}
					if(document.getElementById("CentionCallScheduleLater")) {
						document.getElementById("CentionCallScheduleLater").value = getCustomText("textMsgCallSchedule");
					}
					if(document.getElementById("CentionMsgSend")) {
						document.getElementById("CentionMsgSend").value = getCustomText("textBtnSend");
					}
					if(document.getElementById("CentionMsgCancel")) {
						document.getElementById("CentionMsgCancel").value = getCustomText("textBtnCancel");
					}

					scrollToBottom();
				});
			}

			if(document.getElementById("CentionCallScheduleLater")) {
				document.getElementById("CentionCallScheduleLater").addEventListener("click", function(){
					setElementStyle(document.getElementById("CentionScheduleCall"), "display", "block");
					scrollToBottom();
				});
			}

			if(document.getElementById("CentionCallPhoneNow")) {
				document.getElementById("CentionCallPhoneNow").addEventListener("click", function(){
					let phone = "";
					if(localStorage.getItem("chatPhone") !== ""){
						phone = localStorage.getItem("chatPhone");
					}
					callNowNotif();
				});
			}

			function intervalCheckAvailability(withRetry) {
				if(checkStatusRealTime && !getCustomUI("hideAgentAvatarList")) {
					statusInterval = setInterval(function(){
						getAgentList(CentionChat.areaId);
						if(withRetry) {
							retryChat(false);
						}
					}, checkStatusInterval);
				}
			}

			function callNowNotif() {
				const data = { phone: localStorage.getItem("chatPhone") };
				CentionChat.registerCallAction('CALLBACK_REQUEST_NOW', data, function() {
					console.log("call now requested");
				});
			}

			function callLaterNotif(datetime) {
				const data = { phone: localStorage.getItem("chatPhone"), datetime: datetime };
				CentionChat.registerCallAction('CALLBACK_REQUEST_SCHEDULE', data, function() {
					console.log("call scheduled requested");
				});
			}

			//scroll to call options
			function scrollToBottom() {
				const objDiv = document.getElementById("CentionChatList");
				objDiv.scrollTop = objDiv.scrollHeight;
			}

			//send the callback request
			if(document.getElementById('CentionMsgSend')) {
				document.getElementById('CentionMsgSend').addEventListener('click', function(e) {
					if (!isValidDate) {
						return false;
					}
					const formName = localStorage.getItem("chatName");
					const formEmail = localStorage.getItem("chatEmail");
					const formPhone = localStorage.getItem("chatPhone");
					const formQuestion = localStorage.getItem("chatSubject");
					const subj = formQuestion.substring(0, 30);
					const createErrandURL = baseURL + spacePrefix + "/socket/external.api/createerrand";
					const xhr = new XMLHttpRequest();
					xhr.open('POST', createErrandURL);
					xhr.setRequestHeader('Content-Type', 'application/json');
					xhr.onload = function() {
						if (xhr.status === 200) {
							callLaterNotif(globalSelectedDate);
						} else {
							console.log("Error", xhr.statusText);
						}
					};
					xhr.onerror = function() {
						console.error("Request failed");
					};
					const formData = new FormData();
					formData.append("area", CentionChat.areaId);
					formData.append("name", formName);
					formData.append("from", formEmail);
					formData.append("phone", formPhone);
					formData.append("subject", subj);
					formData.append("body", formQuestion);
					formData.append("callbackSchedule", globalSelectedDate);
					formData.append("channel", "VOICE");
					xhr.send(formData);
				});
			}

			if(document.getElementById("CentionMsgCancel")) {
				document.getElementById("CentionMsgCancel").addEventListener("click", function(){
					document.getElementById("CentionScheduleCall").style.display = "none";
				});
			}

			//only in FAQ_WIDGET part , TO FIX: better implemetation

			// notify/retry if there is no agent in FAQ
			if (useFAQChatInChat) {
				function handleFAQChatClick() {
					if (CentionChat.sessionId === "") {
						retryChat(false);
					} else {
						renderChatOpen(true);
					}
					if (document.getElementById('CentionChatStatus').style.display !== 'none') {
						retryChat(true);
					}
					_chatIsMinimized = false;
				}
				if(document.getElementById('iconContact')) {
					document.getElementById('iconContact').addEventListener('click', handleFAQChatClick);
				}
				if(document.getElementById('BrowseContact')) {
					document.getElementById('BrowseContact').addEventListener('click', handleFAQChatClick);
				}
				if(document.getElementById('FAQChatLauncher')) {
					document.getElementById('FAQChatLauncher').addEventListener('click', handleFAQChatClick);
				}
				if(document.getElementById('ViewPage-Answer')) {
					document.getElementById('ViewPage-Answer').addEventListener('click', function(event) {
						if (event.target.classList.contains('FeedbackDownvote')) {
							handleFAQChatClick();
						}
					});
				}
				if(document.getElementById('ViewPage-BrowseTree')) {
					document.getElementById('ViewPage-BrowseTree').addEventListener('click', function(event) {
						if (event.target.classList.contains('FeedbackDownvote')) {
							handleFAQChatClick();
						}
					});
				}
			}
			//end of FAQ WIDGET part

			// Restart Chat
			if(document.getElementById('CentionNewChatButton')) {
				document.getElementById('CentionNewChatButton').addEventListener('click', function() {
					agentResponded = false;
					showChatBody();
					document.getElementById("CentionChatView").style.display = "none";
					document.getElementById("CentionCallbackList").style.display = "none";
					if (hasChannel) {
						setElementStyle(document.getElementById("CentionChatChannelView"), "display", "block");
						setElementStyle(document.getElementById("CentionChannelView"), "display", "block");
						setElementStyle(document.getElementById("CentionStartView"), "display", "none");
					}
					getAgentList(CentionChat.areaId);
					retryChat(false);
					if(document.getElementById("agentAvatarList") && document.getElementById("agentAvatarList").style.display !== "none") {
						intervalCheckAvailability(true);
					}
					CentionChat.resetChat();
					if(FAQ_SKIN && FAQ_SKIN === "frictionless") {
						setElementStyle(document.querySelector(".FAQFooterContainer"), "display", "block");
					}
				});
			}

			// Retry Chat
			if(document.getElementById('textButtonRetry')) {
				document.getElementById('textButtonRetry').addEventListener('click', function() {
					retryChat(true);
				});
			}

			// Check email validity
			function isValidEmail(emailAddress) {
				const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i;
				return re.test(emailAddress);
			}

			// Check phone number validity
			function isValidPhone(phone) {
				const validOnePhoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*/;
				return validOnePhoneRegex.test(phone);
			}

			function safeHTML(html) {
				/* Based on guideless here : https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html */
				let safehtml = html;
				safehtml = safehtml.replaceAll('&', '&amp;');
				safehtml = safehtml.replaceAll('<', '&lt;');
				safehtml = safehtml.replaceAll('>', '&gt;');
				safehtml = safehtml.replaceAll('"', '&quot;');
				safehtml = safehtml.replaceAll("'", '&#x27;');
				safehtml = safehtml.replaceAll('/', '&#x2F;');
				return safehtml;
			}

			function verifyTxtField(txt) {
				let isTxtHtml = isHTML(txt);
				if(isTxtHtml) {
					let verifiedTxt = txt;
					verifiedTxt = safeHTML(txt);
					return verifiedTxt;
				}
				return txt;
			}

			// Verify start input
			var clientName = "", email = "", startQuestion = "", extraFieldDiv = "";
			function verifyStartInput() {
				clientName = document.getElementById("textInputName").value;
				email = document.getElementById("textInputEmail").value;
				phone = document.getElementById("textInputPhone").value;
				startQuestion = document.getElementById("textInputQuestion").value;
				extraFieldDiv = document.getElementById("textInputExtraField");

				// Checking area
				const noPreSelectArea = getCustomUI("avoidPreselectArea");
				if (noPreSelectArea) {
					// An area must be selected
					const selectedArea = document.getElementById("selectAreaId").value;
					if (selectedArea == -1) {
						console.log("Please select area");
						document.getElementById("selectAreaId").classList.add("warning");
						return false;
					}
				}
				var extraField;
				if (extraFieldDiv && extraFieldDiv.length > 0 && extraFieldParam.field[0].required) {
					extraField = escapeHTML(extraFieldDiv.value);
				}
				if (callbackEnabled && phone == "") {
					document.getElementById("textInputPhone").classList.add("warning");
					return false;
				}
				if(getCustomUI("questionFieldMandatory") && !getCustomUI("questionFieldHide")) {
					if (startQuestion == "") {
						document.getElementById("textInputQuestion").classList.add("warning");
						return false;
					}
				}
				if (clientName == "" || email == "" || (typeof extraField !== "undefined" && extraField == "")) {
					if (clientName == "") {
						document.getElementById("textInputName").classList.add("warning");
					}
					if (email == "") {
						document.getElementById("textInputEmail").classList.add("warning");
					}
					if (extraField == "") {
						document.getElementById("textInputExtraField").classList.add("warning");
					}
					return false;
				} else {
					if (clientName) {
						const nameInvalid = isHTML(clientName);
						if (nameInvalid) {
							document.getElementById("textInputName").classList.add("warning");
							document.getElementById("textInputName").setAttribute("placeholder", getCustomText("textNameInvalid"));
							document.getElementById("textInputName").value = "";
							return false;
						} else {
							const emailValid = isValidEmail(email);
							if (emailValid) {
								var questionInvalid = isHTML(startQuestion);
								if (questionInvalid) {
									document.getElementById("textInputQuestion").classList.add("warning");
									document.getElementById("textInputQuestion").setAttribute("placeholder", getCustomText("textQuestionInvalid"));
									document.getElementById("textInputQuestion").value = "";
									return false;
								}
								if (!cookieDisabled) {
									localStorage.setItem("chatName", clientName);
									localStorage.setItem("chatEmail", email);
									localStorage.setItem("chatPhone", phone);
									localStorage.setItem("chatSubject", startQuestion);
								}
								// If EULA enabled force user to check before start
								if (getCustomUI("askEULA")) {
									if (document.getElementById("askEULA").checked) {
										document.getElementById("EULAAcceptance").classList.remove("warning-label");
										return true;
									} else {
										document.getElementById("EULAAcceptance").classList.add("warning-label");
										document.querySelector(".warning-label").style.color = getCustomConfig("warningTextColor");
										return false;
									}
								}
								return true;
							} else {
								document.getElementById("textInputEmail").classList.add("warning");
								document.getElementById("textInputEmail").setAttribute("placeholder", getCustomText("textMessageEmailInvalid"));
								document.getElementById("textInputEmail").value = "";
								return false;
							}
						}
					}
				}
			}

			// Register event handler for when the start button is clicked.
			if(document.getElementById('textButtonStart')) {
				document.getElementById('textButtonStart').addEventListener('click', function() {
					resetThumbs();
					CentionChat.canChat(function(o) {
						if (o.agentsAvailable > 0) {
							agentResponded = false;
							document.querySelectorAll('#CentionStartView > input:not([type="button"])').forEach(function(input) {
								input.classList.remove("warning");
							});
							document.querySelectorAll('#CentionStartView > textarea').forEach(function(textarea) {
								textarea.classList.remove("warning");
							});
							setTextContentById("textStatusConnecting", getCustomText("textStatusConnecting"));
							document.getElementById("CentionChatList").querySelectorAll('.chatEnded').forEach(function(element) {
								element.remove();
							});
							document.querySelectorAll(".agentConnectedTo").forEach(function(element) {
								element.style.display = "none";
							});
							document.getElementById("CentionChatList").querySelectorAll('.message').forEach(function(element) {
								element.remove();
							});

							// Connect to the Cention chat server
							const inputPass = verifyStartInput();
							if (inputPass) {
								renderChatLoaderHeader();
								let externalData = "";
								const extraField = document.getElementById('textInputExtraField');
								if(extraField && extraField.value != "") {
									const extraFieldValue = escapeHTML(extraField.value);
									if (isJsonStringObj(extraFieldValue)) {
										externalData = extraFieldValue;
									} else {
										externalData = verifyTxtField(externalData);
										externalData = extraFieldParam.template.replace("{EXTRA_FIELD_VALUE}", extraFieldValue);
									}
								}
								CentionChat.connect({
									area: CentionChat.areaId,
									name: verifyTxtField(clientName),
									email: document.getElementById('textInputEmail').value,
									phone: document.getElementById('textInputPhone').value,
									message: verifyTxtField(startQuestion),
									externalData: externalData,
									clientDeviceInfo: {
										browserName: browserName ? browserName : '',
										browserVersion: browserVersion ? browserVersion : '',
										deviceVendor: deviceVendor ? deviceVendor : '',
										deviceModel: deviceModel ? deviceModel : '',
										deviceType: deviceType ? deviceType : '',
										osName: osName ? osName : '',
										osVersion: osVersion ? osVersion : '',
										ua: UA ? UA : '',
										isMobile: isMobile(UA)
									}
								});
								if (params.hooks && params.hooks.onChatStarted) {
									params.hooks.onChatStarted();
								}

								//if(FAQ_SKIN && FAQ_SKIN == "frictionless"){
								//	document.querySelector(".FAQFooterContainer").style.display = "none";
								//}
								document.getElementById('CentionStartView').style.display = "none";
								document.getElementById('CentionChatStatus').style.display = "block";
								document.getElementById('CentionChatView').style.display = "block";
								document.getElementById('CentionChatFinishButtonWrapper').style.display = "block";
								if (ccs.feature["chat.attachment"]) {
									document.getElementById("CentionChatSendFileButtonWrapper").style.display = "block";
								} else {
									document.getElementById("CentionChatSendFileButtonWrapper").style.display = "none";
								}
								document.getElementById('CentionNewChatButtonWrapper').style.display = "none";
								CentionChatResetBodySize("connected");
								document.getElementById('CentionChatQuestion').setAttribute('contenteditable', true);
								activateChatUI();
								if (statusInterval) {
									clearInterval(statusInterval);
								}
							}
						} else {
							document.getElementById('CentionStartView').style.display = "none";
							document.getElementById('CentionChatView').style.display = "none";
							document.getElementById('CentionChatChannelView').style.display = "none";
							document.getElementById('CentionNoAgentsView').style.display = "block";
							removeCreateErrandPlaceholder();
						}
					}, CentionChat.areaId, true);
				});
			}

			function escapeHTML(s) {
				const strippedText = document.createElement("div");
				strippedText.textContent = s;
				return strippedText.innerText;
			}

			if(document.getElementById('textButtonAway')) {
				document.getElementById('textButtonAway').addEventListener('click', function(e) {
					e.preventDefault();
					// Redirect to whatever it linked to that defined by customer
					if (getCustomUI("buttonAwayLink") !== "#" && getCustomUI("buttonAwayLink") != "") {
						window.open(getCustomUI("buttonAwayLink"), '_blank');
					}
				});
			}

			if (getCustomUI("showMenuAsDefault")) {
				setInnerHTMLByElem(document.getElementById("CentionToggleMenuButton"), "<i class='icon-close'></i>");
				setElementStyle(document.getElementById("CentionToggleableChatMenu"), "display", "flex");
				if(document.getElementById("CentionChatList")) {
					document.getElementById("CentionChatList").classList.add("shrinked");
				}
				if(document.getElementById("CentionChatFooter")) {
					document.getElementById("CentionChatFooter").classList.add("expanded");
				}
				if (document.getElementById("CentionFAQFooterContainer")) {
					document.getElementById("CentionFAQFooterContainer").classList.toggle("expanded");
				}
				if (document.getElementById("CentionFAQConversationContainer")) {
					document.getElementById("CentionFAQConversationContainer").classList.add("shrinked");
				}
			} else {
				setInnerHTMLByElem(document.getElementById("CentionToggleMenuButton"), "<i class='icon-add'></i>");
				setElementStyle(document.getElementById("CentionToggleableChatMenu"), "display", "none");
			}

			// Register event handler for when the expand button is clicked, this expands more options
			if(document.getElementById("CentionToggleMenuButton")) {
				let showChatMenu = false;
				document.getElementById("CentionToggleMenuButton").addEventListener("click", function(e) {
					e.preventDefault();
					if (this.querySelector("i").classList.contains("icon-add")) {
						this.querySelector("i").classList.toggle("icon-close");
						this.querySelector("i").classList.remove("icon-add");
						showChatMenu = true;
						if (document.getElementById("CentionFAQConversationContainer")) {
							document.getElementById("CentionFAQConversationContainer").classList.add("shrinked");
						}
						document.getElementById("CentionChatList").classList.add("shrinked");
						if(document.getElementById("CentionChatFooter")) {
							document.getElementById("CentionChatFooter").classList.add("expanded");
						}
					} else {
						this.querySelector("i").classList.toggle("icon-add");
						this.querySelector("i").classList.remove("icon-close");
						showChatMenu = false;
						if (document.getElementById("CentionFAQConversationContainer")) {
							document.getElementById("CentionFAQConversationContainer").classList.remove("shrinked");
						}
						document.getElementById("CentionChatList").classList.remove("shrinked");
						if(document.getElementById("CentionChatFooter")) {
							document.getElementById("CentionChatFooter").classList.remove("expanded");
						}
					}
					document.getElementById("CentionToggleableChatMenu").style.display = (document.getElementById("CentionToggleableChatMenu").style.display === "none") ? "flex" : "none";

					if (useFAQChatInChat) {
						if (document.getElementById("CentionFAQFooterContainer")) {
							document.getElementById("CentionFAQFooterContainer").classList.toggle("expanded");
						}

						if(FAQ_SKIN && FAQ_SKIN == "frictionless"){
							if(showChatMenu) {
								setElementStyle(document.getElementById("CentionToggleableChatMenu"), "display", "flex");
								setElementStyle(document.querySelector(".FAQFooterContainer"), "display", "none");
							} else {
								setElementStyle(document.querySelector(".FAQFooterContainer"), "display", "block");
							}
						}
					}
				});
			}

			if(document.getElementById('closeCookieWarning')) {
				document.getElementById('closeCookieWarning').addEventListener('click', function(e) {
					e.preventDefault();
					document.getElementById('CentionCookieWarning').style.display = "none";
					document.getElementById('showCookieWarning').style.display = "block";
				});
			}
			if(document.getElementById('showCookieWarning')) {
				document.getElementById('showCookieWarning').addEventListener('click', function(e) {
					e.preventDefault();
					document.getElementById('showCookieWarning').style.display = "none";
					document.getElementById('CentionCookieWarning').style.display = "block";
				});
			}

			const enableRatings = getCustomUI('chatRatingsEnabled');
			//Start of Satisfaction Meter Section
			if(enableRatings){
				const ratingUrl = baseURL + spacePrefix + "/socket/external.api/chatrating";
				setElementStyle(document.getElementById('Satisfaction'), "display", "none");
				setElementStyle(document.getElementById('SatisfactionFeedback'), "display", "none");
				setElementStyle(document.getElementById('FeedbackButton'), "display", "none");

				// keeping for test purpose, to be commented
				//document.getElementById("CentionChatQuestion").addEventListener("click", function(){
				//	document.getElementById('Satisfaction').style.display = "block";
				//});

				if(document.getElementById('SatisfactionClose')) {
					document.getElementById('SatisfactionClose').addEventListener("click", function(e){
						e.preventDefault();
						triggerSatisfaction = false;
						document.getElementById('Satisfaction').style.display = "none";
						document.getElementById('SatisfactionFeedback').style.display = "none";
						localStorage.setItem("chatRatedOngoingHidden", "true");
					});
				}


				//animate text to give a feel/response to the client
				function animateSatisfactionText() {
					var satisfactionText = document.getElementById('SatisfactionText');
					if(satisfactionText) {
						setTextContentById("SatisfactionText", getCustomText("textSatisfactionReceived"));
						satisfactionText.style.opacity = 1; // Ensure the element is visible before starting the animation
						satisfactionText.classList.add('fadeInOutAnimation');
					}
				}

				function startLeaveComment() {
					if (ccs.feature['chat.allow-rating-text-comment']) {
						if(localStorage.getItem("chatRatedComment") === "true"){
							return;
						}
						setElementStyle(document.getElementById('FeedbackButton'), "display", "block");
					}
				}

				if(getCustomUI('chatRatingsEnabled')){
					//initially, both thumbs are inactive
					document.getElementById('OngoingLikedChat').classList.add(thumbsUpRegular);
					document.getElementById('OngoingUnlikedChat').classList.add(thumbsDownRegular);
					document.getElementById('OngoingLikedChat').classList.remove(thumbsUpSolid);
					document.getElementById('OngoingUnlikedChat').classList.remove(thumbsDownSolid);

					if(localStorage.getItem("chatRatedValue") === "good"){
						document.getElementById('OngoingLikedChat').classList.remove(thumbsUpRegular);
						document.getElementById('OngoingLikedChat').classList.add(thumbsUpSolid);
					} else if(localStorage.getItem("chatRatedValue") === "bad"){
						document.getElementById('OngoingUnlikedChat').classList.remove(thumbsDownRegular);
						document.getElementById('OngoingUnlikedChat').classList.add(thumbsDownSolid);
					}
					if(localStorage.getItem("chatRated") && localStorage.getItem("chatRatedComment") !== "true"){
						//rated but comment was missed/not completed, showing it again
						if(ccs.feature['chat.allow-rating-text-comment']) {
							startLeaveComment();
						}
					}
				}

				if(document.getElementById('OngoingLikedChat')) {
					document.getElementById('OngoingLikedChat').addEventListener("click", function(e) {
						e.preventDefault();
						animateSatisfactionText();

						// Thumbs up to solid/active
						document.getElementById('OngoingLikedChat').classList.remove(thumbsUpRegular);
						document.getElementById('OngoingLikedChat').classList.add(thumbsUpSolid);
						// Thumbs down back to regular/inactive
						document.getElementById('OngoingUnlikedChat').classList.remove(thumbsDownSolid);
						document.getElementById('OngoingUnlikedChat').classList.add(thumbsDownRegular);

						startLeaveComment();
						// Create FormData object and append data
						const formData = new FormData();
						formData.append("session", CentionChat.sessionId);
						formData.append("action", "likechat");
						fetch(ratingUrl, {
							method: 'POST',
							body: formData
						}).then(response => {
							if (response.ok) {
								return response.json();
							} else {
								throw new Error('Failed to send rating');
							}
						}).then(data => {
							console.log("Post rating msg: ", data);
							localStorage.setItem("chatRated", "true");
							localStorage.setItem("chatRatedValue", "good");
							localStorage.removeItem("chatRatedOngoingHidden");
						}).catch(error => {
							console.error('Error:', error);
						});
					});
				}
				if(document.getElementById('OngoingUnlikedChat')) {
					document.getElementById('OngoingUnlikedChat').addEventListener("click", function(e) {
						e.preventDefault();
						animateSatisfactionText();
						// Thumbs up back to regular/inactive
						document.getElementById('OngoingLikedChat').classList.remove(thumbsUpSolid);
						document.getElementById('OngoingLikedChat').classList.add(thumbsUpRegular);
						// Thumbs down to solid/active
						document.getElementById('OngoingUnlikedChat').classList.remove(thumbsDownRegular);
						document.getElementById('OngoingUnlikedChat').classList.add(thumbsDownSolid);

						startLeaveComment();
						// Create FormData object and append data
						const formData = new FormData();
						formData.append("session", CentionChat.sessionId);
						formData.append("action", "unlikechat");
						fetch(ratingUrl, {
							method: 'POST',
							body: formData
						}).then(response => {
							if (response.ok) {
								return response.json();
							} else {
								throw new Error('Failed to send rating');
							}
						}).then(data => {
							console.log("Post rating msg: ", data);
							localStorage.setItem("chatRated", "true");
							localStorage.setItem("chatRatedValue", "bad");
							localStorage.removeItem("chatRatedOngoingHidden");
						}).catch(error => {
							console.error('Error:', error);
						});
					});
				}

				//Clicking Feedback/Comment
				if(document.getElementById('FeedbackButton')) {
					document.getElementById('FeedbackButton').addEventListener("click", function(e) {
						e.preventDefault();
						document.getElementById('SatisfactionFeedback').style.display = "block";
						document.getElementById('ongoingContent').value = '';
					});
				}

				if (document.getElementById('ongoingContent') && document.getElementById('ongoingContent').value == '') {
					document.getElementById('buttonOngoingSend').classList.add("disabled");
				}

				if(document.getElementById('ongoingContent')) {
					document.getElementById('ongoingContent').addEventListener("keyup", function() {
						if (document.getElementById('ongoingContent').value.length > 0) {
							document.getElementById('buttonOngoingSend').classList.remove("disabled");
						} else {
							document.getElementById('buttonOngoingSend').classList.add("disabled");
						}
					});
				}

				//Feedback form buttons
				if(document.getElementById('buttonOngoingSend')) {
					document.getElementById('buttonOngoingSend').addEventListener("click", function(e) {
						e.preventDefault();
						const message = document.getElementById('ongoingContent').value;

						var action = "";
						if (document.getElementById("OngoingLikedChat").classList.contains(thumbsUpSolid))
							action = "likechat";
						else if (document.getElementById("OngoingLikedChat").classList.contains(thumbsDownSolid))
							action = "unlikechat";

						const formData = new FormData();
						formData.append("session", CentionChat.sessionId);
						formData.append("action", action);
						formData.append("message", message);

						fetch(ratingUrl, {
							method: "POST",
							body: formData
						})
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							return response.json();
						})
						.then(data => {
							console.log(data);
							localStorage.setItem("chatRatedComment", "true");
							setElementStyle(document.getElementById('FeedbackButton'), "display", "none");
						})
						.catch(error => {
							console.error('There has been a problem with your fetch operation:', error);
						});

						document.getElementById('SatisfactionFeedback').style.display = "none";
						document.getElementById('buttonOngoingSend').classList.add("disabled");
						animateSatisfactionText();
					});
				}

				if(document.getElementById('buttonOngoingCancel')) {
					document.getElementById('buttonOngoingCancel').addEventListener("click", function(e) {
						e.preventDefault();
						document.getElementById('ongoingContent').value = '';
						document.getElementById('SatisfactionFeedback').style.display = "none";
						document.getElementById('buttonOngoingSend').classList.add("disabled");
					});
				}
				//End of Satisfaction Meter Sections
			}else{
				setElementStyle(document.getElementById('Satisfaction'), "display", "none");
			}

			//Function to submit chat conversation
			function submitChat(event) {
				var chatMsg = document.getElementById('CentionChatQuestion').innerHTML;
				var fromClient = true, sender = "CLIENT";
				let msgIsHTML = isHTML(chatMsg);
				if (msgIsHTML) {
					/**
					 * We must still supporting html to support pasted formatted text and images, and dragged images
					 * so carefully stripping unnecessary html tags and attributes seems reasonable here
					 * **/
					if (HtmlSanitizer) {
						HtmlSanitizer.AllowedAttributes['id'] = true;
						HtmlSanitizer.AllowedAttributes['class'] = true;
						HtmlSanitizer.AllowedAttributes['data-lightbox'] = true;
						chatMsg = HtmlSanitizer.SanitizeHtml(chatMsg);
					}
				}

				var textLengthCheck = 0;
				var imageContainsDragImg = false;
				const msgHtmlString = document.createElement('div');
				msgHtmlString.innerHTML = chatMsg;
				const msgHtmlNode = msgHtmlString;
				if (msgHtmlNode.querySelector('#draggedImgPreviewContainer')) {
					imageContainsDragImg = true;
				}
				if (imageContainsDragImg) {
					const html = document.createElement('div');
					html.innerHTML = chatMsg;
					_tempImageAttachments.forEach(function (tempImages) {
						const imgPreviewLink = html.querySelector('#imgPreviewLink-' + tempImages.id);
						if (imgPreviewLink) {
							imgPreviewLink.setAttribute('href', CentionChat.baseURL + escape(tempImages.download) + '?t=' + CentionChat.sessionSecret);
						}
						const img = html.querySelector('img#' + tempImages.id);
						if (img) {
							img.setAttribute('src', CentionChat.baseURL + escape(tempImages.download) + '?t=' + CentionChat.sessionSecret);
							img.style.maxWidth = "200px";
							img.style.maxHeight = "200px";
						}
					});
					chatMsg = html.innerHTML;
					sending = CentionChat.message(chatMsg);
					addNewMessage({
						unsent: true,
						text: sending.message,
						fromClient: fromClient,
						sender: sender,
						id: "unsent-" + sending.id
					}, document.getElementById('textInputName').value);
					setInnerHTMLByElem(document.getElementById('CentionChatQuestion'), "");
					event.preventDefault();
					previewTimeout = setTimeout(sendPreview, 400);
				} else {
					textLengthCheck = document.getElementById('CentionChatQuestion').textContent.trim().length;
					if (chatMsg != "" && textLengthCheck > 0) {
						sending = CentionChat.message(chatMsg);
						addNewMessage({
							unsent: true,
							text: sending.message,
							fromClient: fromClient,
							sender: sender,
							id: "unsent-" + sending.id
						}, document.getElementById('textInputName').value);
						setInnerHTMLByElem(document.getElementById('CentionChatQuestion'), "");
						event.preventDefault();
						previewTimeout = setTimeout(sendPreview, 400);
					} else {
						event.preventDefault();
					}
				}
				if(isChatWithChatBot) {
					if(USE_STREAM_LOADER && !document.getElementById("CentionStreamLoader")) {
						const chatListElem = document.getElementById("CentionChatList");
						const chatLoader = document.createElement('div');
						chatLoader.setAttribute('id', "CentionStreamLoader");
						chatLoader.classList.add('cention-chat-loader');
						for (let i = 0; i < 3; i++) {
							const dot = document.createElement('span');
							dot.classList.add('dot');
							chatLoader.appendChild(dot);
						}
						chatListElem.appendChild(chatLoader);
					}
				}
			}

			// Register event handler for when the enter key is pressed
			// within the <textarea> where the client can input a new message.
			// When the enter key is pressed then what the client has entered
			// in the text box will be sent as a new message.
			// To make a line break hold down the shift key when pressing enter.
			if(document.getElementById('CentionChatQuestion')) {
				document.getElementById('CentionChatQuestion').addEventListener('keydown', function(e) {
					previewCount++;
					if (previewCount > 5) {
						sendPreview();
					}
					if(e.key === "Enter" && !e.shiftKey) {
						e.preventDefault();
						submitChat(e);
					}
					clearTimeout(previewTimeout);
				});
			}

			// submit new message by button click
			if(document.getElementById("SubmitChat")) {
				document.getElementById("SubmitChat").addEventListener("click", function(e){
					e.preventDefault();
					submitChat(e);
				});
			}

			function getTimeZoneOffset(){
				return new Date().getTimezoneOffset() * -60;
			}
			// Register event handler for when the save as copy button is clicked.
			// User can save and download the chat history by this event.
			const user =  document.getElementById('textInputName') ? document.getElementById('textInputName').value : "";
			if(document.getElementById('CentionChatSaveButton')) {
				document.getElementById('CentionChatSaveButton').addEventListener('click', function(e) {
					e.preventDefault();
					var url = CentionChat.baseURL + spacePrefix + "/socket/external.api/savehistory?area="+ CentionChat.areaId +"&secret=" + CentionChat.sessionSecret + "&timezoneoffset=" + getTimeZoneOffset() + "&host=" + CentionChat.baseURL;
					if(typeof params.languageCode !== "undefined") {
						url += "&lang="+params.languageCode;
					}
					window.open(url, '_blank');
				});
			}

			// Register event handler for when the print button is clicked.
			// User can print the chat history by clicking print button. The popup window will appear with save botton to print the chat history.
			if(document.getElementById('CentionChatPrintButton')) {
				document.getElementById('CentionChatPrintButton').addEventListener('click', function(e) {
					e.preventDefault();
					var url = CentionChat.baseURL + spacePrefix + "/socket/external.api/printhistory?area="+ CentionChat.areaId +"&secret=" + CentionChat.sessionSecret + "&timezoneoffset=" + getTimeZoneOffset() + "&host=" + CentionChat.baseURL;
					if(typeof params.languageCode !==  "undefined") {
						url += "&lang="+params.languageCode;
					}
					var popup = window.open(url,
					'window',
					'width=640,height=480,scrollbars=yes,status=no');
					if( window.focus ) {
						popup.focus();
					}
				});
			}

			// Register event handler for when the chat finish button is clicked.
			// FIXME: When socket upgrading, no action taken (backend) on finish chat.
			if(document.getElementById('CentionChatFinishButton')) {
				document.getElementById('CentionChatFinishButton').addEventListener('click', function(e) {
					e.preventDefault();
					// CentionChat.close() function close chat connection between user and agent.
					CentionChat.close();
					deactivateChatUI();
					if(getCustomUI('chatRatingsEnabled')){
						//if already rated, no need to show this
						if(localStorage.getItem("chatRated") !== "true" && localStorage.getItem("chatRatedOngoingHidden") === "true"){
							document.getElementById('Satisfaction').style.display = 'block';
							localStorage.removeItem("chatRatedOngoingHidden");
						}
					}
				});
			}

			/** VIDEO CALL SEGMENT **/
			/*-=-=-=-=-=-=-=-=--=-=-*/

			//Open Video Call UI
			//Request to open camera right away (not to waste another click)
			if(document.getElementById('CentionChatVideoButton')) {
				document.getElementById('CentionChatVideoButton').addEventListener('click', function(e) {
					if (coBrowseMode) {
						//just bring back the frame
						document.getElementById('CentionChatVideoFrame').style.display = 'block';
						document.getElementById('CentionChatVideoButtonStop').style.display = 'block';
						document.getElementById('CentionChatVideoButton').style.display = 'none';
						return;
					}
					document.getElementById('CentionChatVideoFrame').style.display = 'block';
					e.preventDefault();
					const user = document.getElementById('textInputName') ? document.getElementById('textInputName').value : "";
					// Start video
					CentionChat.registerVideoCall('video init', user, function() {
						setElementStyle(document.getElementById('CentionChatVideoButton'), "display", "none");
						setElementStyle(document.getElementById('CentionChatVideoButtonStop'), "display", "block");
						setElementStyle(document.getElementById('disableVidButton'), "display", "none");
						setElementStyle(document.getElementById('enableVidButton'), "display", "none");
						setElementStyle(document.getElementById('disableAudioButton'), "display", "none");
						setElementStyle(document.getElementById('enableAudioButton'), "display", "none");
						setElementStyle(document.getElementById('hangupButton'), "display", "none");
						setElementStyle(document.getElementById('callButton'), "display", "none");

						if (ccs.feature["chat.allow-video-call"] && getCustomUI("enableVideoChat")) {
							setElementStyle(document.getElementById('startButton'), "display", "block");
						}
					});
				});
			}

			//Stopping the video UI
			if(document.getElementById('CentionChatVideoButtonStop')) {
				document.getElementById('CentionChatVideoButtonStop').addEventListener('click', function(e) {
					e.preventDefault();
					if (coBrowseMode) {
						//hide the frame again
						setElementStyle(document.getElementById('CentionChatVideoFrame'), "display", "none");
						setElementStyle(document.getElementById('CentionChatVideoButton'), "display", "block");
						setElementStyle(document.getElementById('CentionChatVideoButtonStop'), "display", "none");
						return;
					}
					setElementStyle(document.getElementById('CentionChatVideoButton'), "display", "block");
					setElementStyle(document.getElementById('CentionChatVideoButtonStop'), "display", "none");
					setElementStyle(document.getElementById('CentionChatVideoFrame'), "display", "none");
					disableWebCam();
					if (onVideoCall) {
						CentionChat.registerVideoCall('hangup', user, function() {});
					}
					if (clientScreenShareMode) {
						CentionChat.registerVideoCall('stop-screen-sharing', user, function() {});
					}
					if (agentScreenShareMode) {
						CentionChat.registerVideoCall('stop-agent-screen-sharing', user, function() {});
					}
				});
			}

			if(document.getElementById('CentionCloseVid')) {
				document.getElementById('CentionCloseVid').addEventListener('click', function(e) {
					e.preventDefault();
					if (coBrowseMode) {
						const coBrowseAlertMsg = CentionChat.I("Do you want to close current co-browsing session ?");
						const clientCoBrowseExit = confirm(coBrowseAlertMsg);
						if (clientCoBrowseExit == true) {
							stopVideoWhenClose();
						} else {
							return;
						}
					} else {
						stopVideoWhenClose();
					}
				});
			}

			function stopVideoWhenClose() {
				setElementStyle(document.getElementById('CentionChatVideoButton'), "display", "block");
				setElementStyle(document.getElementById('CentionChatVideoButtonStop'), "display", "none");
				setElementStyle(document.getElementById('CentionChatVideoFrame'), "display", "none");
				disableWebCam();
				if (onVideoCall) {
					CentionChat.registerVideoCall('hangup', user, function() {});
				}
				if (clientScreenShareMode) {
					CentionChat.registerVideoCall('stop-screen-sharing', user, function() {});
				}
				if (agentScreenShareMode) {
					CentionChat.registerVideoCall('stop-agent-screen-sharing', user, function() {});
				}
			}

			if(document.getElementById("CloseAgentVid")) {
				document.getElementById("CloseAgentVid").addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('stop-agent-screen-sharing', user, function() {
						console.log("stop agent screen share");
					});
				});
			}

			//start share screen for co browsing, with agent
			document.querySelectorAll("[id*='coBrowsing']").forEach(function(element) {
				element.addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('start-screen-capture', user, function() {
						document.querySelectorAll("[id*='coBrowsing']").forEach(function(el) {
							el.style.display = 'none';
						});
						document.querySelectorAll("[id*='stopCoBrowsing']").forEach(function(el) {
							el.style.display = 'block';
						});
						document.querySelectorAll("[id*='screenShare']").forEach(function(el) {
							el.style.display = 'none';
						});
						document.getElementById("coBrowsingStatusTxt").style.display = 'block';
						setTextContentById("coBrowsingStatusTxt", getCustomText("textStatusConnecting"));
						document.getElementById("coBrowsing").classList.remove("cobrowse-need-click");
						document.getElementById("toggleRemoteControl").style.display = 'block';
					});
				});
			});

			document.querySelectorAll("[id*='stopCoBrowsing']").forEach(function(element) {
				element.addEventListener("click", function(e) {
					console.log("dbg: stop co browse");
					setTextContentById("coBrowsingStatusTxt", "Stopped");
					e.preventDefault();
					CentionChat.registerVideoCall('stop-screen-capture', user, function() {
						document.querySelectorAll("[id*='coBrowsing']").forEach(function(el) {
							el.style.display = 'block';
						});
						document.querySelectorAll("[id*='stopCoBrowsing']").forEach(function(el) {
							el.style.display = 'none';
						});
						document.getElementById("toggleRemoteControl").style.display = 'none';
					});
				});
			});

			if(document.getElementById("toggleRemoteControl")) {
				document.getElementById("toggleRemoteControl").addEventListener("click", function(e) {
					document.getElementById("toggleRemoteControlListOpt").style.display = (document.getElementById("toggleRemoteControlListOpt").style.display === "none") ? "block" : "none";
				});
			}

			//Start local camera
			if(document.getElementById('startButton')) {
				document.getElementById('startButton').addEventListener("click", function(e) {
					e.preventDefault();
					const user = document.getElementById('textInputName') ? document.getElementById('textInputName').value : "";
					// Start video
					//CentionChat.registerVideoCall('video init', user, function() {
					CentionChat.registerVideoCall('start camera', user, function() {
						setElementStyle(document.getElementById("CentionChatVideoFrameWrapper"), "display", "block");
						setElementStyle(document.getElementById("CentionChatVideoLocalWrapper"), "display", "block");
						setElementStyle(document.getElementById("callButton"), "display", "block");
						setElementStyle(document.getElementById("maximizeVideo"), "display", "block");
						setElementStyle(document.getElementById("startButton"), "display", "none");

						setElementStyle(document.getElementById("CentionChatVideoLocalWrapper"), "display", "block");
						setElementStyle(document.getElementById("CentionChatVideoFrameRemote"), "display", "block");
						setElementStyle(document.getElementById("AvatarOverlayLocal"), "display", "none");
					});
				});
			}

			//Starting to call
			if(document.getElementById('callButton')) {
				document.getElementById('callButton').addEventListener("click", function(e) {
					e.preventDefault();
					const user = document.getElementById('textInputName').value;
					CentionChat.registerVideoCall('video offer', user, function() {
						document.getElementById("startButton").style.display = 'none';
					});
					vidCallInProgress = true;
					document.getElementById('callButton').classList.add("disabled");
					setElementStyle(document.getElementById("hangupButton"), "display", "block");
					setElementStyle(document.getElementById("disableVidButton"), "display", "block");
					setElementStyle(document.getElementById("disableAudioButton"), "display", "block");
				});
			}

			if(document.getElementById('screenShare')) {
				document.getElementById('screenShare').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('start-screen-sharing', user, function() {
						setElementStyle(document.getElementById("screenShare"), "display", "none");
						setElementStyle(document.getElementById("stopScreenShare"), "display", "block");
						if (ccs.feature['chat.allow-co-browsing']) {
							setElementStyle(document.getElementById("coBrowsing"), "display", "block");
						}
					});
				});
			}

			if(document.getElementById('stopScreenShare')) {
				document.getElementById('stopScreenShare').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('stop-screen-sharing', user, function() {
						setElementStyle(document.getElementById("stopScreenShare"), "display", "none");
						setElementStyle(document.getElementById("screenShare"), "display", "block");

						if (ccs.feature['chat.allow-co-browsing']) {
							setElementStyle(document.getElementById("coBrowsing"), "display", "none");
						}
					});
				});
			}

			//Stop the call
			if(document.getElementById('hangupButton')) {
				document.getElementById('hangupButton').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('hangup', user, function() {
						if (ccs.feature['chat.video-call']) {
							setElementStyle(document.getElementById("startButton"), "display", "block");
						}
						setElementStyle(document.getElementById("hangupButton"), "display", "none");
						setElementStyle(document.getElementById("disableVidButton"), "display", "none");
						setElementStyle(document.getElementById("enableVidButton"), "display", "none");
						setElementStyle(document.getElementById("disableAudioButton"), "display", "none");
						setElementStyle(document.getElementById("enableAudioButton"), "display", "none");
					});
					vidCallInProgress = false;
					if (fullScreenMode) {
						toggleFullScreen();
					}
					document.getElementById('callButton').classList.remove("disabled");
					setElementStyle(document.getElementById("callButton"), "display", "none");
				});
			}

			// Disable video
			// Make this only appear when session ongoing
			if(document.getElementById('disableVidButton')) {
				document.getElementById('disableVidButton').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('stopWebCam', user, function() {
						setElementStyle(document.getElementById("hangupButton"), "display", "block");
						setElementStyle(document.getElementById("enableVidButton"), "display", "block");
						setElementStyle(document.getElementById("disableVidButton"), "display", "none");
						setElementStyle(document.getElementById("disableAudioButton"), "display", "block");
						setElementStyle(document.getElementById("maximizeVideo"), "display", "block");
					});
				});
			}

			if(document.getElementById('enableVidButton')) {
				document.getElementById('enableVidButton').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('startWebCam', user, function() {
						setElementStyle(document.getElementById("hangupButton"), "display", "block");
						setElementStyle(document.getElementById("disableVidButton"), "display", "block");
						setElementStyle(document.getElementById("enableVidButton"), "display", "none");
					});
				});
			}

			// Disable audio
			// Make this only appear when session ongoing
			if(document.getElementById('disableAudioButton')) {
				document.getElementById('disableAudioButton').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('stopAudio', user, function() {
						setElementStyle(document.getElementById("hangupButton"), "display", "block");
						setElementStyle(document.getElementById("disableAudioButton"), "display", "none");
						setElementStyle(document.getElementById("enableAudioButton"), "display", "block");
					});
				});
			}

			// Re-enable audio
			// Make this only appear when session ongoing
			if(document.getElementById('enableAudioButton')) {
				document.getElementById('enableAudioButton').addEventListener("click", function(e) {
					e.preventDefault();
					CentionChat.registerVideoCall('startAudio', user, function() {
						setElementStyle(document.getElementById("hangupButton"), "display", "block");
						setElementStyle(document.getElementById("disableAudioButton"), "display", "block");
						setElementStyle(document.getElementById("enableAudioButton"), "display", "none");
					});
				});
			}

			//Client receive answer from Agent Video request
			CentionChat.registerAction('video-answer', function ( data ) {
				console.log("Client received video answer signal", data);
			});

			//Clear chat rating
			function clearChatRating(reset) {
				localStorage.removeItem("chatRated");
				localStorage.removeItem("chatRatedValue");
				if (ccs.feature['chat.allow-rating-text-comment']) {
					localStorage.removeItem("chatRatedComment");
				}
				if(reset) {
					localStorage.removeItem("chatRatedOngoingHidden");
				}
			}

			function resetThumbs(){
				document.getElementById('OngoingLikedChat').classList.add(thumbsUpRegular);
				document.getElementById('OngoingUnlikedChat').classList.add(thumbsDownRegular);
				document.getElementById('OngoingLikedChat').classList.remove(thumbsUpSolid);
				document.getElementById('OngoingUnlikedChat').classList.remove(thumbsDownSolid);
				setElementStyle(document.getElementById('Satisfaction'), "display", "none");
			}

			function activateChatUI() {
				// Enable the chat editor and buttons for inputting new message
				document.getElementById('CentionChatQuestion').contentEditable = true;
				document.getElementById('CentionChatQuestion').classList.remove('disabled');
				document.getElementById('SubmitChat').classList.remove('disabled');
			}

			function deactivateChatUI() {
				// Scroll the message list to the bottom
				autoScrollToBottomChatList();

				// Disable the chat editor for inputting new message
				document.getElementById('CentionChatQuestion').contentEditable = false;
				document.getElementById('CentionChatQuestion').classList.add('disabled');
				document.getElementById('SubmitChat').classList.add('disabled');

				// Remove agent name from header
				setInnerHTMLByElem(document.querySelector('#CentionChatHeader > .text'), _chatTitle);

				// Hide finish button, show restart chat button
				setElementStyle(document.getElementById('CentionNewChatButtonWrapper'), "display", "block");
				setElementStyle(document.getElementById('CentionChatFinishButtonWrapper'), "display", "none");
				document.querySelectorAll("[id*='CentionChatSendFileButtonWrapper']").forEach(function(element) {
					element.style.display = 'none';
				});
				setElementStyle(document.getElementById('CentionChatVideoCallWrapper'), "display", "none");
				setElementStyle(document.getElementById('CentionChatAudioCallWrapper'), "display", "none");

				// Hide connected message
				setElementStyle(document.getElementById('CentionChatStatus'), "display", "none");
				setElementStyle(document.getElementById('CentionChatWelcome'), "display", "none");
				setElementStyle(document.getElementById('CentionChatConnected'), "display", "none");
				ccs.resumable = false;
				clientAvatar = "";

				// Video chat stuff
				setElementStyle(document.getElementById('CentionChatVideoFrame'), "display", "none");

				if(getCustomUI('chatRatingsEnabled')){
					clearChatRating();
				}
				CentionChatActive = false;
				if (params.hooks && params.hooks.onChatStopped) {
					params.hooks.onChatStopped();
				}

				if (useFAQChatInChat) {
					if (document.getElementById("CentionFAQFooterContainer")) {
						document.getElementById("CentionFAQFooterContainer").classList.toggle("expanded");
					}
					if(FAQ_SKIN && FAQ_SKIN === 'frictionless'){
						setElementStyle(document.querySelector(".FAQFooterContainer"), "display", "block");
						setElementStyle(document.getElementById("CentionToggleableChatMenu"), "display", "none");

						//change elem id CentionChatView height to 90%
						setElementStyle(document.getElementById("CentionChatView"), "height", "90%");
					}
				}
				const chatLoader = document.getElementById("CentionStreamLoader");
				if(chatLoader) {
					chatLoader.remove();
				}
			}

			//Register event handler for when the chat send file button is clicked.
			if(document.getElementById('CentionChatSendFileButtonWrapper')) {
				const sendFileButtonWrapper = document.getElementById("CentionChatSendFileButtonWrapper");
				const fileInput = document.getElementById("CentionChatSendFileInput");
				sendFileButtonWrapper.addEventListener("click", function () {
					fileInput.click();
				});
				fileInput.addEventListener("change", function () {
					if(fileInput.files.length > 0) {
						for(var i = 0; i < fileInput.files.length; i++) {
							CentionChat.attachFile(fileInput.files[i], function (um) {
								addNewMessage(um, document.getElementById('textInputName').value);
							});
						}
					}
				});
			}
			const questionWrapper = document.getElementById('CentionChatQuestion');
			if(questionWrapper) {
				questionWrapper.addEventListener('drop', function(e) {
					e.preventDefault();
					var file = e.dataTransfer.files[0];
					uploadChatTempImage(file);
				});
				questionWrapper.addEventListener('dragenter', function(e) {
					e.preventDefault();
				});

				questionWrapper.addEventListener('dragover', function(e) {
					e.preventDefault();
				});

				questionWrapper.addEventListener('paste', function(e) {
					handlepaste(e);
				});
			}
			if(document.getElementById('CentionChatVideoFrameLocal')) {
				document.getElementById('CentionChatVideoFrameLocal').addEventListener('click', function() {
					if (fullScreenMode) {
						toggleLocalFS();
					}
				});
			}
			if(document.getElementById('CentionChatVideoFrameRemote')) {
				document.getElementById('CentionChatVideoFrameRemote').addEventListener('click', function() {
					if (fullScreenMode) {
						toggleRemoteFS();
					}
				});
			}

			if(document.getElementById('client-widget-display-view')) {
				document.getElementById('client-widget-display-view').addEventListener('click', function() {
					if (fullScreenMode) {
						toggleLocalShareFS();
					}
				});
			}

			if(document.getElementById('agent-widget-display-view')) {
				document.getElementById('agent-widget-display-view').addEventListener('click', function() {
					if (fullScreenMode) {
						toggleRemoteShareFS();
					}
				});
			}

			//NOTES:
			//Firefox pasted image as base64 data.
			//Safari pasted image as blob url for example, blob://localhost.com/<identifier>
			var editableDiv = document.getElementById("CentionChatQuestion");
			const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
			const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
			const isFireFox = /Firefox/.test(navigator.userAgent) && /Mozilla/.test(navigator.userAgent);
			var isUsingSafari = false;
			var isUsingChrome = false;
			if (isChrome) isUsingChrome = true;
			if (isSafari) isUsingSafari = true;

			function handlepaste (e) {
				var types, pastedData, savedContent;
				// Browsers that support the 'text/html' type in the Clipboard API
				pastedData = "";
				if(isFireFox){
					if(e.clipboardData instanceof DataTransfer){
						var itemlength = e.clipboardData.items.length;
						for(var i = 0; i < itemlength; i++){
							var item = e.clipboardData.items[i];
							if(item.kind == "string"){
								pastedData = e.clipboardData.getData(item.type);
								processPaste(editableDiv, pastedData);
								e.stopPropagation();
								e.preventDefault();
								break;
							} else if(item.kind == "file" && item.type.indexOf("image") !== -1){
								var blob = item.getAsFile();
								if(blob) {
									const reader = new FileReader();
									reader.onload = function(event){
										pastedData = "<img src="+event.target.result+" />";
										processPaste(editableDiv, pastedData, event.target.result);
									};
									reader.readAsDataURL(blob);
								}
							}
						}
						return false;
					} else {
						console.info("FF clipboard data unhandled:",
							e.clipboardDate);
					}
				} else if (e && e.clipboardData && e.clipboardData.types &&
					e.clipboardData.getData) {
					types = e.clipboardData.types;
					if (((types instanceof DOMStringList) && types.contains("text/html")) ||
					(types.indexOf && types.indexOf('text/html') !== -1)) {
						pastedData =  e.clipboardData.getData('text/html');
						processPaste(editableDiv, pastedData);
						e.stopPropagation();
						e.preventDefault();
						return false;
					}
				}else {
					if(isUsingChrome) {
						if(e.clipboardData) {
							const items = e.clipboardData.items;
							const blob = items[0].getAsFile();
							if(blob) {
								const reader = new FileReader();
								reader.onload = function(event){
									pastedData = "<img src="+event.target.result+" />";
									processPaste(editableDiv, pastedData, event.target.result);
								};
								reader.readAsDataURL(blob);
							}
						}
					}
				}
				// Everything else: Move existing element contents to a DocumentFragment for safekeeping
				savedContent = document.createDocumentFragment();
				while(editableDiv.childNodes.length > 0) {
					savedContent.appendChild(editableDiv.childNodes[0]);
				}
				// Then wait for browser to paste content into it and cleanup
				waitForPastedData(editableDiv, savedContent, "");
				return true;
			}
			function waitForPastedData (elem, savedContent) {
				// If data has been processes by browser, process it
				if (elem.childNodes && elem.childNodes.length > 0) {
					// Retrieve pasted content via innerHTML
					// (Alternatively loop through elem.childNodes or elem.getElementsByTagName here)
					var pastedData = elem.innerHTML;
					// Restore saved content
					elem.innerHTML = "";
					elem.appendChild(savedContent);
					// Call callback
					processPaste(elem, pastedData, "");
				}
				// Else wait 20ms and try again
				else {
					setTimeout(function () {
						waitForPastedData(elem, savedContent, "")
					}, 20);
				}
			}
			function dataURItoBlob(dataURI) {
				// convert base64 to raw binary data held in a string
				// doesn't handle URLEncoded DataURIs

				//fixme @sue.  Error: Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded
				//on below line atob()
				const byteString = atob(dataURI.split(',')[1]);
				// separate out the mime component
				const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
				// write the bytes of the string to an ArrayBuffer
				const ab = new ArrayBuffer(byteString.length);
				// create a view into the buffer
				const ia = new Uint8Array(ab);
				// set the bytes of the buffer to the correct values
				for (var i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}
				// write the ArrayBuffer to a blob, and you're done
				var blob = new Blob([ab], {type: mimeString});
				return blob;
			}
			function blobToFile(theBlob, fileName){
				theBlob.lastModifiedDate = new Date();
				theBlob.name = fileName;
				return theBlob;
			}
			function isHTML(str) {
				const a = document.createElement('div');
				a.innerHTML = str;
				let foundHtml = false;
				for (var c = a.childNodes, i = c.length; i--; ) {
					if (c[i].nodeType == 1) {
						foundHtml = true;
						break;
					}
				}
				return foundHtml;
			}
			var walk_the_DOM = function walk(node, func) {
				func(node);
				node = node.firstChild;
				while (node) {
					walk(node, func);
					node = node.nextSibling;
				}
			};
			function sanitizeHTML(htmlString){
				var doc = document.createElement('div');
				doc.innerHTML = htmlString;
				walk_the_DOM(doc, function(el) {
					if(el.removeAttribute) {
						el.removeAttribute('style');
						el.removeAttribute('class');
					}
				});
				return doc.innerHTML;
			}
			function stripHTML(html){
				var tmp = document.createElement("div");
				tmp.innerHTML = html;
				return tmp.textContent || tmp.innerText || "";
			}

			function hasOnlyImg(div) {
				if (div.children.length != 1)
					return false;
				return (div.children[0].tagName == "IMG" || div.children[0].tagName == "img" );
			}

			function convertImgToCanvasAndUploadTemp(img) {
				if (!img.src) return;
				var canvas = document.createElement('canvas');
				var ctx = canvas.getContext('2d');
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0);
				canvas.toBlob(function(blob){
					if(blob != null) {
						const myFile = blobToFile(blob, "pasted-image.png");
						uploadChatTempImage(myFile);
					}
				});
			}

			function processPaste (elem, pastedData, base64Data) {
				const CentionChatQuestion = document.getElementById('CentionChatQuestion');
				elem.focus();
				var tmp = document.createElement('div');
				tmp.innerHTML = pastedData;
				let isImgText = /^data:image\//.test(pastedData);
				if(pastedData && isUsingSafari) {
					var pastedImgWrapper= document.createElement('div');
					pastedImgWrapper.innerHTML= pastedData;
					if(hasOnlyImg(pastedImgWrapper)){
						var alt = "";
						alt = pastedImgWrapper.children[0].getAttribute("alt");
						var url = pastedImgWrapper.children[0].getAttribute("src");
						if(url.startsWith("blob:")){
							var img = tmp.getElementsByTagName('img')[0];
							if(typeof img !== "undefined") {
								convertImgToCanvasAndUploadTemp(img);
							}
						}else{
							pastedData = "<div id=\"draggedImgPreviewContainer\" class=\"imgDraggedPreviewContainer\">" +
								"<a id=\"imgPreviewLink-" + alt + "\" " +
									"class=\"imgPreviewLink\" " +
									"data-lightbox=\"imgPreviewLink" + alt + "\" "+
									"href=\""+ url +"\" >" +
								"<img src=\"" + url + "\" " +
								"id=" + alt + " " +
								" alt='' style=\"max-width:200px;max-height:200px\" />" +
								"</a>" +
								"</div>";
								const div = document.createElement('div');
								div.innerHTML = pastedData;
								CentionChatQuestion.appendChild(div.firstChild);
							}
					}else{
						const img = tmp.getElementsByTagName('img')[0];
						if(typeof img !== "undefined") {
							convertImgToCanvasAndUploadTemp(img);
						}else {
							if(isHTML(pastedData)){
								pastedData = sanitizeHTML(pastedData);
							}
							const div = document.createElement('div');
							div.innerHTML = pastedData;
							CentionChatQuestion.appendChild(div.firstChild);
						}
					}

				}else if(pastedData && !isUsingChrome){
					//firefox and ie
					//firefox use base64, draw it as img and upload
					var img = tmp.getElementsByTagName('img')[0];
					if(typeof img !== "undefined") {
						if (!img.src) return;
						base64Data = img.src;
						var c = "<canvas id=\"canvas1\" width=\"0\" height=\"0\" ></canvas>";
						editableDiv.innerHTML += c;
						var canvas = document.getElementById("canvas1");
						var ctx = canvas.getContext("2d");
						var image = new Image();
						image.onload = function() {
							ctx.drawImage(image, 0, 0);
						};
						image.src = base64Data;
						var file = dataURItoBlob(base64Data);
						uploadChatTempImage(file);
					} else if(isImgText){
						base64Data = pastedData;
						var c = "<canvas id=\"canvas1\" width=\"0\" height=\"0\" ></canvas>";
						editableDiv.innerHTML += c;
						var canvas = document.getElementById("canvas1");
						var ctx = canvas.getContext("2d");
						var image = new Image();
						image.onload = function() {
							ctx.drawImage(image, 0, 0);
						};
						image.src = base64Data;
						var file = dataURItoBlob(base64Data);
						uploadChatTempImage(file);
					} else {
						let textOnly = ""
						if(isHTML(pastedData)){
							textOnly = stripHTML(pastedData);
						} else {
							textOnly = pastedData;
						}
						const div = document.createElement('div');
						div.innerHTML = textOnly;
						CentionChatQuestion.appendChild(div.firstChild);
					}
				}else if(pastedData && isUsingChrome){
					if(base64Data) {
						var c = "<canvas id=\"canvas1\" width=\"0\" height=\"0\" ></canvas>";
						editableDiv.innerHTML += c;
						var canvas = document.getElementById("canvas1");
						var ctx = canvas.getContext("2d");
						var image = new Image();
						image.onload = function() {
							ctx.drawImage(image, 0, 0);
						};
						image.src = base64Data;
						var file = dataURItoBlob(base64Data);
						uploadChatTempImage(file);
					} else if(isImgText){
						var c = "<canvas id=\"canvas1\" width=\"0\" height=\"0\" ></canvas>";
						editableDiv.innerHTML += c;
						var canvas = document.getElementById("canvas1");
						var ctx = canvas.getContext("2d");
						var image = new Image();
						image.onload = function() {
							ctx.drawImage(image, 0, 0);
						};
						image.src = pastedData;
						var file = dataURItoBlob(pastedData);
						uploadChatTempImage(file);
					}else {
						let noImage = true
						if(isHTML(pastedData)){
							pastedData = sanitizeHTML(pastedData);

							//Handling paste image from website (Right click Image > Copy image)
							var pastedImgWrapper= document.createElement('div');
							pastedImgWrapper.innerHTML= pastedData;

							//This will convert pasted image that pasted as <img src>
							//into previewable image to agent
							//without having to upload this img.
							//this make it different from the dropped(dragged) image
							let url = ""
							if(hasOnlyImg(pastedImgWrapper)){
								var alt = "";
								alt = pastedImgWrapper.children[0].getAttribute("alt");
								url = pastedImgWrapper.children[0].getAttribute("src");
								pastedData = "<div id=\"draggedImgPreviewContainer\" class=\"imgDraggedPreviewContainer\">" +
								"<a id=\"imgPreviewLink-" + alt + "\" " +
									"class=\"imgPreviewLink\" " +
									"data-lightbox=\"imgPreviewLink" + alt + "\" "+
									"href=\""+ url +"\" >" +
								"<img src=\"" + url + "\" " +
								"id=" + alt + " " +
								" alt='' style=\"max-width:200px;max-height:200px\" />" +
								"</a>" +
								"</div>";
								noImage = false;
							}
							if(noImage == false) {
								var file = dataURItoBlob(url);
								uploadChatTempImage(file);
							}
						}
						if (noImage == true) {
							let textOnly = ""
							if(isHTML(pastedData)){
								textOnly = stripHTML(pastedData);
							} else {
								textOnly = pastedData;
							}
							const div = document.createElement('div');
							div.innerHTML = textOnly;
							CentionChatQuestion.appendChild(div.firstChild);
						}
					}
				}else {
					if(isHTML(pastedData)){
						pastedData = sanitizeHTML(pastedData);
					}
					const div = document.createElement('div');
					div.innerHTML = pastedData;
					CentionChatQuestion.appendChild(div.firstChild);
				}
			}
			var uploadLoader = document.getElementById('CentionUploadloader');
			function uploadChatTempImage(file) {
				if(uploadLoader) {
					uploadLoader.style.display = 'block';
				}
				var xhr =[];
				var formData = new FormData();
				var boundary = Math.floor(Math.random() * 6)+ Math.floor(''+new Date() / 1000);
				formData.append("uploadfile", file);
				formData.append( 'random', parseFloat(boundary));
				formData.append( 'session', CentionChat.sessionId);
				formData.append( 'area', CentionChat.areaId);
				formData.append( 'sessionSecret', CentionChat.sessionSecret);
				xhr = new XMLHttpRequest();
				xhr.open("POST",baseURL + spacePrefix + CentionChatStatus.centionFilePrefix + "/chat/client/uploadTempAttachment");
				xhr.onreadystatechange = function(ev){
					if (ev.target.readyState == 4 && ev.target.status == 200) {
						var ro = JSON.parse(ev.target.responseText);
						_tempImageAttachments.push(ro);
						let html;
						html = "<div id=\"draggedImgPreviewContainer\" class=\"imgDraggedPreviewContainer\">" +
							"<a id=\"imgPreviewLink-" + ro.id + "\" " +
								"class=\"imgPreviewLink\" " +
								"data-lightbox=\"imgPreviewLink" + ro.id + "\" "+
								"href=\""+ baseURL + spacePrefix + escape(ro.download) + '?t='+CentionChat.sessionSecret +"\" >" +
							"<img src=\"" + baseURL + spacePrefix + escape(ro.download) + '?t='+CentionChat.sessionSecret + "\" " +
							"id=" + ro.id + " " +
							" alt='' style=\"max-width:200px;max-height:200px\" />" +
							"</a>" +
							"</div>";
						const CentionChatQuestion = document.getElementById('CentionChatQuestion');
						const div = document.createElement('div');
						div.innerHTML = html;
						CentionChatQuestion.appendChild(div.firstChild);
						if(uploadLoader) {
							uploadLoader.style.display = 'none';
						}
					}
				};
				xhr.send(formData);
			}

			//Orientationn Potrait and Landscape
			window.addEventListener("orientationchange", function(event) {
				if (document.getElementById('CentionChatBody').style.display !== 'none') {
					if (getPosition === "bottomRight" || getPosition === "bottomLeft") {
						window.addEventListener('resize', function() {
						});
					}
					if (getPosition === "centerRight") {
						window.addEventListener('resize', function() {
							document.getElementById("CentionChatHeader").style.right = document.getElementById('CentionChatBody').offsetWidth - 73 + 'px';
						});
					}
					if (getPosition === "centerLeft") {
						window.addEventListener('resize', function() {
							document.getElementById("CentionChatHeader").style.left = document.getElementById('CentionChatBody').offsetWidth - 73 + 'px';
						});
					}
				}
			});

			// Setup contact form
			setElementPlaceholderById('contactInputName', getCustomText("textInputName") + " *");
			setElementPlaceholderById('contactInputEmail', getCustomText("textInputEmail") + " *");
			setElementPlaceholderById('contactInputQuestion', getCustomText("textInputQuestion") + " *");
			if(document.getElementById('CentionMsgSend')) {
				document.getElementById('CentionMsgSend').disabled = true;
			}

			if(document.getElementById('contactButtonSubmit')) {
				document.getElementById('contactButtonSubmit').addEventListener('click', function () {
					const formName = document.getElementById('contactInputName').value;
					const formEmail = document.getElementById('contactInputEmail').value;
					const formPhone = document.getElementById('contactInputPhone').value;
					const formQuestion = document.getElementById('contactInputQuestion').value;
					const emailIsValid = isValidEmail(formEmail);
					let phoneIsValid = false;
					if (formPhone == "" || (formPhone != "" && isValidPhone(formPhone))) {
						phoneIsValid = true;
					}
					const subj = formQuestion.substring(0, 30);
					if (formName && formQuestion && emailIsValid && phoneIsValid) {
						const formData = new FormData();
						formData.append("area", CentionChat.areaId);
						formData.append("name", formName);
						formData.append("from", formEmail);
						formData.append("phone", formPhone);
						formData.append("subject", subj);
						formData.append("body", formQuestion);
						const createErrandURL = baseURL + spacePrefix + "/socket/external.api/createerrand";
						fetch(createErrandURL, {
							method: 'POST',
							body: formData
						})
						.then(response => {
							if (response.ok) {
								return response.json();
							} else {
								throw new Error('Failed to create errand');
							}
						})
						.then(data => {
							document.getElementById('contactInputQuestion').value = "";
							setInnerHTMLByElem(document.getElementById('contactFormInfo'), Linker.linkifyUrls(getCustomText("textContactFormSentNotice")));
							document.getElementById('CentionNoAgentsView').classList.add("form-sent");
						})
						.catch(error => {
							console.error("Error:", error);
						});
					} else {
						if ((formName == "") || (formEmail == "") || (formQuestion == "")) {
							if (formName == "") {
								document.getElementById("contactInputName").classList.add("warning");
							}
							if (formEmail == "") {
								document.getElementById("contactInputEmail").classList.add("warning");
							}
							if (formQuestion == "") {
								document.getElementById("contactInputQuestion").classList.add("warning");
							}
						} else if (!emailIsValid) {
							document.getElementById('contactInputEmail').value = "";
						} else if (!phoneIsValid) {
							document.getElementById("contactInputPhone").classList.add("warning");
							document.getElementById("contactInputPhone").value = "";
						}
						return false;
					}
				});
			}

			//Mobile optimization
			if(params.faqWidgetCfg == "") {
				
				const isMobileFSOptimized = chat_config_full_screen_mobile && isChatMobileDeviceSize;
				const adjustForSafeArea = () => {
					const widgetContainer = document.querySelector('.skin__frame');
					const safeAreaBottom = getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom') || '0px';
					widgetContainer.style.paddingBottom = safeAreaBottom;
				};
				if (!isMobileFSOptimized) {
					adjustForSafeArea();
				}

				window.addEventListener('resize', () => {
					const activeElement = document.activeElement;
					if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.id === 'CentionChatQuestion') {
						// Ensure the input is visible when the keyboard is active
						activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
					if (!isMobileFSOptimized) {
						adjustForSafeArea();
					}
				});

				const chatInput = 'CentionChatQuestion';
				const chatElems = [...document.querySelectorAll('#cention-chat-container input, #cention-chat-container textarea'), document.getElementById(chatInput)];
				chatElems.forEach(el => {
					el.addEventListener('focus', () => {
						el.scrollIntoView({ behavior: 'smooth', block: 'center' });
					});
				});
			}
		} //end load mini chat
};

// Linker linkifies text that looks like URL in text given by editor.
var Linker = {
	// Regex for capturing urls in ckeditor html.
	urlRegex: /(>|^)(https?:\/\/[^ \<]+)/i,

	linkifyUrls: function (html) {
		var res
		, i
		;

		res = Array.prototype.slice.call(html.split(/(\&nbsp;|\s)+/g))

		for(i=0; i<res.length; i++) {
			res[i] = res[i].replace(this.urlRegex, function(match, p1, p2) {
				var url = p2
					, lastChunk = ""
				;
				while (url && url.length > 1 && (
					url[url.length-1] == '.' ||
						url[url.length-1] == ',' ||
						url[url.length-1] == '"' ||
						url[url.length-1] == ']' ||
						url[url.length-1] == ')'
				)) {
					lastChunk += url[url.length-1];
					url = url.substring(0, url.length-1);
				}
				var removeExtraTagRegex = /(<([^>]+)>)/ig;
				var linkSrc = url.replace(/"/g, '\\x22')
				sanitizeLinkSrc = linkSrc.replace(removeExtraTagRegex, "");

				//TODO: Preview url using OG(Open graph) Meta data if possible

				return [ p1
					, '<a href="' + sanitizeLinkSrc + '" target="_blank">' + url + '</a>'
					, lastChunk
				].join('');
			});
		}
		return res.join(' ');
	}
};
