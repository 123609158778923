var WS_EVENT_CONNECTED = 'WS_CONNECTED'
var WS_EVENT_DISCONNECTED = 'WS_DISCONNECTED'

function Socket(url) {
	var CLEAN_QUEUE_NUMBER = 100;
	var WARNING_QUEUE_NUMBER = 50;
	var me = {
		id: 0,
		acks: {},
		outputQueue: [],
		unackQueue: [],
		queue: [],
		handler: {},
		Dispatcher: null,
		conn: null,
		reconnectTimer: null
	};

	function prefixNamespace(namespace, evt) {
		if (!namespace) {
			return evt;
		}
		return namespace + '/' + evt;
	}

	me.on = function(namespace, evt, callback) {
		me.handler[prefixNamespace(namespace, evt)] = callback;
	};

	// almost same like sendEvents but without wait for ack before send another
	// one. This way should be more flexible and more mimic to real powerful
	// websocket. Use sendEvents (such as chat) if you need to make sure the
	// sending is as send1 -> ack1 -> send2 -> ack2 ... sendN -> ackN
	function output() {
		if (me.conn == null) {
			return;
		}
		if (me.outputQueue.length) {
			var i;
			for (i=0; i<me.outputQueue.length && i<CLEAN_QUEUE_NUMBER; i++) {
				me.conn.send(JSON.stringify(me.outputQueue[0]));
				me.unackQueue.push(me.outputQueue.shift());
			}
			if (i > WARNING_QUEUE_NUMBER) {
				console.log("%cWARNING:", "background:yellow", "high websocket queue:", i);
			}
		}
	}

	function startOutput() {
		if (me.unackQueue.length) {
			me.outputQueue = me.unackQueue.concat(me.outputQueue);
			me.unackQueue = [];
		}
		output();
	}

	function removeFromUnackQueue(packetId) {
		for (var i=0; i<me.unackQueue.length; i++) {
			if (me.unackQueue[i].id === packetId) {
				me.unackQueue.splice(i, 1);
				break;
			}
		}
	};

	function outputBase(packet, callback) {
		me.id++;
		packet.id = me.id;
		me.acks[me.id] = function(pkt) {
			if (typeof callback == 'function') {
				callback.apply(me, pkt.args);
			}
			removeFromUnackQueue(packet.id);
			if (me.outputQueue.length) {
				setTimeout(output, 0);
			}
		};
		me.outputQueue.push(packet);
		if (me.outputQueue.length) {
			output();
		}
	};

	me.emit = function(namespace, evt) {
		var cacheArgs = [];
		for (var i=2; i<arguments.length; i++) {
			cacheArgs.push(arguments[i]);
		}
		var callback;
		var packet = {
			namespace: namespace,
			type: evt
		};
		if (cacheArgs.length) {
			if (typeof cacheArgs[cacheArgs.length - 1] === 'function') {
				callback = cacheArgs.pop();
			}
			packet.args = cacheArgs;
		}
		outputBase(packet, callback);
	};

	me.unackOutputs = function() {
		return me.unackQueue.length;
	};

	function namespaceReply(conn, namespace, id, args) {
		var packet = {
			id: id,
			namespace: namespace
		};
		if (args.length) {
			packet.args = args;
		}
		conn.send(JSON.stringify(packet));
	}

	me.SetEventListener = function(evt, callback) {
		me.handler[evt] = callback;
	};

	function sendEvents() {
		if (me.conn == null) {
			return;
		}
		if (me.queue.length > 0) {
			me.conn.send(JSON.stringify(me.queue[0]));
		}
	};

	me.SendEvent = function(evt, strOrObject, callback) {
		var packet = {
			type: evt
		};

		if (typeof strOrObject == "object") {
			packet.data = strOrObject;
		} else if (typeof strOrObject == "string") {
			packet.payload = strOrObject;
		}

		me.id++;
		packet.id = me.id;
		me.acks[me.id] = function(pkt) {
			if (typeof callback == 'function') {
				callback.apply(me, pkt.args);
			}
			me.queue.shift();
			if (me.queue.length > 0) {
				setTimeout(sendEvents, 0);
			}
		};
		me.queue.push(packet);
		if (me.queue.length == 1) {
			sendEvents();
		}
	};

	function reply(conn, id, arg) {
		var packet = {
			id: id,
		};
		if (arg) {
			if (typeof arg == "object") {
				packet.data = arg;
			} else if (typeof arg == "string") {
				packet.payload = arg;
			} else {
				throw new Error("invalid reply argument " + typeof(arg));
			}
		}
		conn.send(JSON.stringify(packet));
	}

	me.onmessage = function(data) {
		var packet = JSON.parse(data)
		, replied = false
		, cb
		, conn = me.conn
		;
		if (conn == null) {
			return;
		}
		if (packet.id) {
			// This is an ack from back end
			if (me.acks[packet.id]) {
				me.acks[packet.id](packet)
				delete me.acks[packet.id];
			}
			return;
		}

		packet.reply = function() {
			if (replied) {
				return;
			} else if (packet.namespace) {
				var args = [];
				for (var i=0; i<arguments.length; i++) {
					args.push(arguments[i]);
				}
				replied = true;
				namespaceReply(conn, packet.namespace, packet.wantAck, args);
				return;
			}
			switch (arguments.length) {
			case 0:
				replied = true;
				reply(conn, packet.wantAck);
				break;
			case 1:
				replied = true;
				reply(conn, packet.wantAck, arguments[0]);
				break;
			case 2:
				replied = true;
				reply(conn, packet.wantAck, {"a":arguments[0],"b":arguments[1]});
				break;
			default:
				throw new Error("invalid ack argument")
			}
		};

		if (me.Dispatcher) {
			me.Dispatcher(packet);
		}
		var event;
		if (packet.namespace) {
			event = prefixNamespace(packet.namespace, packet.event);
		} else {
			event = packet.event;
		}
		cb = me.handler[event];
		if (cb) {
			if (packet.args) {
				packet.args.push(packet.reply)
			} else {
				packet.args = [packet.reply];
			}
			cb.apply(me, packet.args)
		} else {
			if (packet.wantAck > 0) {
				packet.reply(packet.event+"(NO_LISTENER)");
			}
		}

		if (packet.wantAck < 0) {
			packet.reply(packet.event);
		}

		if (!replied) {
			// TODO dispatcher may call packet.reply() at a later
			// time, so us calling it here will wrongly make the
			// future call a no-op
			packet.reply(packet.event+"(NOT_CALLED)");
		}
	};

	me.pendingEvents = function() {
		return me.queue.length;
	};

	me.dispatch = function(action) {
		if (typeof me.Dispatcher != 'function') {
			return;
		}
		me.Dispatcher(action);
	}

	function connect(url) {
		var conn;

		if (me.ReconnectingWebSocket) {
			conn = me.ReconnectingWebSocket(url);
		} else {
			conn = new WebSocket(url);
		}

		conn.onclose = function (evt) {
			me.conn = null;
			// NOTE: this is packet type and NOT redux action type.
			me.dispatch({ type: WS_EVENT_DISCONNECTED });
		};
		conn.onopen = function(evt) {
			me.conn = conn;

			startOutput();

			// Upon reconnection, resend the last event that yet to receive ACK (if any)
			sendEvents();

			// NOTE: this is packet type and NOT redux action type.
			// after sendEvents() to avoid duplicate send of 'register' event from chat API upon connect
			me.dispatch({ type: WS_EVENT_CONNECTED });
		};
		conn.onmessage = function(evt) {
			me.onmessage(evt.data);
		};
		conn.onerror = function(evt) {
			if (typeof console.error === "function") {
				console.error("WebSocket error observed:", {evt: evt, conn: conn});
			}
		};
	};

	me.Connect = function() {
		connect(url);
		if (!me.ReconnectingWebSocket) {
			me.reconnectTimer = setInterval(function() {
				if (me.conn) {
					return;
				}
				connect(url);
			}, 7000);
		}
	};

	me.Disconnect = function()  { // probably useless (websocket always connected)
		if (me.conn) {
			if (me.reconnectTimer) {
				clearInterval(me.reconnectTimer);
				me.reconnectTimer = null;
			}
			me.conn.close();
		}
	}
	return me;
}

if (typeof module == "object" && typeof module.exports == "object"){
	module.exports.Socket = Socket;
	module.exports.WS_EVENT_CONNECTED = WS_EVENT_CONNECTED;
	module.exports.WS_EVENT_DISCONNECTED = WS_EVENT_DISCONNECTED;
}
