var CentionBaseURL, spacePrefix;
var CentionTemplateButtonCallbacks = {};
if (typeof CentionBaseURL == "undefined") {
	CentionBaseURL = "";
	switch (window.location.hostname) {
	case "api.cention.com":
	case "cloud.cention.com":
	case "cloud.cust.cention.se":
		spacePrefix = window.location.pathname.substr(0, window.location.pathname.indexOf("/", 3));
	}
}

var _CentionFAQ = function(config) {
	var that = {};
	var Public = {};
	var ws;
	var actionHandlers = {};
	Public.baseURL = ""; // May be set by third party client.

	function log() {
		if (window.console && console.log && typeof console.log === "function") {
			console.log.apply(console, arguments)
		}
	}

	that.wsDispatcher = function(action) {
		if (action && action.type == 'WS_CONNECTED') {
			that.connected = true;
			if (that.parameters) {
				var id = that.parameters.id
				, minVotes = that.parameters.minVotes
				, limit = that.parameters.limit
				, topList = that.parameters.topList
				, browseLibrary = that.parameters.browseLibrary
				;
				ws.SendEvent('FAQ_REGISTER', {
					id: id,
					minVotes: minVotes,
					limit: limit,
					topList: topList,
					browseLibrary: browseLibrary,
					baseURL: CentionBaseURL,
				}, function(data) {
					invokeAction("connect", data);
				});
			}
		}
		if (action && action.type == "WS_DISCONNECTED") {
			that.connected = false;
			invokeAction("disconnect");
		}
	};

	that.traverseTree = function(data) {
		if (data.Libs && data.Libs.length > 0) {
			c3jQuery.each(data.Libs, function(k, l){
				that.populateLibrary(l);
			});
		}
		if (data.Cats && data.Cats.length > 0) {
			c3jQuery.each(data.Cats, function(k, c){
				that.populateCategory(c);
			});
		}
		if (data.Qs && data.Qs.length > 0) {
			c3jQuery.each(data.Qs, function(k, q){
				that.populateQuestion(q);
			});
		}
	};

	that.populateLibrary = function(data) {
		if (!that.libraryList[data.Id]) {
			that.libraryList[data.Id] = data;
			that.traverseTree(data);
		}
	};

	that.populateCategory = function(data) {
		if (!that.categoryList[data.Id]) {
			that.categoryList[data.Id] = data;
			that.traverseTree(data);
		}
	};

	that.populateQuestion = function(data) {
		that.questionList[data.Id] = data
	};

	that.setupClientSocketListeners = function() {
		ws.SetEventListener("FAQ_TOPLIST", function(data) {
			that.libraryList = {};
			that.categoryList = {};
			that.questionList = {};
			var result = {
				list: [],
				libs: []
			};
			if (data && data.list) {
				c3jQuery.each(data.list, function(k, q){
					that.populateQuestion(q);
					result.list.push(q)
				});
			}
			if (data && data.library) {
				result.libs = data.library;
				c3jQuery.each(data.library, function(k, lib){
					that.populateLibrary(lib);
				});
			}
			invokeAction("FAQ_TOPLIST", result)
		});
	};

	// file view handling on client side
	// This will convert agent url to client accessible url
	that.maybeFormat = function (text) {
		if(text === "") {
			return text;
		}

		var addBaseURL = RegExp(Public.baseURL+CentionChatStatus.centionFilePrefix+'/', 'g');
		var addWebroot = RegExp(CentionChatStatus.centionFilePrefix+'/', 'g');

		if(text.search(CentionChatStatus.centionFilePrefix+"/chat/client") > -1) {
			text = text.replace(addBaseURL,Public.baseURL+spacePrefix+CentionChatStatus.centionFilePrefix+'/');
		}
		if(spacePrefix !== ''){
			if(text.search(Public.baseURL+spacePrefix+CentionChatStatus.centionFilePrefix+"/file") > -1) {
				return text;

			}else if (text.search(spacePrefix+CentionChatStatus.centionFilePrefix+"/file") > -1) {
				return text;

			}else if(text.search(CentionChatStatus.centionFilePrefix+"/file") > -1) {
				text = text.replace(addWebroot,Public.baseURL+spacePrefix+CentionChatStatus.centionFilePrefix+'/');
			}
		}else{
			if(text.search(Public.baseURL+CentionChatStatus.centionFilePrefix+"/file") > -1) {
				return text;

			}else if(text.search(CentionChatStatus.centionFilePrefix+"/file") > -1) {
				text = text.replace(addWebroot,Public.baseURL+CentionChatStatus.centionFilePrefix+'/');
			}
		}

		return text;
	};

	function invokeAction( action ) {
		var handler = actionHandlers[action];
		if( handler ) {
			return handler.apply(self, Array.prototype.slice.apply(arguments, [1]), Array.prototype.slice.apply(arguments, [2]));
		}
	};

	Public.registerAction = function(action, handler) {
		actionHandlers[action] = handler;
	};

	Public.connect = function(parameters) {
		if (that.connected) {
			return;
		}
		if(!parameters.baseURL) {
			parameters.baseURL = Public.baseURL;
		}
		if(spacePrefix){
			parameters.baseURL = parameters.baseURL + spacePrefix;
		}
		var hostname = parameters.baseURL.replace(/(^\w+:|^)\/\//, '');
		ws = Socket('wss://'+hostname+"/faq.ws");
		that.parameters = parameters;
		that.setupClientSocketListeners();
		ws.Dispatcher = that.wsDispatcher;
		ws.Connect();
		return;
	};

	Public.disconnect = function() {
		ws.Disconnect();
		return;
	}

	Public.search = function(opt, searchText) {
		if (that.connected) {
			ws.SendEvent('FAQ_SEARCH', {opt: opt, search: searchText}, function(data) {
				var result = [];
				if (data && data.list) {
					c3jQuery.each(data.list, function(k, q){
						that.populateQuestion(q);
						result.push(q);
					});
				}
				invokeAction("FAQ_SEARCH", result);
			});
			return;
		}
	};

	Public.setVote = function(id) {
		if (that.connected) {
			ws.SendEvent('FAQ_SET_VOTE', {id: id}, function(data) {
				if (data && data.vote) {
					invokeAction("FAQ_VOTE_STATUS", data.vote > 0 ? true : false);
				}
			});
			return;
		}
	};

	Public.getVote = function(id) {
		if (that.connected) {
			ws.SendEvent('FAQ_GET_VOTE', {id: id}, function(data) {
				if (data) {
					invokeAction("FAQ_VOTE_STATUS", data.status);
				}
			});
			return;
		}
	};

	Public.getQuestionByLibrary = function(id) {
		if (that.libraryList) {
			var data = that.libraryList[id];
			if (data) {
				return data;
			}
		}
		return null;
	};

	Public.getQuestionByCategry = function(id) {
		if (that.categoryList) {
			var data = that.categoryList[id];
			if (data) {
				return data;
			}
		}
		return null;
	};

	Public.getAnswer = function(id) {
		if (that.questionList) {
			var data = that.questionList[id];
			if (data) {
				data["Q"] = that.maybeFormat(data["Q"]);
				data["A"] = that.maybeFormat(data["A"]);
				return data;
			}
		}
		return null;
	};

	Public.getFormattedFiles = function(id) {
		var filesHTML = "";
		if (that.questionList) {
			var data = that.questionList[id];
			if (data) {
				if(data["F"] && data["F"].length > 0) {
					var files = data["F"];
					filesHTML = "<div>";
					for(let i = 0;i < files.length; i++){
						let file = files[i]["A"];
						filesHTML += "<i class='fas fa-paperclip'></i> <a href="+Public.baseURL+file.path+" target='_blank'>"+file.name+"</a>";
						if(i != files.length) {
							filesHTML += "<br>";
						}
					}
					filesHTML += "</div>";
				}
				return filesHTML;
			}
		}
		return filesHTML;
	}

	return Public;
};
